<template>
  <SidebarLayout>
    <GridContainer>
      <div class="col-span-12 mb-20">
        <PageHeader>
          <template v-if="currentAthlete?.firstName">
            {{
              `${currentAthlete.firstName} ${currentAthlete?.lastName ?? ''}`
            }}
          </template>

          <template #action>
            <div class="back-action flex items-center">
              <BackButton />
            </div>
          </template>
        </PageHeader>

        <VideoGrid v-if="id" :athlete-id="id" :athlete="currentAthlete" />
      </div>
    </GridContainer>
  </SidebarLayout>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { HttpClientFetch } from 'src/core/HttpClient';
import { API, useEndpoint } from 'src/api/api.endpoints';
import { IMember } from 'src/core/Interfaces/IAthlete';
import SidebarLayout from 'src/components/layout/SidebarLayout.vue';
import VideoGrid from 'src/components/Video/VideoGrid.vue';
import GridContainer from 'src/components/common/GridContainer.vue';
import PageHeader from 'src/components/common/PageHeader.vue';
import BackButton from 'src/components/common/BackButton.vue';

const currentAthlete = ref<IMember>({} as IMember);
const id = ref('');
const isFetchInProgress = ref(false);

const route = useRoute();

onMounted(async () => {
  isFetchInProgress.value = true;

  const { athleteId } = route.params;

  if (!athleteId) {
    return;
  }

  await getAthlete(athleteId as string);
  id.value = athleteId as string;
});

const getAthlete = async (athleteId: string) => {
  const response = await HttpClientFetch(
    useEndpoint(`${API.MY_ATHLETES}/${athleteId}` as API),
  );

  isFetchInProgress.value = false;

  if (!response.ok) {
    return;
  }

  const { athlete } = await response.json();

  currentAthlete.value = athlete;
};
</script>

<style scoped>
.back-action:hover svg {
  @apply -translate-x-4 transform;
}
</style>
