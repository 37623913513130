import { Analytics, getAnalytics } from '@firebase/analytics';
import { initializeApp } from '@firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const {
  VITE_FIREBASE_APP_ID,
  VITE_FIREBASE_API_KEY,
  VITE_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_BUCKET_URL,
  VITE_FIREBASE_REALTIME_DB_URL,
} = import.meta.env;

const firebaseConfig = {
  appId: VITE_FIREBASE_APP_ID,
  apiKey: VITE_FIREBASE_API_KEY,
  authDomain: VITE_FIREBASE_AUTH_DOMAIN,
  projectId: VITE_FIREBASE_PROJECT_ID,
  storageBucket: VITE_FIREBASE_BUCKET_URL,
  databaseURL: VITE_FIREBASE_REALTIME_DB_URL,
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const useAppUser = () => auth.currentUser;
const database = getDatabase(firebaseApp);

let analytics: Analytics;

if (firebaseConfig.appId) {
  analytics = getAnalytics(firebaseApp);
}

export { auth, useAppUser, storage, analytics, database };
