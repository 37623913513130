/**
 * AnalyticsService.ts
 * @deprecated
 */
//TODO remove this file
import { logEvent, setCurrentScreen, setUserId } from '@firebase/analytics';
import { analytics, auth } from '../3rdParty/Firebase';
import { AnalyticsEvents } from '../AnalyticsConstants';

type EventData = { [key: string]: unknown };

/**
 * @deprecated
 */
const logUserEvent = (eventName: string, eventData?: EventData) => {
  if (!analytics) {
    return;
  }

  logEvent(analytics, eventName, eventData);
};

/**
 * @deprecated
 */
const logUserLogin = () => {
  setCurrentUserId();
  logUserEvent(AnalyticsEvents.LOGIN);
};

/**
 * @deprecated
 */
const logUserSignUp = (eventData?: EventData) => {
  logUserEvent(AnalyticsEvents.SIGN_UP, eventData);
};

/**
 * @deprecated
 */
const setCurrentUserId = () => {
  if (!auth.currentUser?.uid || !analytics) {
    return;
  }

  setUserId(analytics, auth.currentUser?.uid);
};

/**
 * @deprecated
 */
const setCurrentScreenName = (screenName: string) => {
  if (!analytics) {
    return;
  }

  setCurrentScreen(analytics, screenName);
};

/**
 * @deprecated
 */
export { logUserEvent, logUserLogin, logUserSignUp, setCurrentScreenName };
