import { IVideo } from 'src/core/Interfaces/IVideo';
import { IVideoAlbumEntry } from 'src/core/Interfaces/IVideoAlbum';
import AlbumsRepository from 'src/core/repositories/Albums/AlbumsRepository';

export class AlbumsService {
  #albumsRepo: AlbumsRepository;

  constructor() {
    this.#albumsRepo = new AlbumsRepository();
  }

  getAlbums = async (take = 0): Promise<IVideoAlbumEntry[]> => {
    const data = await this.#albumsRepo.getAlbums();

    return Object.entries(data).map(([key, { name, videos }]) => ({
      name,
      key,
      videos: this.#takeAlbums(videos && Object.values(videos), take),
    }));
  };

  #takeAlbums = (albums: IVideo[] = [], take = 0): IVideo[] => {
    if (!take) {
      return albums as IVideo[];
    }

    return albums.slice(0, take) as IVideo[];
  };
}
