<template>
  <div class="flex w-full items-center">
    <div class="flex">
      <button v-if="!isPlaying" class="mr-2" type="button" @click="play">
        <PlayIcon
          class="h-8 w-8"
          :class="props.darkControls ? 'text-primary' : 'text-white'"
        />
      </button>
      <button v-else class="mr-2" type="button" @click="pause">
        <PauseIcon
          class="h-8 w-8"
          :class="props.darkControls ? 'text-primary' : 'text-white'"
        />
      </button>
    </div>

    <div :id="`waveform-${props.playerKey}`" class="wave h-10 w-full"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useEmitter } from 'src/core/EventEmitter';
import { VOICE_VISUALIZATION_EVENT } from 'src/core/Events';
import { PlayIcon, PauseIcon } from '@heroicons/vue/20/solid';
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js';

enum WAVESURFER_EVENT {
  FINISH = 'finish',
}

interface AUDIO_PROPS {
  audioUrl?: string;
  blob?: Blob;
  darkControls?: boolean;
  isBlob?: boolean;
  playerKey: string;
}

const props = withDefaults(defineProps<AUDIO_PROPS>(), {
  audioUrl: '',
  blob: undefined,
  darkControls: false,
  isBlob: false,
  playerKey: '',
});

const emitter = useEmitter();
const waveOptions: WaveSurferOptions = {
  barWidth: 1,
  container: `#waveform-${props.playerKey}`,
  cursorWidth: 0,
  height: 40,
  hideScrollbar: true,
  normalize: true,
  progressColor: '#fde047',
  waveColor: '#0a323c',
};

let wavesurfer: WaveSurfer;
const isPlaying = ref(false);

onMounted(() => {
  wavesurfer = WaveSurfer.create(waveOptions);
  wavesurfer.on(WAVESURFER_EVENT.FINISH, () => {
    isPlaying.value = false;
    wavesurfer.seekTo(0);
  });

  if (props.isBlob) {
    loadWaveSurferFromBlob(props.blob as Blob);

    emitter.on(VOICE_VISUALIZATION_EVENT.DRAW_BLOB, (value) => {
      loadWaveSurferFromBlob(value as Blob);
    });

    return;
  }

  loadWaveSurferFromUrl();
  emitter.on(VOICE_VISUALIZATION_EVENT.DRAW, loadWaveSurferFromUrl);
});

onBeforeUnmount(() => {
  wavesurfer?.empty();
});

const play = () => {
  wavesurfer.play();
  isPlaying.value = true;
};

const pause = () => {
  wavesurfer.pause();
  isPlaying.value = false;
};

const loadWaveSurferFromBlob = (blob: Blob) => {
  const url = URL.createObjectURL(blob);
  wavesurfer.load(url);
};

const loadWaveSurferFromUrl = () => {
  wavesurfer.load(props.audioUrl);
};
</script>
