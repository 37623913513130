import {
  child,
  DataSnapshot,
  get,
  getDatabase,
  onChildAdded,
  push,
  ref,
  remove,
  set,
} from 'firebase/database';

export default class BaseRealtimeDBRepository {
  protected db = getDatabase();

  getDbRef = (path: string) => {
    return ref(this.db, path);
  };

  getChildRef = (path: string) => {
    return child(ref(this.db), path);
  };

  getData = async <T>(
    path: string,
    fallbackValue: T,
  ): Promise<DataSnapshot | T> => {
    const dbRef = ref(this.db);
    const snapshot = await get(child(dbRef, path));

    return snapshot.exists() ? snapshot.val() : fallbackValue;
  };

  setData = <T>(path: string, payload: T): Promise<void> => {
    return set(ref(this.db, path), payload);
  };

  getEntryRef = (path: string) => {
    const ref = this.getDbRef(path);

    return push(ref);
  };

  delete = async (path: string) => {
    remove(ref(this.db, path));
  };

  onChildAdded = (
    path: string,
    callback: (snapshot: DataSnapshot) => unknown,
    onlyOnce = false,
  ) => {
    return onChildAdded(this.getDbRef(path), (data) => callback(data), {
      onlyOnce,
    });
  };
}
