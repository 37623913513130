<script setup lang="ts">
import {
  LOWER_BODY,
  UPPER_BODY,
  moveNetKeyPointsLabels,
} from 'src/core/Constants';
import { FrameTagDto } from 'src/core/services/FrameTags';
import {
  Frame,
  Keypoint,
} from 'src/core/services/Video/VideoKeypointsHttpService';
import { computed } from 'vue';
import { useVideoTagsScreenStore } from './videoTagsScreen.store';

defineEmits(['selectTag', 'addTag']);

const props = defineProps<{
  tags: FrameTagDto[];
  activeTagId: string | undefined; //Pick<FrameTagDto, 'id'> as string;
  frameSizeRatio: { x: number; y: number };
  frameSize: { clientWidth: number; clientHeight: number };
  keypointsFrame?: Frame;
}>();

const store = useVideoTagsScreenStore();

const lines = computed(() => {
  const lines = [
    Array.from(new Array(UPPER_BODY.length)).map(() => ''),
    Array.from(new Array(LOWER_BODY.length)).map(() => ''),
  ];

  props.tags.forEach((tag) => {
    let index = UPPER_BODY.indexOf(
      tag.name as keyof typeof moveNetKeyPointsLabels,
    );

    const linePoints = [
      tag.offsetX / props.frameSizeRatio.x,
      tag.offsetY / props.frameSizeRatio.y,
    ].join(',');

    if (index !== -1) {
      lines[0][index] = linePoints;

      return;
    }

    index = LOWER_BODY.indexOf(tag.name as keyof typeof moveNetKeyPointsLabels);

    if (index !== -1) {
      lines[1][index] = linePoints;
    }
  });

  const upperBodyLinePoints = lines[0].filter(
    (linePoints) => !!linePoints?.length,
  );
  const lowerBodyLinePoints = lines[1].filter(
    (linePoints) => !!linePoints?.length,
  );

  return [
    upperBodyLinePoints.length === UPPER_BODY.length ? upperBodyLinePoints : [],
    lowerBodyLinePoints.length === LOWER_BODY.length ? lowerBodyLinePoints : [],
  ].filter((linePoints) => !!linePoints?.length);
});

const aiKeypoints = computed(() => {
  if (!props.keypointsFrame) {
    return;
  }

  const lines = [
    Array.from(new Array(UPPER_BODY.length)).map(() => ''),
    Array.from(new Array(LOWER_BODY.length)).map(() => ''),
  ];

  const points: { x: number; y: number }[] = [];

  (props.keypointsFrame.pose.keypoints ?? []).forEach((keypoint: Keypoint) => {
    let index = UPPER_BODY.indexOf(
      keypoint.name as keyof typeof moveNetKeyPointsLabels,
    );

    const point = {
      x: keypoint.x / props.frameSizeRatio.x,
      y: keypoint.y / props.frameSizeRatio.y,
    };

    const linePoints = [point.x, point.y].join(',');

    if (index !== -1) {
      lines[0][index] = linePoints;
      points.push({ x: point.x, y: point.y });

      return;
    }

    index = LOWER_BODY.indexOf(keypoint.name);

    if (index !== -1) {
      lines[1][index] = linePoints;
      points.push({ x: point.x, y: point.y });
    }
  });

  return { lines, points };
});
</script>
<template>
  <svg
    :viewBox="`0 0 ${frameSize.clientWidth} ${frameSize.clientHeight}`"
    xmlns="http://www.w3.org/2000/svg"
    class="absolute select-none"
    @click="$emit('addTag', $event)"
  >
    <template v-if="store.isAiKeypointsVisible && aiKeypoints">
      <polyline
        v-for="(line, index) in aiKeypoints.lines"
        :key="`line-${index}`"
        :points="line.join(' ')"
        fill="none"
        class="stroke-yellow-400"
        stroke-width="2"
      />
      <circle
        v-for="(point, index) in aiKeypoints.points"
        :key="`ai-point-${index}`"
        :cx="point.x"
        :cy="point.y"
        r="4"
        class="fill-yellow-400 stroke-black stroke-2"
        @click.stop="$emit('addTag', $event)"
      />
    </template>

    <template v-if="store.isTagJoinsVisible">
      <polyline
        v-for="(line, index) in lines"
        :key="`line-${index}`"
        :points="line.join(' ')"
        fill="none"
        stroke="black"
        stroke-width="2"
      />
    </template>

    <circle
      v-for="tag in tags"
      :key="tag.id"
      :cx="tag.offsetX / frameSizeRatio.x"
      :cy="tag.offsetY / frameSizeRatio.y"
      r="6"
      class="stroke-black stroke-[3]"
      :class="[
        tag.id === props.activeTagId || store.isTagJoinsVisible
          ? 'fill-red-600'
          : 'fill-white hover:h-3 hover:w-3 hover:fill-red-400',
      ]"
      @click.stop="$emit('selectTag', { e: $event, frameTag: tag })"
    />
  </svg>
</template>
