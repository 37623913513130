<script></script>
<template>
  <dialog id="an_modal_whats_new" class="modal">
    <div class="modal-box w-6/12 max-w-5xl">
      <h3 class="text-xl font-semibold">What's new in AthleticNext</h3>
      <form method="dialog">
        <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
          ✕
        </button>
      </form>
      <div class="mt-4">
        <h4 class="text-md font-semibold text-zinc-600">
          01.05.2024 - In-player video switching.
        </h4>

        <video playsinline controls="false" class="mt-1 w-full rounded-lg">
          <source
            src="https://cdn.athleticnext.com/public/video-switch.mov"
            type="video/mp4"
          />
        </video>

        <p class="pt-2 font-extralight text-zinc-600">
          Elevate your analysis efficiency. Streamline your workflow and
          maintain focus with the introduction of in-player video switching. Our
          new right-side drawer allows you to seamlessly browse and select your
          next video for analysis, all within the player environment. This
          eliminates context switching and minimizes disruptions, empowering you
          to make faster, more informed decisions during your analysis sessions.
        </p>
      </div>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  </dialog>
</template>
