<template>
  <div class="invite-wrapper mt-6 h-full">
    <div class="flex justify-center">
      <img class="h-32" src="/friends.svg" alt="Friends" />
    </div>

    <div v-if="invitesCount" class="mt-2">
      <div class="flex w-full justify-center">
        <div class="relative mb-4 w-full">
          <transition name="fade">
            <span
              v-if="isCopied"
              class="absolute right-0 top-0 -translate-y-[100%] rounded-lg bg-black p-2 text-xs text-white"
            >
              Copied!
            </span>
          </transition>

          <div
            class="relative mt-1 flex w-full cursor-pointer items-center rounded-lg border bg-gray-100 px-4 py-3 text-sm font-extralight"
            @click="copy"
          >
            <span
              class="flex h-full w-full items-center overflow-x-auto whitespace-nowrap"
            >
              {{ inviteUrl }}
            </span>

            <span
              class="absolute right-0 flex h-full items-center bg-gray-100 px-2"
            >
              <DocumentDuplicateIcon class="h-5 w-5 text-secondary" />
            </span>
          </div>
        </div>
      </div>

      <div class="flex w-full justify-center">
        <ANButton
          :disabled="!invitesCount"
          class="w-full md:w-1/2"
          dark
          @click="share"
        >
          <div class="flex w-full items-center justify-center p-4">
            <span class="mr-2 text-sm font-normal text-white">
              {{ buttonConfig.text }}
            </span>
            <Component :is="buttonConfig.icon" class="h-5 w-5 text-white" />
          </div>
        </ANButton>
      </div>
    </div>

    <div v-else>
      Congrats! Your team is complete. To invite more people reach out to
      <a
        href="mailto:support@athleticnext.com"
        class="text-blue-500 transition duration-300 hover:text-blue-800"
      >
        support@athleticnext.com
      </a>
      to see how you can upgrade your plan.
    </div>

    <div class="mt-4 text-center">
      <div class="flex justify-center text-sm">
        <span class="font-medium">{{ athletesCount }}</span>
        <span class="pl-1 text-secondary">athletes invited</span>
      </div>
      <div class="flex justify-center text-sm">
        <span class="font-medium">{{ invitesCount }}</span>
        <span class="pl-1 text-secondary">invites left</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import {
  ShareIcon,
  CheckIcon,
  DocumentDuplicateIcon,
} from '@heroicons/vue/20/solid';
import { PUBLIC_ROUTES, PUBLIC_ROUTE_NAMES } from 'src/core/AppRoutes';
import { copyText } from 'src/services/utilities/HtmlElementUtilities';
import { ANButton } from 'src/components/Uikit/Components';

const props = withDefaults(
  defineProps<{
    token?: string;
    invitesCount: number;
    athletesCount: number;
  }>(),
  { token: '', invitesCount: 0, athletesCount: 0 },
);

const navigatorShareAvailable = ref(false);
const inviteTokenUsedForCopy = ref<string | null>(null);
const isCopied = ref(false);

onMounted(() => {
  navigatorShareAvailable.value = !!navigator.share;
});

const inviteUrl = computed(() => {
  return `${window.location.origin}${
    PUBLIC_ROUTES[PUBLIC_ROUTE_NAMES.REGISTER]
  }?t=${props.token}`;
});

const buttonConfig = computed(() => {
  if (navigatorShareAvailable.value) {
    return {
      text: 'Share my invite',
      icon: ShareIcon,
    };
  }

  return {
    text: !inviteTokenUsedForCopy.value ? 'Copy my url' : 'Copied',
    icon: !inviteTokenUsedForCopy.value ? DocumentDuplicateIcon : CheckIcon,
  };
});

const share = () => {
  navigatorShareAvailable.value ? shareInvite() : writeInviteLinkToClipboard();
};

const shareInvite = () => {
  if (!navigatorShareAvailable.value) {
    return;
  }

  navigator.share({
    title: 'AthleticNext',
    text: 'You have been invited to join a team on AthleticNext',
    url: inviteUrl.value,
  });
};

const writeInviteLinkToClipboard = async (): Promise<void> => {
  if (!navigator.clipboard) {
    return;
  }

  await copyText(inviteUrl.value);
  inviteTokenUsedForCopy.value = inviteUrl.value;
};

const copy = async () => {
  await copyText(inviteUrl.value);
  isCopied.value = true;

  setTimeout(() => (isCopied.value = false), 2000);
};
</script>

<style scoped>
@import url('../../styles/transitions.css');

@media (orientation: landscape) {
  .invite-wrapper {
    margin-bottom: 6rem;
  }

  @screen md {
    .invite-wrapper {
      margin-bottom: 0rem !important;
    }
  }
}
</style>
