<template>
  <router-view />
  <CookieConsentWidget />
  <SupportChat v-if="appUser" />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
import { appUser } from 'src/core/UserStore';
import CookieConsentWidget from 'src/components/common/CookieConsentWidget.vue';

const SupportChat = defineAsyncComponent(
  () => import('src/components/Support/SupportChat.vue'),
);
</script>
