export const VIDEO_EVENTS = {
  CHANGE_PLAYBACKRATE: Symbol('CHANGE_PLAYBACKRATE'),
  UPDATE_CURRENT_TIME: Symbol('UPDATE_CURRENT_TIME'),
  VIDEO_SEEK_START: Symbol('VIDEO_SEEK_START'),
  VIDEO_SEEK_END: Symbol('VIDEO_SEEK_END'),
};

export const VIDEO_PLAYER_EVENTS = {
  TOGGLE_VIDEO_AI: Symbol('TOGGLE_VIDEO_AI'),
  TOGGLE_VIDEO_CANVAS: Symbol('TOGGLE_VIDEO_CANVAS'),
  TOGGLE_IMAGE_SLIDER: Symbol('TOGGLE_IMAGE_SLIDER'),
  TOGGLE_SIDE_BY_SIDE_VIDEOS: Symbol('TOGGLE_SIDE_BY_SIDE_VIDEOS'),
};

export const CONTEXT_CARD_EVENTS = {
  CLOSE: Symbol('CONTEXT_CARD_EVENTS_CLOSE'),
};

export const POSE_DETECTOR_EVENTS = {
  INITIALIZED: Symbol('INITIALIZED'),
};

export const VIDEO_RECORDER_EVENTS = {
  RECORD: Symbol('RECORD'),
  CLOSE: Symbol('CLOSE'),
  SAVE: Symbol('SAVE'),
};

export const VOICE_COMMENT_ACTION = {
  START_RECORDING: Symbol('START_RECORDING'),
  STOP_RECORDING: Symbol('STOP_RECORDING'),
  UPLOAD: Symbol('UPLOAD'),
  GET_COMMENTS: Symbol('GET_COMMENTS'),
};

export const VOICE_VISUALIZATION_EVENT = {
  DRAW: Symbol('DRAW'),
  DRAW_BLOB: Symbol('DRAW_BLOB'),
};

export const CANVAS_DRAWING_EVENTS = {
  MODE_CHANGE: Symbol('MODE_CHANGE'),
  LINE: {
    UNDO: Symbol('LINE_UNDO'),
    CLEAR: Symbol('LINE_CLEAR'),
    OPTIONS_CHANGED: Symbol('LINE_OPTIONS_CHANGED'),
  },
  ANGLE: {
    CLEAR: Symbol('ANGLE_CLEAR'),
    OPTIONS_CHANGED: Symbol('ANGLE_OPTIONS_CHANGED'),
  },
};
