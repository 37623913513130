<template>
  <div class="mt-6 border-t">
    <div
      class="mt-2 flex flex-col md:flex-row md:items-center"
      :class="[
        isLoadingSubscriptionPlan ? 'justify-center' : 'justify-between',
      ]"
    >
      <ANSpinner v-if="isLoadingSubscriptionPlan" class="mt-4" />
      <template v-else>
        <div class="md:flex">
          <div class="whitespace-nowrap font-medium md:w-32 md:shrink-0">
            Current Plan
          </div>
          <div class="md:mt-0 md:px-4">
            <span class="capitalize">
              {{ subscriptionPlan?.plan.subscription.plan }}
            </span>
            <p class="text-xs text-gray-500">
              <template v-if="hasActiveSubscription">
                <template v-if="!activeSubscriptionPlan?.canceledAt">
                  You have active AthleticNext subscription which will renew on
                  <span class="font-medium text-gray-700">{{
                    subscriptionRenewalDate
                  }}</span>
                </template>
                <template v-else-if="activeSubscriptionPlan?.canceledAt">
                  Your AthleticNext subscription was canceled on
                  <span class="font-medium text-gray-700">{{
                    subscriptionCancelDate
                  }}</span
                  >. You can still use all the features of your current plan
                  until
                  <span class="font-medium text-gray-700">{{
                    subscriptionRenewalDate
                  }}</span>
                </template>
              </template>
              <template v-else>
                Free plan allows uploading
                <span class="font-medium text-gray-700">1 video</span> to test
                all features of AthleticNext. To unlock
                <span class="font-medium text-gray-700">unlimited number</span>
                of video uploads and keep using all the Free plan features,
                please upgrade your plan to
                <span class="font-medium text-gray-700">Individual</span>.
              </template>
            </p>
          </div>
        </div>
        <div class="md:w-40 md:shrink-0">
          <ANButton
            v-if="hasActiveSubscription"
            :loading="isRedirectingInprogress"
            :disabled="isRedirectingInprogress"
            class="mt-4 w-full whitespace-nowrap md:mt-0"
            @click="redirectToCustomerPortal"
          >
            Manage Subscription
          </ANButton>
          <ANButton
            v-else
            class="mt-4 w-full whitespace-nowrap md:mt-0"
            @click="isSubscriptionModalOpen = true"
          >
            Upgrade plan
          </ANButton>
        </div>
      </template>
    </div>
  </div>

  <ANModal
    v-if="isSubscriptionModalOpen"
    closable
    size="Small"
    @close="isSubscriptionModalOpen = false"
  >
    <template #title> Upgrade plan </template>

    <div class="mt-6 grid grid-cols-2 gap-4">
      <button
        type="button"
        class="relative w-full overflow-hidden rounded-lg border-2 px-4 py-3"
        :class="[
          billingInterval === 'monthly'
            ? 'border-sky-800 bg-white'
            : 'border-slate-300 hover:bg-slate-100',
        ]"
        @click="billingInterval = 'monthly'"
      >
        <div>Monthly</div>
        <div>€29.99 / month</div>
      </button>
      <button
        type="button"
        class="relative w-full overflow-hidden rounded-lg border-2 border-gray-300 px-4 py-3"
        :class="[
          billingInterval === 'yearly'
            ? 'border-sky-800 bg-white'
            : 'border-slate-300 hover:bg-slate-100',
        ]"
        @click="billingInterval = 'yearly'"
      >
        <div
          class="absolute right-0.5 top-0.5 rounded-md bg-sky-800 px-1 py-px text-xxs capitalize text-white"
        >
          save 2 months
        </div>
        <div>Yearly</div>
        <div>€299.99 / year</div>
      </button>
    </div>

    <div class="mt-6 text-sm leading-6 text-slate-800">
      <div class="flex items-center">
        <CheckBadgeIcon class="h-4 w-4 text-green-600" />
        <p class="ml-2">Unlimited number of video uploads</p>
      </div>
      <div class="flex items-center">
        <CheckBadgeIcon class="h-4 w-4 text-green-600" />
        <p class="ml-2">Access videos from any device and from anywhere</p>
      </div>
      <div class="flex items-center">
        <CheckBadgeIcon class="h-4 w-4 text-green-600" />
        <p class="ml-2">Use AthleticNext's video analysis tools</p>
      </div>
      <div class="flex items-center">
        <CheckBadgeIcon class="h-4 w-4 text-green-600" />
        <p class="ml-2">Organize your videos in albums</p>
      </div>
      <div class="flex items-center">
        <CheckBadgeIcon class="h-4 w-4 text-green-600" />
        <p class="ml-2">Share a link to your video with others</p>
      </div>
      <div class="flex items-center">
        <CheckBadgeIcon class="h-4 w-4 text-green-600" />
        <p class="ml-2">
          Use in-browser Artificial Intelligence (AI) analysis tool
        </p>
      </div>
      <div class="flex items-center">
        <CheckBadgeIcon class="h-4 w-4 text-green-600" />
        <p class="ml-2">And many other features</p>
      </div>
    </div>

    <template #action>
      <div class="grid w-full grid-cols-2 gap-4">
        <ANButton full @click="isSubscriptionModalOpen = false"
          >Cancel</ANButton
        >
        <ANButton full dark @click="upgrade(billingInterval)"
          >Upgrade Plan</ANButton
        >
      </div>
    </template>
  </ANModal>
</template>
<script lang="ts" setup>
import { ISubscriptionPlan } from 'src/core/Interfaces/ISubscriptionPlan';
import { SubscriptionHttpService } from 'src/core/services/Subscription/SubscriptionHttpService';
import { computed, onMounted, ref } from 'vue';
import { ANButton, ANModal, ANSpinner } from 'src/components/Uikit/Components';
import { CheckBadgeIcon } from '@heroicons/vue/24/outline';

type BillingInterval = 'monthly' | 'yearly';

const subscriptionHttpService: SubscriptionHttpService =
  new SubscriptionHttpService();
const isLoadingSubscriptionPlan = ref(true);
const isSubscriptionModalOpen = ref(false);
const isRedirectingInprogress = ref(false);
const subscriptionPlan = ref<ISubscriptionPlan | null>(null);
const billingInterval = ref<BillingInterval>('monthly');

onMounted(async () => {
  await fetchSubscriptionPlan();
  isLoadingSubscriptionPlan.value = false;
});

const activeSubscriptionPlan = computed(() => {
  return subscriptionPlan.value?.plan?.subscription ?? null;
});

const hasActiveSubscription = computed(() => {
  if (!activeSubscriptionPlan.value) {
    return 'free';
  }

  return activeSubscriptionPlan.value.plan === 'individual';
});

const subscriptionRenewalDate = computed(() => {
  if (!activeSubscriptionPlan.value?.currentPeriodEnd) {
    return '';
  }

  return new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(activeSubscriptionPlan.value.currentPeriodEnd));
});

const subscriptionCancelDate = computed(() => {
  if (!activeSubscriptionPlan.value?.canceledAt) {
    return '';
  }

  return new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(activeSubscriptionPlan.value.canceledAt));
});

const fetchSubscriptionPlan = async () => {
  subscriptionPlan.value = await subscriptionHttpService.getSubscription();
};

const upgrade = async (billingInterval: BillingInterval) => {
  isRedirectingInprogress.value = true;
  const checkoutSession = await subscriptionHttpService
    .getCheckoutSession(billingInterval)
    .finally(() => {
      isRedirectingInprogress.value = false;
    });

  window.location.href = checkoutSession.url;
};

const redirectToCustomerPortal = async () => {
  isRedirectingInprogress.value = true;
  await fetchSubscriptionPlan();

  if (!subscriptionPlan.value?.plan.customerPortalUrl) {
    isRedirectingInprogress.value = false;

    return;
  }

  window.location.href = subscriptionPlan.value?.plan.customerPortalUrl;
};
</script>
