import { environmentVariables } from 'src/core/config/AppConfig';

const {
  VITE_MEDIA_CDN_URL,
  VITE_APPLICATION_INSIGHTS_KEY,
  VITE_SENTRY_DSN,
  VITE_BFF_API_PATH,
} = environmentVariables;

export const ClientAppConfig = {
  mediaCdnUrl: VITE_MEDIA_CDN_URL,
  applicationInsights: VITE_APPLICATION_INSIGHTS_KEY,
  sentryDsn: VITE_SENTRY_DSN,
};

export const INVITES_PER_USER_LIMIT = 3;

export { VITE_BFF_API_PATH };
