export const ERROR_KEYS = {
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  AUTH_WRONG_PASSWORD: 'auth/wrong-password',
  AUTH_TOO_MANY_REQUESTS: 'auth/too-many-requests',
};

export const ERROR_MESSAGE = {
  [ERROR_KEYS.INVALID_PASSWORD]: 'The password is not valid! Please try again.',
  [ERROR_KEYS.AUTH_WRONG_PASSWORD]:
    'The password is not valid! Please try again.',
  [ERROR_KEYS.AUTH_TOO_MANY_REQUESTS]:
    'Access to this account has been temporarily disabled due to many failed login attempts. You can try again later or contact support@athleticnext.com for further assistance.',
};
