<template>
  <div class="video-player relative">
    <SplitTimeWidget v-if="clock" :clock="clock" :current-time="currentTime" />
    <div class="relative flex justify-center" :class="videoCssClass">
      <video
        :id="videoId"
        ref="videoElement"
        class="video-player__video w-auto"
        :class="{
          hidden: videoDetailsStore.mode === VideoDetailsMode.AiFrames,
        }"
        preload="auto"
        muted
        playsinline
        crossorigin="anonymous"
        @pointerdown="togglePlay"
      />
      <template v-if="videoDetailsStore.mode === VideoDetailsMode.AiFrames">
        <VideoFramesAi
          :video="video"
          :current-time="currentTime"
          :orientation="videoOrientation"
        />
      </template>
      <template v-else>
        <DrawingCanvas
          v-if="showDrawingCanvas"
          class="absolute h-full w-full"
          :video-el="videoEl"
        />
      </template>
    </div>

    <ImageScroll
      v-if="isVideoReadyToPlay"
      :video="video"
      :video-duration="videoDuration"
      :video-current-time="currentTime"
      @update="onImageScrollUpdate"
    />
  </div>
</template>

<script setup lang="ts">
import SplitTimeWidget from 'src/components/Video/VideoPlayer/SplitTimeWidget.vue';
import { ClientAppConfig } from 'src/core/ClientAppConfig';
import { MediaOrientation } from 'src/core/Common.types';
import { useEmitter } from 'src/core/EventEmitter';
import { VIDEO_EVENTS, VIDEO_PLAYER_EVENTS } from 'src/core/Events';
import { IVideo } from 'src/core/Interfaces/IVideo';
import {
  VideoPLayerStateProvider,
  videoPLayerStateKey,
} from 'src/core/providers';
import { useVideoDetailsStore } from 'src/pages/app/VideoDetails/VideoDetails.store';
import { VideoDetailsMode } from 'src/pages/app/VideoDetails/VideoDetails.types';
import { computed, inject, onMounted, onUnmounted, ref } from 'vue';
import DrawingCanvas from './DrawingCanvas.vue';
import ImageScroll from './ImageScroll.vue';
import VideoFramesAi from './VideoFramesAi.vue';

/**
 * Video Player component
 * @deprecated We are using src/pages/app/VideoDetails/VideoDetails.vue for displaying video
 * @todo Remove this component when `/share/videos/:videoId` route is updated and used VideoDetails.vue
 */

const videoDetailsStore = useVideoDetailsStore();

const { setCurrentTime, clock } = inject<VideoPLayerStateProvider>(
  videoPLayerStateKey,
  () => ({}) as VideoPLayerStateProvider,
  true,
);

const emitter = useEmitter();
const videoId = 'videoId';
const timeSpan = 0.1;
const videoElement = ref<unknown>();
const isVideoPlaying = ref(false);
const isVideoReadyToPlay = ref(false);
const currentTime = ref(0);
const playbackRate = ref(1);
const showDrawingCanvas = ref(false);
const showVideoAI = ref(false);
const videoCssClass = ref('');
const videoOrientation = ref<MediaOrientation>(MediaOrientation.Portrait);

let rafId: number;

const props = withDefaults(
  defineProps<{ video: IVideo; hideBackButton?: boolean }>(),
  {
    hideBackButton: false,
  },
);

const videoEl = computed(() => videoElement.value as HTMLVideoElement);

const videoDuration = computed(() => {
  return videoDetailsStore.videoPlayer.duration ?? 0;
});

const setVideoCssClass = () => {
  if (!videoEl.value) {
    return '';
  }

  const { videoHeight = 0, videoWidth = 0 } = videoEl.value;

  videoCssClass.value =
    videoHeight < videoWidth ? 'top-1/2 transform -translate-y-1/2' : '';

  videoOrientation.value =
    videoHeight < videoWidth
      ? MediaOrientation.Landscape
      : MediaOrientation.Portrait;
};

const togglePlay = () => {
  if (isVideoPlaying.value) {
    pause();
  } else {
    play();
  }
};

onMounted(async () => {
  videoEl.value.src = getVideoSrc();
  videoEl.value.load();
  await new Promise((resolve) => {
    videoEl.value.onloadeddata = () => {
      videoDetailsStore.videoPlayer.duration = videoEl.value.duration;

      setVideoCssClass();
      resolve(true);
    };
  });

  videoEl.value.addEventListener('canplaythrough', onCanPlayThroughHandler);
  videoEl.value.addEventListener('loadedmetadata', onLoadedMetadataHandler);
  videoEl.value.addEventListener('play', onPlayHandler);
  videoEl.value.addEventListener('ended', onEndedHandler);

  emitter.on(VIDEO_EVENTS.CHANGE_PLAYBACKRATE, (value) => {
    changePlaybackRate(Number(value));
  });
  emitter.on(VIDEO_PLAYER_EVENTS.TOGGLE_VIDEO_CANVAS, toggleDrawingCanvas);
  emitter.on(VIDEO_PLAYER_EVENTS.TOGGLE_VIDEO_AI, toggleVideoAI);
});

onUnmounted(() => {
  if (!videoEl.value) {
    return;
  }

  videoEl.value.removeEventListener('canplaythrough', onCanPlayThroughHandler);
  videoEl.value.removeEventListener('loadedmetadata', onLoadedMetadataHandler);
  videoEl.value.removeEventListener('play', onPlayHandler);
  videoEl.value.removeEventListener('ended', onEndedHandler);
});

const getVideoSrc = () => {
  const { userId, userUid, blobName, blobUrl, hasKeypoints } = props.video;

  // TODO: Fix videos not being displayed locally using local Azure Storage emulator
  if (blobUrl && !hasKeypoints) {
    return [
      ClientAppConfig.mediaCdnUrl,
      (userId ?? userUid).toLowerCase(),
      blobName,
    ].join('/');
  }

  return [ClientAppConfig.mediaCdnUrl, blobName].join('/');
};

const toggleDrawingCanvas = () => {
  showDrawingCanvas.value = !showDrawingCanvas.value;
};

const toggleVideoAI = () => {
  showVideoAI.value = !showVideoAI.value;
};

const onCanPlayThroughHandler = () => {
  if (!isVideoReadyToPlay.value) {
    play();
  }

  isVideoReadyToPlay.value = true;
};

const onLoadedMetadataHandler = async () => {
  setVideoCssClass();
};

const onPlayHandler = () => {
  function step() {
    if (
      videoEl.value.paused ||
      videoEl.value.currentTime >= videoDuration.value
    ) {
      window.cancelAnimationFrame(rafId);

      return;
    }

    // currentTime.value = videoEl.value.currentTime;
    updateCurrentTime(videoEl.value.currentTime);
    rafId = requestAnimationFrame(step);
  }

  step();
};

const onEndedHandler = () => {
  isVideoPlaying.value = false;
};

const onImageScrollUpdate = (time: number) => {
  if (time === 0) {
    advanceTime(0);

    return;
  }

  const difference = Math.abs(videoEl.value.currentTime - time);

  if (difference >= 0.005) {
    advanceTime(time);
  }
};

const advanceTime = (time: number) => {
  pause();

  let newCurrentTime = time;
  if (time <= 0) {
    newCurrentTime = 0;
  } else if (time + timeSpan >= videoDuration.value) {
    newCurrentTime = videoDuration.value;
  }

  videoEl.value.currentTime = newCurrentTime;
  updateCurrentTime(newCurrentTime);
};

const updateCurrentTime = (value: number): void => {
  currentTime.value = value;
  setCurrentTime(value);
};

const play = () => {
  isVideoPlaying.value = true;
  videoEl.value.play();
};

const pause = () => {
  isVideoPlaying.value = false;
  videoEl.value.pause();
};

const changePlaybackRate = (value: number) => {
  playbackRate.value = 1 / value;
  videoEl.value.playbackRate = playbackRate.value;
};
</script>

<style scoped>
.video-player {
  height: 100vh;
}
.video-player__video {
  touch-action: none;
  max-height: 100vh;
}

.drawer-visible .video-player__video {
  max-height: 50vh;
}

@screen sm {
  .drawer-visible .video-player__video {
    max-height: 100vh;
  }
}
</style>
