import {
  useAuthorizationHeader,
  useContentTypeHeader,
} from 'src/api/api.endpoints';
import { VITE_BFF_API_PATH } from 'src/core/ClientAppConfig';
import { useAppUser } from '../3rdParty/Firebase';
import { ContentTypeHeaders, HttpMethods } from '../Constants';

export abstract class BaseBffHttpService {
  protected abstract readonly basePath: string;
  protected readonly pathSeparator = '/';
  #domain = `${VITE_BFF_API_PATH}/api/`;

  protected async get(path: string, params: { [key: string]: string } = {}) {
    const url = new URL(path, this.#domain);
    const searchParams = new URLSearchParams(params);
    searchParams.forEach((value, key) => {
      url.searchParams.append(key, value);
    });

    return fetch(url, {
      method: HttpMethods.GET,
      headers: useAuthorizationHeader(await this.#getToken()),
    });
  }

  protected async post<T>(path: string, body: T) {
    const isJsonBody = !(body instanceof FormData);
    const payload = isJsonBody ? JSON.stringify(body) : body;

    return fetch(`${this.#domain}${path}`, {
      method: HttpMethods.POST,
      body: payload,
      headers: {
        ...useAuthorizationHeader(await this.#getToken()),
        ...(isJsonBody && useContentTypeHeader(ContentTypeHeaders.JSON)),
      },
    });
  }

  protected async delete(path: string) {
    return fetch(`${this.#domain}${path}`, {
      method: HttpMethods.DELETE,
      headers: useAuthorizationHeader(await this.#getToken()),
    });
  }

  async #getToken() {
    return (await useAppUser()?.getIdToken()) ?? '';
  }
}
