<template>
  <slot />
</template>
<script setup lang="ts">
import { provide, ref } from 'vue';
import { videoPLayerStateKey } from './keys';
import { Clock, VideoPLayerStateProvider } from './types';

const currentTime = ref(0);
const clock = ref<Clock | undefined>();

const setCurrentTime = (value: number) => {
  currentTime.value = value;
};

const setClock = (startTime: number) => {
  clock.value = { startTime };
};

const resetClock = () => {
  clock.value = undefined;
};

provide<VideoPLayerStateProvider>(videoPLayerStateKey, {
  clock,
  setClock,
  resetClock,
  currentTime,
  setCurrentTime,
});
</script>
