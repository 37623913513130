import { onMounted, onUnmounted, ref } from 'vue';

export function useMouseMoveListener() {
  const parentElement = ref<HTMLElement | null>(null);
  const offsetX = ref(0);
  const offsetY = ref(0);

  function update(event: MouseEvent) {
    offsetX.value = event.offsetX;
    offsetY.value = event.offsetY;
  }

  onMounted(() => {
    parentElement.value?.addEventListener('mousemove', update);
  });

  onUnmounted(() => {
    parentElement.value?.removeEventListener('mousemove', update);
  });

  return { offsetX, offsetY, parentElement };
}
