<template>
  <SidebarLayout>
    <GridContainer>
      <div class="col-span-12 mb-20">
        <PageHeader>
          My Teams

          <template #action>
            <div class="flex items-center">
              <ANButton dark @click="openCreateTeamModal">
                <PlusIcon class="mr-2 h-6 w-6" />
                New Team</ANButton
              >
            </div>
          </template>
        </PageHeader>

        <div v-for="team in teams" :key="team.id">
          <RouterLink :to="`/app/teams/${team.id}`">
            {{ team.name }}
            {{ team.name }} - {{ team.description }}
          </RouterLink>
          <ANButton color="danger" @click="deleteTeam(team.id)">
            Delete
          </ANButton>
        </div>
      </div>

      <ANModal v-if="isCreateTeamModalOpen" size="Medium">
        <template #title>Create New team</template>

        <div>
          <ANInput
            v-model="teamName"
            type="text"
            placeholder="Team Name"
            class="mb-4"
          />
          <ANInput
            v-model="teamDescription"
            type="text"
            placeholder="Team Description"
            class="mb-4"
          />
        </div>

        <template #action>
          <ANButton class="mr-2 w-full" @click="isCreateTeamModalOpen = false">
            Cancel
          </ANButton>
          <ANButton dark class="ml-2 w-full" @click="createTeam">
            <p>Create</p>
          </ANButton>
        </template>
      </ANModal>
    </GridContainer>
  </SidebarLayout>
</template>

<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/outline';
import GridContainer from 'src/components/common/GridContainer.vue';
import PageHeader from 'src/components/common/PageHeader.vue';
import SidebarLayout from 'src/components/layout/SidebarLayout.vue';
import { ANButton, ANModal, ANInput } from 'src/components/Uikit/Components';
import { TeamsHttpService, TeamDto } from 'src/core/services/Teams';
import { onMounted, ref } from 'vue';

const teamsHttpService = new TeamsHttpService();

const isCreateTeamModalOpen = ref(false);
const teams = ref<TeamDto[]>([]);
const teamName = ref('');
const teamDescription = ref('');

onMounted(async () => {
  fetchTeams();
});

const fetchTeams = async () => {
  teams.value = (await teamsHttpService.getTeams()).items;
};

const openCreateTeamModal = () => {
  teamName.value = '';
  teamDescription.value = '';
  isCreateTeamModalOpen.value = true;
};

const createTeam = async () => {
  await teamsHttpService.create({
    name: teamName.value,
    description: teamDescription.value,
  });
  fetchTeams();

  isCreateTeamModalOpen.value = false;
};

const deleteTeam = async (teamId: string) => {
  await teamsHttpService.deleteTeam(teamId);

  teams.value = teams.value.filter((team) => team.id !== teamId);
};
</script>
