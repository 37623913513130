<template>
  <ANModal :size="size">
    <template #title> Remove Video </template>

    <slot />

    <template #action>
      <ANButton class="mr-2 w-full" @click="emit('close')"> Cancel </ANButton>
      <ANButton dark class="ml-2 w-full" @click="onConfirm">
        <p>Confirm</p>
      </ANButton>
    </template>
  </ANModal>
</template>

<script lang="ts" setup>
import { ANButton, ANModal } from 'src/components/Uikit/Components';
import { EMIT_EVENTS } from 'src/core/Constants';
import { ModalOptions } from 'src/core/ModalConstants';

const emit = defineEmits([EMIT_EVENTS.CONFIRM, EMIT_EVENTS.CLOSE]);

withDefaults(defineProps<{ size: ModalOptions }>(), {
  size: 'ExtraSmall',
});

const onConfirm = () => {
  emit(EMIT_EVENTS.CONFIRM);
  emit(EMIT_EVENTS.CLOSE);
};
</script>
