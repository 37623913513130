export const COOKIE_AN_WEB_CLIENT = 'an_web_client';
export const COOKIE_COOKIE_CONSENT = 'an_cookie_consent';
export const COOKIE_CSIT = 'an_csit';

export const getCookieValueByName = (name: string): string => {
  const cookie = decodeURIComponent(document.cookie);
  const cookieItems = cookie.split('; ');

  let cookieValue = '';

  for (const item of cookieItems) {
    const [key, value] = item.split('=');

    if (key === name) {
      cookieValue = value;
    }
  }

  return cookieValue;
};
