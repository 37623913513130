<template>
  <transition name="widget">
    <div
      v-if="isCookieWidgetVisible"
      class="fixed bottom-0 right-0 z-50 w-full rounded-lg border border-gray-100 bg-white px-4 py-8 shadow-md sm:right-12 sm:w-80"
    >
      <p class="text-sm font-light">
        By continuing using this website, you agree to our use of cookies. We
        use cookies to provide you with a great experience and to improve the
        services we provide through this website.
      </p>

      <ANButton dark full class="mt-6" @click="setCookie"> I agree </ANButton>
    </div>
  </transition>
</template>

<script setup lang="ts">
import {
  COOKIE_AN_WEB_CLIENT,
  COOKIE_COOKIE_CONSENT,
  getCookieValueByName,
} from 'src/services/utilities/CookieUtilities';
import { onMounted, ref } from 'vue';
import { ANButton } from 'src/components/Uikit/Components';

const isCookieWidgetVisible = ref(false);
const isMobileWebClient =
  getCookieValueByName(COOKIE_AN_WEB_CLIENT) === 'mobile';

onMounted(() => {
  if (isMobileWebClient) {
    return;
  }

  isCookieWidgetVisible.value = !getCookieValueByName(COOKIE_COOKIE_CONSENT);
});

const setCookie = () => {
  const expiresAt = new Date();
  expiresAt.setDate(365);

  document.cookie = `${COOKIE_COOKIE_CONSENT}=${Date.now()}; expires=${expiresAt.toUTCString()}; SameSite=strict; Secure`;
  isCookieWidgetVisible.value = false;
};
</script>

<style scoped>
* {
  will-change: bottom;
}

.widget-enter-active {
  animation: widgetIn 2s;
}
.widget-leave-active {
  animation: widgetOut 0.5s;
}

@keyframes widgetIn {
  from {
    bottom: -30rem;
  }
  to {
    bottom: 0;
  }
}
@keyframes widgetOut {
  from {
    bottom: 0;
  }
  to {
    bottom: -30rem;
  }
}
</style>
