<template>
  <div class="rounded-lg bg-white px-4 py-2">
    <p class="mb-1 text-xs font-semibold text-slate-800">Timer</p>
    <button
      type="button"
      class="flex items-center rounded-md border border-slate-300 bg-slate-100 px-2 py-1 text-sm font-normal text-slate-800"
      @click="handleAddSplit"
    >
      <ClockIcon class="mr-1 h-5 w-5 text-slate-600" />
      {{ clock ? 'Hide' : 'Show' }} Split Time
    </button>
  </div>
</template>
<script setup lang="ts">
import { ClockIcon } from '@heroicons/vue/24/outline';
import {
  VideoPLayerStateProvider,
  videoPLayerStateKey,
} from 'src/core/providers';
import { inject } from 'vue';

const { currentTime, clock, setClock, resetClock } =
  inject<VideoPLayerStateProvider>(
    videoPLayerStateKey,
    () => ({}) as VideoPLayerStateProvider,
    true,
  );

const handleAddSplit = () => {
  if (!clock.value) {
    setClock(currentTime.value);

    return;
  }

  resetClock();
};
</script>
