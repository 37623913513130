<template>
  <div class="p-2">
    <p class="overflow-hidden text-ellipsis pl-3 font-semibold text-gray-900">
      {{ user?.displayName ?? user?.email }}
    </p>
    <p v-if="user?.displayName" class="pl-3 text-xs">{{ user?.email }}</p>

    <hr class="mb-1 mt-3 border-secondary-light" />

    <p
      class="flex items-center rounded-lg p-2 text-sm font-normal leading-6 transition duration-300 hover:bg-secondary-light"
    >
      <CogIcon class="an-dark--text h-5 w-5" />
      <router-link :to="APP_ROUTES.SETTINGS" class="ml-2">
        My Settings
      </router-link>
    </p>

    <p
      class="flex items-center rounded-lg p-2 text-sm font-normal leading-6 transition duration-300 hover:bg-secondary-light"
    >
      <DocumentTextIcon class="an-dark--text h-5 w-5" />
      <router-link :to="PUBLIC_ROUTES.TERMS_AND_CONDITIONS" class="ml-2">
        Terms of Service
      </router-link>
    </p>

    <p
      class="flex items-center rounded-lg p-2 text-sm font-normal leading-6 transition duration-300 hover:bg-secondary-light"
    >
      <ShieldCheckIcon class="an-dark--text h-5 w-5" />

      <router-link :to="PUBLIC_ROUTES.PRIVACY_POLICY" class="ml-2">
        Privacy Policy
      </router-link>
    </p>

    <hr class="my-1 border-secondary-light" />

    <div
      class="flex w-full rounded-lg p-2 font-normal transition duration-300 hover:bg-secondary-light"
    >
      <ArrowLeftOnRectangleIcon class="an-dark--text h-5 w-5" />
      <button
        type="button"
        class="an-dark--text ml-2 text-sm font-medium"
        @click="signOut"
      >
        Sign Out
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  DocumentTextIcon,
  ArrowLeftOnRectangleIcon,
  ShieldCheckIcon,
  CogIcon,
} from '@heroicons/vue/24/outline';
import {
  APP_ROUTES,
  PUBLIC_ROUTES,
  PUBLIC_ROUTE_NAMES,
} from 'src/core/AppRoutes';
import { auth, useAppUser } from 'src/core/3rdParty/Firebase';
import { useRouter } from 'vue-router';

const router = useRouter();
const user = useAppUser();

const signOut = async () => {
  await auth.signOut();
  router.push({ name: PUBLIC_ROUTE_NAMES.ROOT });
};
</script>
