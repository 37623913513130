<template>
  <div class="text-center">
    <button type="button" style="font-size: 0" @click="authorizeUser">
      <img
        src="/sign-in-with-google.svg"
        alt="Sign in with Google"
        class="h-10 rounded"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { APP_ROUTES } from 'src/core/AppRoutes';
import { useRouter } from 'vue-router';

const router = useRouter();
const authorizeUser = async () => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  const signInResult = await signInWithPopup(auth, provider);

  if (!signInResult) {
    return;
  }

  router.push(APP_ROUTES.VIDEOS);
};
</script>
