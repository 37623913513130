import { defineStore } from 'pinia';
import { ref } from 'vue';
import { VideoDetailsMode } from './VideoDetails.types';

export type VideoDetailsStore = {
  mode: VideoDetailsMode;
  resetMode: () => void;
  videoPlayer: {
    duration: number;
  };
};

export const useVideoDetailsStore = defineStore<
  'VideoDetails',
  VideoDetailsStore
>('VideoDetails', () => {
  const mode = ref<VideoDetailsMode>(VideoDetailsMode.VideoPlayer);
  const videoPlayer = ref({ duration: 0 });

  function resetMode() {
    mode.value = VideoDetailsMode.VideoPlayer;
  }

  return { mode: mode.value, resetMode, videoPlayer: videoPlayer.value };
});
