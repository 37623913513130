<template>
  <div class="mx-auto">
    <div
      v-if="label || $slots.labelAfter"
      class="flex items-center justify-between text-xs font-medium text-gray-700"
    >
      <label v-if="label" class="text-left">
        {{ label }}
      </label>
      <slot v-if="$slots.labelAfter" name="labelAfter" />
    </div>
    <input
      :value="modelValue"
      :type="type"
      class="mt-2 block w-full rounded-md border border-gray-300 px-4 shadow-sm outline-none focus:border-gray-400 focus:ring focus:ring-gray-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500"
      :class="small ? 'h-8' : 'h-12'"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="onInput"
      @focus="emit('focus')"
      @blur="emit('blur')"
    />
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['update:modelValue', 'focus', 'blur']);

interface IProps {
  modelValue: string;
  type?: string;
  placeholder?: string;
  small?: boolean;
  label?: string;
  disabled?: boolean;
}

withDefaults(defineProps<IProps>(), {
  modelValue: '',
  type: 'text',
  small: false,
  placeholder: 'Please Enter',
  label: '',
  disabled: false,
});

const onInput = ({ target }: Event) => {
  emit('update:modelValue', (target as HTMLInputElement).value);
};
</script>
