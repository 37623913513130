<template>
  <div class="rounded-lg bg-white px-4 py-2">
    <p class="mb-1 text-xs font-semibold text-slate-800">Playback speed</p>
    <div class="grid grid-cols-4 gap-2">
      <button
        v-for="value of [1, 2, 4, 8]"
        :key="value"
        type="button"
        class="rounded-lg border px-4 py-2 text-xs sm:text-sm"
        :class="{ 'bg-black text-white': playbackRate === value }"
        @click="setPlaybackRate(value)"
      >
        1/{{ value }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { EMIT_EVENTS } from 'src/core/Constants';

const emit = defineEmits([EMIT_EVENTS.CHANGE]);

const props = defineProps<{
  playbackRate: number;
}>();

const setPlaybackRate = (value: number) => {
  if (value === props.playbackRate) {
    return;
  }

  emit(EMIT_EVENTS.CHANGE, value);
};
</script>
