<template>
  <div class="video-player relative">
    <div
      v-if="!store.deviceInfo.isMobileWebClient"
      class="absolute left-0 right-0 top-0 z-10 flex justify-between px-4 py-3"
    >
      <BackButton small light :route-name="APP_ROUTE_NAMES.VIDEO_LIST" />
    </div>
    <div class="flex h-full flex-col justify-center">
      <div class="items-center justify-center landscape:flex landscape:w-full">
        <div
          class="relative flex max-h-full max-w-[100vw] flex-col items-center landscape:w-1/2"
        >
          <video
            :id="videoId"
            ref="sideBySideVideoElement"
            class="aspect-video w-full portrait:max-h-[calc(50vh-64px)]"
            preload="auto"
            muted
            playsinline
            :src="getVideoSrc(video)"
            crossorigin="anonymous"
            @loadedmetadata="
              onlLoadedData(videoEl, STORE_KEYS.CURRENT_TIME_VIDEO_A)
            "
            @pointerdown="togglePlayPause(videoEl)"
            @play="onVideoPlay(videoEl, STORE_KEYS.CURRENT_TIME_VIDEO_A)"
          />
        </div>
        <div
          class="relative flex max-h-full max-w-[100vw] flex-col items-center landscape:w-1/2"
        >
          <ANButton
            class="mx-auto my-2 max-w-[fit-content]"
            :class="{
              'absolute right-2 top-0 z-10':
                comparableVideo && isCompareVideosVisible,
            }"
            :small="Boolean(comparableVideo && isCompareVideosVisible)"
            :rounded="Boolean(comparableVideo && isCompareVideosVisible)"
            @click="showVideoSelector"
          >
            Select video to compare
          </ANButton>
          <video
            v-if="comparableVideo && isCompareVideosVisible"
            ref="sideBySideCompareVideoElement"
            class="aspect-video w-full portrait:max-h-[calc(50vh-64px)]"
            preload="auto"
            muted
            playsinline
            :src="getVideoSrc(comparableVideo)"
            @loadedmetadata="
              onlLoadedData(compareVideoEl, STORE_KEYS.CURRENT_TIME_VIDEO_B)
            "
            @pointerdown="togglePlayPause(compareVideoEl)"
            @play="onVideoPlay(compareVideoEl, STORE_KEYS.CURRENT_TIME_VIDEO_B)"
          />
        </div>
      </div>
      <div
        class="relative items-center justify-center bg-slate-800 landscape:flex landscape:w-full"
      >
        <ImageScrollSimple
          v-if="videoEl"
          class="w-full"
          :video="video"
          :video-duration="videoEl.duration"
          :video-current-time="Number(store[STORE_KEYS.CURRENT_TIME_VIDEO_A])"
          :poster="getPoster(video)"
          @update="
            onScrollProgressUpdate(
              videoEl,
              compareVideoEl,
              STORE_KEYS.CURRENT_TIME_VIDEO_A,
              $event,
            )
          "
        />
        <div
          v-if="comparableVideo && compareVideoEl"
          class="absolute left-1/2 top-2 z-30 -translate-x-1/2 transform portrait:top-1/2 portrait:-mt-2 portrait:-translate-y-1/2"
        >
          <button
            type="button"
            class="s3hadow-lg flex h-12 w-12 flex-col items-center justify-center rounded-full bg-white"
            :class="[
              store.isSideBySideVideoSeekingLocked
                ? 'bg-emerald-200'
                : 'bg-slate-200',
            ]"
            @click="
              store.isSideBySideVideoSeekingLocked =
                !store.isSideBySideVideoSeekingLocked
            "
          >
            <div>
              <template v-if="store.isSideBySideVideoSeekingLocked">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.0404 16.2473L6.00617 16.2468C3.66027 16.2468 1.75985 14.3454 1.75885 11.9995C1.75935 9.65407 3.66027 7.75316 6.00567 7.75266L13.0399 7.75316L13.0399 9.16909L6.00616 9.16809C4.44523 9.16909 3.17529 10.439 3.17479 11.9995C3.17529 13.5609 4.44523 14.8308 6.00616 14.8308L13.0399 14.8308L13.0404 16.2473ZM17.9936 7.75316L13.0399 7.75316L13.0399 9.16909L17.9941 9.16859C19.5556 9.16909 20.8255 10.439 20.8255 12C20.8255 13.5609 19.5556 14.8308 17.9946 14.8308L13.0399 14.8308L13.0404 16.2473L17.9951 16.2473C20.3395 16.2468 22.2409 14.3454 22.2419 12.0005C22.2409 9.65457 20.339 7.75366 17.9936 7.75316Z"
                    fill="currentColor"
                  />
                  <line
                    x1="8.71001"
                    y1="11.9"
                    x2="15.28"
                    y2="11.9"
                    stroke="currentColor"
                    stroke-width="1.6"
                  />
                </svg>
              </template>
              <template v-else>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9154 16.2463L6.00617 16.2468C3.66027 16.2468 1.75985 14.3454 1.75885 11.9995C1.75935 9.65407 3.66027 7.75316 6.00566 7.75266L10.9159 7.75316L10.9164 9.16859L6.00616 9.16809C4.44523 9.16909 3.17529 10.439 3.17479 11.9995C3.17529 13.5609 4.44523 14.8308 6.00616 14.8308L10.9159 14.8308L10.9154 16.2463V16.2463ZM17.9936 7.75316L13.0399 7.75316L13.0399 9.16909L17.9941 9.16859C19.5556 9.1691 20.8255 10.439 20.8255 12C20.8255 13.5609 19.5556 14.8308 17.9946 14.8308L13.0399 14.8308L13.0404 16.2473L17.9951 16.2473C20.3395 16.2468 22.2409 14.3454 22.2419 12.0005C22.2409 9.65457 20.339 7.75366 17.9936 7.75316V7.75316Z"
                    fill="currentColor"
                  />
                  <line
                    x1="9.71387"
                    y1="11.9"
                    x2="14.2854"
                    y2="11.9"
                    stroke="currentColor"
                    stroke-width="1.2"
                  />
                </svg>
              </template>
            </div>
            <p class="-mt-1 text-[6px]">
              SYNC {{ store.isSideBySideVideoSeekingLocked ? 'ON' : 'OFF' }}
            </p>
          </button>
        </div>
        <ImageScrollSimple
          v-if="comparableVideo && compareVideoEl"
          class="w-full"
          :video="comparableVideo"
          :video-duration="compareVideoEl.duration"
          :video-current-time="Number(store[STORE_KEYS.CURRENT_TIME_VIDEO_B])"
          @update="
            onScrollProgressUpdate(
              compareVideoEl,
              videoEl,
              STORE_KEYS.CURRENT_TIME_VIDEO_B,
              $event,
            )
          "
        />
      </div>
    </div>

    <VideoDrawer
      v-if="isVideoSelectVisible"
      should-skip-navigation
      :unselectable-video-ids="!!comparableVideo ? [comparableVideo.id] : []"
      @select="onCompareVideos"
      @close="isVideoSelectVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ANButton } from 'src/components/Uikit/Components';
import { ClientAppConfig } from 'src/core/ClientAppConfig';
import { IVideo } from 'src/core/Interfaces/IVideo';
import { getPoster } from 'src/core/Utils/VideoUtils';
import { STORE_KEYS, store } from 'src/store';
import { computed, ref } from 'vue';
import ImageScrollSimple from './ImageScrollSimple.vue';
import VideoDrawer from './VideoDrawer.vue';
import BackButton from '../common/BackButton.vue';
import { APP_ROUTE_NAMES } from 'src/core/AppRoutes';

const videoId = 'sideBySideVideoId';
const TIME_SPAN = 0.1;
const sideBySideVideoElement = ref<unknown>();
const sideBySideCompareVideoElement = ref<unknown>();
const isVideoSelectVisible = ref(false);
const comparableVideo = ref<IVideo | null>(null);
const isCompareVideosVisible = ref(false);

withDefaults(defineProps<{ video: IVideo }>(), {});

const rafIDs = {
  [STORE_KEYS.CURRENT_TIME_VIDEO_A]: 0,
  [STORE_KEYS.CURRENT_TIME_VIDEO_B]: 1,
};

const videoEl = computed(
  () => sideBySideVideoElement.value as HTMLVideoElement,
);

const compareVideoEl = computed(
  () => sideBySideCompareVideoElement.value as HTMLVideoElement,
);

const togglePlayPause = (videoDomNode: HTMLVideoElement) => {
  if (videoDomNode.paused) {
    videoDomNode.play();
  } else {
    videoDomNode.pause();
  }
};

const showVideoSelector = () => {
  store.isSideBySideVideoSeekingLocked = false;
  isVideoSelectVisible.value = true;
};

const onVideoSelect = (video: IVideo) => {
  comparableVideo.value = video;
};

const onCompareVideos = async (video: IVideo) => {
  onVideoSelect(video);

  isCompareVideosVisible.value = true;
  isVideoSelectVisible.value = false;
};

const onlLoadedData = (
  videoDomNode: HTMLVideoElement,
  storeKey: keyof typeof STORE_KEYS,
) => {
  store[storeKey] = 0;
  videoDomNode.play();
};

const getVideoSrc = (video: IVideo): string => {
  const { userId, userUid, blobName, blobUrl, hasKeypoints } = video;

  if (!blobUrl || hasKeypoints) {
    return [ClientAppConfig.mediaCdnUrl, blobName].join('/');
  }

  return [
    ClientAppConfig.mediaCdnUrl,
    (userId ?? userUid).toLowerCase(),
    blobName,
  ].join('/');
};

const onVideoPlay = (
  videoDomNode: HTMLVideoElement,
  storeKey: keyof typeof STORE_KEYS,
) => {
  function step() {
    if (
      videoDomNode.paused ||
      videoDomNode.currentTime >= videoDomNode.duration
    ) {
      window.cancelAnimationFrame(rafIDs[storeKey]);

      return;
    }

    store[storeKey] = videoDomNode.currentTime;
    rafIDs[storeKey] = requestAnimationFrame(step);
  }

  step();
};

const onScrollProgressUpdate = (
  videoDomNode: HTMLVideoElement,
  compareVideoDomNode: HTMLVideoElement,
  storeKey: keyof typeof STORE_KEYS,
  progress: number,
) => {
  videoDomNode.pause();

  let difference = 0;

  if (progress !== 0) {
    const { duration, currentTime } = videoDomNode;
    const time = duration * progress;
    difference = time - currentTime;
  }

  if (Math.abs(difference) < 0.005) {
    return;
  }

  advanceVideoTime(videoDomNode, difference);
  store[storeKey] = videoDomNode.currentTime;

  if (compareVideoDomNode && store.isSideBySideVideoSeekingLocked) {
    advanceVideoTime(compareVideoDomNode, difference);
    const compareVideoKey =
      storeKey === STORE_KEYS.CURRENT_TIME_VIDEO_A
        ? STORE_KEYS.CURRENT_TIME_VIDEO_B
        : STORE_KEYS.CURRENT_TIME_VIDEO_A;
    store[compareVideoKey] = compareVideoDomNode.currentTime;
  }
};

const advanceVideoTime = (
  videoDomNode: HTMLVideoElement,
  difference: number,
) => {
  if (!videoDomNode.paused) {
    videoDomNode.pause();
  }

  const { currentTime, duration } = videoDomNode;
  let newCurrentTime = currentTime + difference;

  if (newCurrentTime <= 0) {
    newCurrentTime = 0;
  } else if (newCurrentTime + TIME_SPAN >= duration) {
    newCurrentTime = duration;
  }

  videoDomNode.currentTime = newCurrentTime;
};
</script>

<style>
@media (orientation: portrait) {
  .mobile-web-client-top-padding {
    padding-top: 80px;
  }
}
</style>

<style scoped>
.video-player {
  height: 100vh;
}
.video-player__video {
  touch-action: none;
  max-height: 100vh;
}

.drawer-visible .video-player__video {
  max-height: 50vh;
}

@screen sm {
  .drawer-visible .video-player__video {
    max-height: 100vh;
  }
}
</style>
