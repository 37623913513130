<template>
  <div
    class="relative flex h-full min-h-screen w-screen flex-col bg-slate-100 md:flex-row"
  >
    <!-- Desktop Sidebar -->
    <div class="relative z-50 hidden w-70 min-w-70 flex-col md:flex">
      <div
        class="fixed left-0 top-0 z-50 h-full w-70 border-r border-secondary bg-gray-900 pb-10"
      >
        <!-- <NavigationHeader /> -->
        <div class="flex items-center border-b border-slate-600 px-6 py-4">
          <img
            src="/athleticnext-logo-48-yelllow.svg"
            alt="AthleticNext logo"
            class="h-8"
          />
          <h1 class="pl-2 text-lg font-medium text-white">AthleticNext</h1>
        </div>
        <AppNavigation class="mt-2" />
      </div>
      <GlobalNavBar class="z-40" />
      <ProductUpdatesModal />
    </div>

    <!-- Mobile Topbar -->
    <div
      class="fixed left-0 top-0 z-40 flex h-16 min-h-16 w-screen items-center justify-between border-b border-secondary-light bg-gray-900 px-8 md:hidden"
    >
      <Bars3Icon
        class="h-7 w-7 cursor-pointer text-white transition duration-300 ease-in-out hover:text-gray-200"
        @click="isMobileMenuOpen = true"
      />
      <img src="/aN_logo_light.svg" alt="AthleticNext logo" class="h-7" />
      <NavigationHeader small />
    </div>

    <ANModal
      v-if="isMobileMenuOpen"
      class="md:hidden"
      closable
      size="Full"
      @close="isMobileMenuOpen = false"
    >
      <template #title> Navigation </template>

      <AppNavigation class="mt-10" />
    </ANModal>

    <div class="h-full w-full pt-16 md:pt-10">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Bars3Icon } from '@heroicons/vue/20/solid';
import GlobalNavBar from 'src/components/Navigation/GlobalNavBar.vue';
import NavigationHeader from 'src/components/Navigation/NavigationHeader.vue';
import { ANModal } from 'src/components/Uikit/Components';
import AppNavigation from 'src/components/layout/AppNavigation.vue';
import ProductUpdatesModal from 'src/components/Navigation/ProductUpdatesModal.vue';
import { ref } from 'vue';

const isMobileMenuOpen = ref(false);
</script>
