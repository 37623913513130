export const getElementDimensionInfo = (element: HTMLElement | undefined) => {
  if (!element) {
    return { height: 0, width: 0, x: 0, y: 0 };
  }

  const { height, width, x, y } = element.getBoundingClientRect();

  return { height, width, x, y };
};

export const toggleClassOnBody = (className: string) => {
  const element = document.body;
  const { classList } = element;

  if (classList.contains(className)) {
    classList.remove(className);

    return;
  }

  classList.add(className);
};

export const useBodyClassToggler = (className: string) => {
  const element = document.body;
  const { classList } = element;

  return {
    add: () => {
      classList.add(className);
    },
    remove: () => {
      classList.remove(className);
    },
  };
};

export const getSimpleId = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const copyText = async (text: string) => {
  const copy = navigator.clipboard.writeText(text);

  return await copy;
};
