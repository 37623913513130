import { IStatistics } from '../Interfaces/IStatistics';
import BaseRealtimeDBRepository from './Albums/BaseRealtimeDBRepository';

export class PublicRepository extends BaseRealtimeDBRepository {
  async GetStatistics() {
    return (await this.getData<{ statistics: unknown }>('public', {
      statistics: {},
    })) as IStatistics;
  }
}
