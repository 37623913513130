<template>
  <div class="relative">
    <div class="absolute left-1/2 top-0 z-20 h-16 w-0.5 bg-yellow-300"></div>
    <div
      ref="scrollWrapper"
      class="relative flex overflow-x-auto pb-4"
      @scroll="onScrollHandler"
      @pointerdown="isScrollHandlerDisabled = false"
    >
      <div class="h-16 w-1/2 shrink-0 grow-0"></div>
      <div ref="imagesWrapper" class="flex h-16 w-max shrink-0 grow-0">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image"
          loading="lazy"
          class="image-scroll_image h-16"
          @load="onLoadImageHandler"
        />
      </div>
      <div class="h-16 w-1/2 shrink-0 grow-0"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ClientAppConfig } from 'src/core/ClientAppConfig';
import { useEmitter } from 'src/core/EventEmitter';
import { VIDEO_PLAYER_EVENTS } from 'src/core/Events';
import { IVideo } from 'src/core/Interfaces/IVideo';
import { getFramesDirectory } from 'src/core/Utils/VideoUtils';
import { computed, ref, onMounted, watch } from 'vue';

const emit = defineEmits(['update']);

const scrollWrapper = ref<unknown>();
const imagesWrapper = ref<unknown>();
const isScrollHandlerDisabled = ref(false);
const emitter = useEmitter();

const props = withDefaults(
  defineProps<{
    video: IVideo;
    videoDuration: number;
    videoCurrentTime: number;
  }>(),
  {},
);
const scrollWrapperElement = ref<HTMLDivElement>();
const scrollWrapperClientWidth = ref(0);
const loadedImagesCount = ref(0);
const isSliderOpen = ref(true);

watch(
  () => props.videoCurrentTime,
  (currentTime) => {
    isScrollHandlerDisabled.value = true;
    const timeProgress = currentTime / props.videoDuration;

    (scrollWrapper.value as HTMLDivElement).scrollLeft =
      (imagesWrapper.value as HTMLDivElement).scrollWidth *
      (timeProgress > 1 ? 1 : timeProgress);
  },
);

onMounted(() => {
  emitter.on(VIDEO_PLAYER_EVENTS.TOGGLE_IMAGE_SLIDER, toggleImageSlider);
});

const filmstripPath = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pathA, pathB, _] = props.video.blobName.split('/');
  const { userId, userUid } = props.video;

  return `${ClientAppConfig.mediaCdnUrl}/${(
    userId ?? userUid
  ).toLowerCase()}/${pathA}/${pathB}/filmstrip/`;
});

const images = computed(() => {
  if (!props.video.blobUrl || props.video.hasKeypoints) {
    return frames.value;
  }

  return [...Array(Math.floor(5 * 1))].map((_, i) => {
    return `${filmstripPath.value}${i + 1}.jpeg`;
  });
});

const frames = computed(() => {
  const framesDir = getFramesDirectory(props.video.blobName);

  return [...Array(Math.floor(5 * 1))].map((_, i) => {
    const fileName = `${i + 1}`.padStart(4, '0');

    return `${framesDir}/f${fileName}.jpeg`;
  });
});

const setScrollWrapperClientWidth = () => {
  if (!scrollWrapperElement.value) {
    return;
  }

  scrollWrapperClientWidth.value = scrollWrapperElement.value.clientWidth;
};

const toggleImageSlider = () => {
  isSliderOpen.value = !isSliderOpen.value;
};

const onLoadImageHandler = () => {
  loadedImagesCount.value = loadedImagesCount.value + 1;

  if (loadedImagesCount.value === images?.value?.length) {
    setScrollWrapperClientWidth();
  }
};

const onScrollHandler = () => {
  if (isScrollHandlerDisabled.value) {
    isScrollHandlerDisabled.value = false;

    return;
  }

  const { scrollLeft } = scrollWrapper.value as HTMLDivElement;
  const { scrollWidth } = imagesWrapper.value as HTMLDivElement;

  const scrollProgress = scrollLeft / scrollWidth;
  const progress =
    scrollProgress > 1 ? 1 : scrollProgress < 0 ? 0 : scrollProgress;

  emit('update', progress);
};
</script>

<style scoped>
.image-scroll_image {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.slide-enter-active {
  animation: slideIn 0.2s;
}
.slide-leave-active {
  animation: slideOut 0.2s;
}

@keyframes slideIn {
  from {
    bottom: -5rem;
  }
  to {
    bottom: 0;
  }
}
@keyframes slideOut {
  from {
    bottom: 0;
  }
  to {
    bottom: -5rem;
  }
}
</style>
