<template>
  <RegistrationLayout class="an-gradient-bg relative">
    <h1 class="mb-9 mt-4 px-4 text-center text-2xl font-bold text-white">
      Restore password
    </h1>

    <div class="rounded-md bg-white p-12">
      <template v-if="success">
        <SuccessNotification>
          Password reset link has been sent to your email
          {{ email }}
        </SuccessNotification>

        <div class="mt-6 flex text-base font-normal">
          <p class="text-primary">Have the password?</p>
          <a
            href="/login"
            class="mx-1 text-blue-500 transition duration-300 hover:text-primary"
          >
            Sign in
          </a>
        </div>
      </template>

      <form v-else>
        <ANInput
          v-model="email"
          type="email"
          label="Email"
          placeholder="Enter Your Email"
          @keydown.enter="submit"
        />

        <ErrorNotification
          v-if="error"
          class="mt-4 rounded-md bg-white p-2 text-red-500"
        >
          <p class="text-sm text-red-500">
            Something went wrong! Please try again or get in touch with us via
            <a
              class="inline-flex whitespace-nowrap text-blue-500 transition duration-300 hover:text-primary"
              href="mailto:support@athleticnext.com"
            >
              support@athleticnext.com
            </a>
          </p>
        </ErrorNotification>

        <ANButton
          class="mt-6"
          :disabled="!email && !isInProgress"
          dark
          full
          @click="submit"
        >
          <ANSpinner v-if="isInProgress" light />
          <template v-else>Restore password</template>
        </ANButton>

        <a
          href="/login"
          class="an-dark--text my-4 mt-4 block text-center text-sm font-bold transition duration-200 ease-in-out hover:text-orange-500"
        >
          Back to login
        </a>
      </form>
    </div>
  </RegistrationLayout>
</template>
<script setup lang="ts">
import RegistrationLayout from '../../components/layout/RegistrationLayout.vue';
import ErrorNotification from 'src/components/common/ErrorNotification.vue';
import { ref } from 'vue';
import { auth } from 'src/core/3rdParty/Firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import SuccessNotification from 'src/components/common/SuccessNotification.vue';
import { ANInput, ANButton, ANSpinner } from 'src/components/Uikit/Components';

const error = ref(false);
const success = ref(false);
const isInProgress = ref(false);
const email = ref();

const submit = () => {
  isInProgress.value = true;
  error.value = false;

  sendPasswordResetEmail(auth, email.value)
    .then(() => {
      success.value = true;
    })
    .catch(() => {
      error.value = true;
    })
    .finally(() => {
      isInProgress.value = false;
    });
};
</script>
