<template>
  <div class="flex justify-center bg-black">
    <div class="w-full max-w-screen-xl px-4 py-7">
      <div class="mb-2 flex items-center justify-between text-xs">
        <img src="/aN_logo_light.svg" alt="AthleticNext logo" class="h-7" />

        <div class="flex items-center">
          <a
            v-for="({ link, icon }, index) in socialIcons"
            :key="index"
            :href="link"
            target="_blank"
            class="shrink-0"
          >
            <img
              class="h-6 fill-white opacity-95 transition duration-300 ease-in-out hover:fill-orange-400"
              :class="{ 'ml-4': index }"
              :src="`/soc-${icon}.svg`"
            />
          </a>
        </div>
      </div>

      <div
        class="mt-5 flex w-full items-center justify-center text-xs font-medium text-white"
      >
        <template v-for="({ label, url }, index) in routes" :key="label">
          <router-link
            :to="url"
            class="font-bold transition duration-300 ease-in-out hover:text-orange-400"
          >
            {{ label }}
          </router-link>
          <span v-if="index !== routes.length - 1" class="mx-2"> | </span>
        </template>
      </div>

      <div class="mt-6 w-full text-center text-xs text-white">
        <p>Einervegen 1, 4737 Hordnes</p>
        <p>Org.nr.: 928 940 055</p>
        <a
          href="mailto:support@athleticnext.com"
          class="cursor-pointer font-bold transition duration-300 ease-in-out hover:text-orange-400"
        >
          support@athleticnext.com
        </a>
        <p class="mt-4">
          Copyright © {{ currentYear }} AthleticNext AS. All Rights Reserved
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PUBLIC_ROUTES } from 'src/core/AppRoutes';

const socialIcons = [
  { icon: 'linkedin', link: 'https://www.linkedin.com/company/athleticnext/' },
  { icon: 'instagram', link: 'https://www.instagram.com/athleticnext/' },
];

const currentYear = new Date().getFullYear();

const routes = [
  { label: 'Terms of Service', url: PUBLIC_ROUTES.TERMS_AND_CONDITIONS },
  { label: 'Privacy Policy', url: PUBLIC_ROUTES.PRIVACY_POLICY },
];
</script>
