import {
  ISubscriptionCheckoutSession,
  ISubscriptionPlan,
} from 'src/core/Interfaces/ISubscriptionPlan';
import { BaseBffHttpService } from 'src/core/services/BaseBffHttpService';

export class SubscriptionHttpService extends BaseBffHttpService {
  basePath = 'subscription/checkout-session';

  async getSubscription(): Promise<ISubscriptionPlan> {
    const response = await this.get('subscription');

    return response.json();
  }

  async getCheckoutSession(
    interval: string,
  ): Promise<ISubscriptionCheckoutSession> {
    const response = await this.get(`${this.basePath}/${interval}`);

    return response.json();
  }
}
