<template>
  <ANContentSection title="Account">
    <p v-if="memberSince" class="text-xs font-medium text-gray-500">
      AthleticNext member since {{ memberSince }}
    </p>

    <SubscriptionPlan v-if="AppFeatureFlags.isSubscriptionEnabled" />
    <div v-else class="mt-6 border-t">
      <div
        class="mt-2 flex flex-col justify-between md:flex-row md:items-center"
      >
        <div class="md:flex">
          <div class="whitespace-nowrap font-medium md:w-32 md:shrink-0">
            Current Plan
          </div>
          <div class="md:mt-0 md:px-4">
            Free
            <p class="text-xs text-gray-500">
              Individual plan allows uploading unlimited number of videos and
              access all features. The plan includes 1 GB of video storage for
              free.
            </p>
          </div>
        </div>
        <div class="md:w-36 md:shrink-0">
          <ANButton
            class="mt-4 w-full whitespace-nowrap md:mt-0"
            @click="isSubscriptionModalOpen = true"
          >
            Upgrade plan
          </ANButton>
        </div>
      </div>
    </div>

    <ANModal
      v-if="isSubscriptionModalOpen"
      closable
      size="ExtraSmall"
      @close="isSubscriptionModalOpen = false"
    >
      <template #title> Upgrade plan </template>

      <div class="mt-6 flex justify-center">
        <img class="h-32" src="/subscriptions.svg" alt="Subscription" />
      </div>

      <p class="mt-8 w-full text-center text-xs text-gray-900">
        Currently you are using free plan that includes 1 GB of video storage.
        In case you would like to upgrade your plan, please get in touch with us
        via e-mail
        <span class="text-sm font-semibold">support@athleticnext.com</span>
      </p>

      <template #action>
        <a
          href="mailto:support@athleticnext.com"
          class="flex w-full cursor-pointer items-center justify-center rounded-lg border border-gray-700 bg-gray-700 px-5 py-2.5 text-center text-sm font-medium text-white shadow-sm transition-all hover:border-gray-900 hover:bg-gray-900 sm:w-2/3"
        >
          Contact us
        </a>
      </template>
    </ANModal>
  </ANContentSection>
</template>

<script setup lang="ts">
import SubscriptionPlan from 'src/components/Account/SubscriptionPlan.vue';
import {
  ANButton,
  ANContentSection,
  ANModal,
} from 'src/components/Uikit/Components';
import { useAppUser } from 'src/core/3rdParty/Firebase';
import { AppFeatureFlags } from 'src/core/config/AppFeatureFlags';
import { ref } from 'vue';

const user = useAppUser();

const memberSince =
  user?.metadata.creationTime &&
  new Intl.DateTimeFormat('en', {
    month: 'long',
    year: 'numeric',
  }).format(new Date(user?.metadata.creationTime));

const isSubscriptionModalOpen = ref(false);
</script>
