<template>
  <transition name="slide">
    <div v-show="isSliderOpen" class="absolute bottom-0 left-0 right-0">
      <div
        class="relative h-20 overflow-x-hidden pt-4"
        style="touch-action: none"
        @pointerdown.prevent="onDragStartHandler"
        @pointermove.prevent="onDragHandler"
        @pointerup="onDragEndHandler"
      >
        <div
          class="js-target absolute left-1/2 z-10 h-16 w-0.5 -translate-x-1/2 transform bg-yellow-300"
        >
          <p
            class="absolute left-0 top-0 rounded-sm bg-yellow-300 p-0.5 text-xs tabular-nums"
            style="transform: translate(-50%, -80%)"
          >
            {{ videoCurrentTime.toFixed(2) }}
          </p>
        </div>
        <div
          ref="scrollWrapperElement"
          class="absolute bottom-0 left-1/2 top-0 mt-4 flex"
          style="width: max-content; touch-action: none; font-size: 0"
          :style="`transform: translateX(${positionX}px);`"
        >
          <img
            v-for="(image, index) in images"
            :key="index"
            :src="image"
            loading="lazy"
            class="image-scroll_image h-16"
            @error="onLoadImageCountHandler"
            @load="onLoadImageCountHandler"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ClientAppConfig } from 'src/core/ClientAppConfig';
import { useEmitter } from 'src/core/EventEmitter';
import { VIDEO_EVENTS, VIDEO_PLAYER_EVENTS } from 'src/core/Events';
import { IVideo } from 'src/core/Interfaces/IVideo';
import { getFramesDirectory } from 'src/core/Utils/VideoUtils';
import { computed, ref, onMounted } from 'vue';

const emit = defineEmits(['update']);
const emitter = useEmitter();

const props = withDefaults(
  defineProps<{
    video: IVideo;
    videoDuration: number;
    videoCurrentTime: number;
  }>(),
  {},
);

const isScrolling = ref(false);
const currentClientX = ref(0);
const scrollWrapperElement = ref<HTMLDivElement>();
const scrollWrapperClientWidth = ref(0);
const scrollPositionX = ref(0);
const loadedImagesCount = ref(0);
const isSliderOpen = ref(true);

onMounted(() => {
  emitter.on(VIDEO_PLAYER_EVENTS.TOGGLE_IMAGE_SLIDER, toggleImageSlider);
});

const filmstripPath = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pathA, pathB, _] = props.video.blobName.split('/');
  const { userId, userUid } = props.video;

  return `${ClientAppConfig.mediaCdnUrl}/${(
    userId ?? userUid
  ).toLowerCase()}/${pathA}/${pathB}/filmstrip/`;
});

const images = computed(() => {
  if (Number.isNaN(props.videoDuration)) {
    return;
  }

  if (!props.video.blobUrl || props.video.hasKeypoints) {
    return frames.value;
  }

  return [...Array(Math.floor(props.videoDuration * 1))].map((_, i) => {
    return `${filmstripPath.value}${i + 1}.jpeg`;
  });
});

const frames = computed(() => {
  const framesDir = getFramesDirectory(props.video.blobName);

  return [...Array(Math.floor(5 * 1))].map((_, i) => {
    const fileName = `${i + 1}`.padStart(4, '0');

    return `${framesDir}/f${fileName}.jpeg`;
  });
});

const positionX = computed(() => {
  if (
    Number.isNaN(props.videoDuration) ||
    Number.isNaN(props.videoCurrentTime)
  ) {
    return 0;
  }

  return -(
    (props.videoCurrentTime / props.videoDuration) *
    scrollWrapperClientWidth.value
  );
});

const setScrollWrapperClientWidth = () => {
  if (!scrollWrapperElement.value) {
    return;
  }

  scrollWrapperClientWidth.value = scrollWrapperElement.value.clientWidth;
};

const onDragStartHandler = (event: PointerEvent) => {
  isScrolling.value = true;
  currentClientX.value = event.clientX;
  // On drag start reuse the position set from parent component
  scrollPositionX.value = positionX.value;
  emitter.emit(VIDEO_EVENTS.VIDEO_SEEK_START);
};

const onDragHandler = (event: PointerEvent) => {
  if (!isScrolling.value) {
    onDragEndHandler();

    return;
  }

  const clientWidth = scrollWrapperClientWidth.value;
  let newPositionX =
    scrollPositionX.value - (currentClientX.value - event.clientX);

  if (newPositionX <= -clientWidth) {
    newPositionX = -clientWidth;
  } else if (newPositionX >= 0) {
    newPositionX = 0;
  }

  scrollPositionX.value = newPositionX;

  currentClientX.value = event.clientX;
  emit('update', -(scrollPositionX.value / clientWidth) * props.videoDuration);
};

const onDragEndHandler = () => {
  if (!isScrolling.value) {
    return;
  }

  isScrolling.value = false;
  emitter.emit(VIDEO_EVENTS.VIDEO_SEEK_END);
};

const onLoadImageCountHandler = () => {
  loadedImagesCount.value = loadedImagesCount.value + 1;

  if (loadedImagesCount.value === images?.value?.length) {
    setScrollWrapperClientWidth();
  }
};

const toggleImageSlider = () => {
  isSliderOpen.value = !isSliderOpen.value;
};
</script>

<style scoped>
.image-scroll_image {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
}

/* .slider-hidden {
  bottom: -5rem;
} */

.slide-enter-active {
  animation: slideIn 0.2s;
}
.slide-leave-active {
  animation: slideOut 0.2s;
}

@keyframes slideIn {
  from {
    bottom: -5rem;
  }
  to {
    bottom: 0;
  }
}
@keyframes slideOut {
  from {
    bottom: 0;
  }
  to {
    bottom: -5rem;
  }
}
</style>
