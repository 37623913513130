<template>
  <div id="mic" class="h-10 w-full"></div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';

let wavesurfer: WaveSurfer;

onMounted(() => {
  wavesurfer = WaveSurfer.create({
    container: '#mic',
    waveColor: '#0a323c',
    height: 40,
    interact: false,
    cursorWidth: 0,
    barWidth: 1,
    barHeight: 1,
  });

  const record = wavesurfer.registerPlugin(RecordPlugin.create());
  record.startRecording();
});

onBeforeUnmount(() => {
  wavesurfer?.empty();
});
</script>
