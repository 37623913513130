<template>
  <div class="scroll-container my-8 flex overflow-x-scroll scroll-smooth">
    <section
      v-for="({ title, text, image }, index) in props.tabs"
      :key="`${title}-${index}`"
      class="scroll-area relative ml-12 flex h-[420px] flex-shrink-0 snap-center items-center justify-center transition-colors duration-300 ease-in"
      :class="[
        cssClass,
        {
          'ml-8 md:ml-20': index === 0,
          'mr-8 md:mr-20': index === props.tabs.length - 1,
        },
      ]"
    >
      <img
        loading="lazy"
        :src="image"
        class="image h-full w-full rounded-xl bg-no-repeat object-cover"
      />

      <div class="absolute z-10 p-8 text-white">
        <h4 class="text-2xl font-semibold">{{ title }}</h4>

        <p class="text-sm">{{ text }}</p>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
type Tab = {
  title: string;
  text: string;
  image: string;
};

type CarouselProps = {
  tabs: Tab[];
  cssClass?: string;
};

const props = withDefaults(defineProps<CarouselProps>(), {
  tabs: () => [],
  cssClass: 'w-[75vw] md:w-96',
});
</script>

<style scoped>
.scroll-container {
  scroll-snap-type: x mandatory;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.image {
  filter: blur(2px) grayscale(100%) brightness(80%);
  background-position-x: center;
}
</style>
