import { ClientAppConfig } from '../ClientAppConfig';

export const getFrameKey = (frameIndex: number) => {
  return `f${frameIndex.toString().padStart(4, '0')}`;
};

export const getFrameUrl = (frameIndex: number, blobDirectory: string) => {
  return new URL(
    [
      ClientAppConfig.mediaCdnUrl,
      blobDirectory,
      'frames',
      `${getFrameKey(frameIndex)}.jpeg`,
    ].join('/'),
  ).href;
};
