<template>
  <SidebarLayout>
    <GridContainer>
      <div class="col-span-12 mb-20">
        <PageHeader>
          Team

          <template #action>
            <div class="flex items-center">
              <ANButton dark @click="isInviteAthleteModalOpen = true">
                <PlusIcon class="mr-2 h-6 w-6" />
                New Athlete</ANButton
              >
            </div>
          </template>
        </PageHeader>
        {{ pendingInvites }}
      </div>

      <ANModal v-if="isInviteAthleteModalOpen" size="Medium">
        <template #title>Create New team</template>

        <div>
          <ANInput
            v-model="email"
            type="text"
            placeholder="Email"
            class="mb-4"
          />
        </div>

        <template #action>
          <ANButton
            class="mr-2 w-full"
            @click="isInviteAthleteModalOpen = false"
          >
            Cancel
          </ANButton>
          <ANButton dark class="ml-2 w-full" @click="invite">
            <p>Create</p>
          </ANButton>
        </template>
      </ANModal>
    </GridContainer>
  </SidebarLayout>
</template>

<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/outline';
import GridContainer from 'src/components/common/GridContainer.vue';
import PageHeader from 'src/components/common/PageHeader.vue';
import SidebarLayout from 'src/components/layout/SidebarLayout.vue';
import { ANButton, ANModal, ANInput } from 'src/components/Uikit/Components';
import { TeamInvitesHttpService } from 'src/core/services/TeamInvites';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const teamInvitesHttpService = new TeamInvitesHttpService();

const isInviteAthleteModalOpen = ref(false);
const teamId = ref(router.currentRoute.value.params.teamId as string);
const email = ref('');
const pendingInvites = ref({});

onMounted(async () => {
  getPendingInvites();
});

const getPendingInvites = async () => {
  const response = await teamInvitesHttpService.listMy(teamId.value);

  pendingInvites.value = response.ok ? await response.json() : {};
};

const invite = () => {
  teamInvitesHttpService.create({
    email: email.value,
    teamId: teamId.value,
  });
};
</script>
