<script lang="ts" setup>
import { ref } from 'vue';
import BackButton from 'src/components/common/BackButton.vue';
import VideoDrawer from 'src/components/Video/VideoDrawer.vue';
import { IVideo } from 'src/core/Interfaces/IVideo';
import { getShortDisplayDate } from 'src/services/utilities/DateUtils';
import { getPoster } from 'src/core/Utils/VideoUtils';
import { APP_ROUTE_NAMES } from 'src/core/AppRoutes';

withDefaults(defineProps<{ video: IVideo }>(), {});

const isVideoDrawerVisible = ref(false);

const toggleVideoDrawer = () => {
  isVideoDrawerVisible.value = !isVideoDrawerVisible.value;
};
</script>

<template>
  <div
    class="absolute left-0 right-0 top-0 z-10 flex justify-between px-4 py-3"
  >
    <BackButton small light :route-name="APP_ROUTE_NAMES.VIDEO_LIST" />
    <button
      v-if="video.id"
      class="flex w-28 min-w-max items-center rounded-lg bg-white/80 px-2 align-middle text-xs transition-opacity duration-200 ease-in hover:opacity-85"
      @click="toggleVideoDrawer()"
    >
      <img
        :src="getPoster(video)"
        :alt="video.blobName"
        class="mr-2 h-5 w-5 rounded-sm object-cover"
      />
      {{ getShortDisplayDate(video.createdAt) }}
    </button>

    <img
      src="/athleticnext-logo-48-yelllow.svg"
      alt="AthleticNext logo"
      title="AthleticNext"
      class="h-8"
    />
  </div>

  <VideoDrawer v-if="isVideoDrawerVisible" @close="toggleVideoDrawer()" />
</template>
