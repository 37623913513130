<template>
  <SidebarLayout>
    <GridContainer>
      <div class="col-span-12 mb-20 flex flex-col">
        <PageHeader>
          My Albums

          <template #action>
            <div class="flex items-center">
              <ANButton dark @click="isModalVisible = true">
                <span class="px-2">Create Album</span>
              </ANButton>
            </div>
          </template>
        </PageHeader>

        <ResponsiveGrid v-if="albums.length" :min-column-width="10" :gap="4">
          <VideoAlbums
            :albums="albums"
            removable
            large
            @select="onAlbumSelected"
            @delete="albumKeyForRemoval = $event"
          />
        </ResponsiveGrid>

        <EmptyState v-else class="mt-8 h-96">
          <template #illustration>
            <Squares2X2Icon class="h-24 w-24 text-gray-300" />
          </template>

          <template #description> You haven't created any albums yet </template>
        </EmptyState>
      </div>
    </GridContainer>
  </SidebarLayout>

  <CreateVideoAlbumModal
    v-if="isModalVisible"
    @success="onAlbumCreated"
    @close="isModalVisible = false"
  >
    To create new album, please enter the album name. Video albums allow you to
    easier manage your videos.
  </CreateVideoAlbumModal>

  <RemoveConfirmationModal
    v-if="albumKeyForRemoval"
    size="Small"
    @close="albumKeyForRemoval = ''"
    @confirm="deleteAlbum(albumKeyForRemoval)"
  >
    <p class="mt-4 text-sm text-gray-500">
      Are you sure you want to delete this video album? This action is
      irreversible and will permanently remove the album from your collection.
      <br />
      <br />
      Deleting the album will not delete the videos in this album. The videos
      will remain in your account, but they will no longer be organized within
      the album.
    </p>
  </RemoveConfirmationModal>

  <ANModal v-if="isAlbumCreated" size="ExtraSmall">
    <template #title>
      <div class="flex items-center">
        <CheckBadgeIcon class="mr-2 h-8 w-8 text-green-300" />
        Album Created Successfully
      </div>
    </template>

    <p class="mt-4 text-sm text-gray-500">
      You can add videos to this album from
      <router-link :to="APP_ROUTES.VIDEOS" class="text-blue-500">
        Videos
      </router-link>
      page. Click on the <b>"More"</b> icon and select
      <b>"Add To Album"</b> option.
    </p>

    <template #action>
      <ANButton dark class="w-full sm:w-2/3" @click="isAlbumCreated = false">
        Confirm
      </ANButton>
    </template>
  </ANModal>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { IVideoAlbumEntry } from 'src/core/Interfaces/IVideoAlbum';
import { Squares2X2Icon, CheckBadgeIcon } from '@heroicons/vue/20/solid';
import { AlbumsService } from 'src/core/services/Albums/AlbumsService';
import { ANModal, ANButton } from 'src/components/Uikit/Components';
import { APP_ROUTES, APP_ROUTE_NAMES } from 'src/core/AppRoutes';
import GridContainer from 'src/components/common/GridContainer.vue';
import PageHeader from 'src/components/common/PageHeader.vue';
import ResponsiveGrid from 'src/components/common/ResponsiveGrid.vue';
import SidebarLayout from 'src/components/layout/SidebarLayout.vue';
import VideoAlbums from 'src/components/Video/VideoAlbums.vue';
import CreateVideoAlbumModal from 'src/components/Video/CreateVideoAlbumModal.vue';
import EmptyState from 'src/components/common/EmptyState.vue';
import AlbumsRepository from 'src/core/repositories/Albums/AlbumsRepository';
import RemoveConfirmationModal from 'src/components/Video/RemoveConfirmationModal.vue';
import { useRouter } from 'vue-router';

const albumsRepository = new AlbumsRepository();
const albumsService = new AlbumsService();
const router = useRouter();

const albums = ref<IVideoAlbumEntry[]>([]);
const isModalVisible = ref<boolean>(false);
const isAlbumCreated = ref<boolean>(false);
const albumKeyForRemoval = ref<string>('');

onMounted(async () => {
  await getAlbums();
});

const getAlbums = async () => {
  albums.value = await albumsService.getAlbums(4);
};

const onAlbumCreated = () => {
  isAlbumCreated.value = true;
  getAlbums();
};

const onAlbumSelected = (key: string) => {
  router.push({
    name: APP_ROUTE_NAMES.VIDEO_ALBUM,
    params: { key },
  });
};

const deleteAlbum = async (albumKey: string) => {
  await albumsRepository.deleteAlbum(albumKey);
  getAlbums();
};
</script>
