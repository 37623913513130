export enum HttpMethods {
  DELETE = 'Delete',
  POST = 'Post',
  PUT = 'Put',
  GET = 'Get',
}

export enum ContentTypeHeaders {
  JSON = 'application/json',
  JPEG = 'image/jpeg',
  MP4 = 'video/mp4',
  MP3 = 'audio/mp3',
}

export enum ColorName {
  YELLOW = 'yellow',
  ORANGE = 'orange',
  RED = 'red',
  PINK = 'pink',
  GREEN = 'green',
  BLUE = 'blue',
  DARKBLUE = 'darkBlue',
  DARKBLUE2 = 'darkBlue2',
  PURPLE = 'purple',
  GRAY = 'gray',
  DARKGRAY = 'darkGray',
  BLACK = 'black',
  WHITE = 'white',
}

export const COLORS = {
  [ColorName.YELLOW]: '#fef344',
  [ColorName.ORANGE]: '#fac710',
  [ColorName.RED]: '#f24825',
  [ColorName.PINK]: '#da0062',
  [ColorName.GREEN]: '#8fd14f',
  [ColorName.BLUE]: '#16cdd4',
  [ColorName.DARKBLUE]: '#2e9cef',
  [ColorName.DARKBLUE2]: '#414bb2',
  [ColorName.PURPLE]: '#9611ac',
  [ColorName.GRAY]: '#e6e6e6',
  [ColorName.DARKGRAY]: '#808080',
  [ColorName.BLACK]: '#1a1a1a',
  [ColorName.WHITE]: '#ffffff',
};

export enum MoveNetKeypoints {
  NOSE = 'nose',
  LEFT_EYE = 'left_eye',
  RIGHT_EYE = 'right_eye',
  LEFT_EAR = 'left_ear',
  RIGHT_EAR = 'right_ear',
  LEFT_SHOULDER = 'left_shoulder',
  RIGHT_SHOULDER = 'right_shoulder',
  LEFT_ELBOW = 'left_elbow',
  RIGHT_ELBOW = 'right_elbow',
  LEFT_WRIST = 'left_wrist',
  RIGHT_WRIST = 'right_wrist',
  LEFT_HIP = 'left_hip',
  RIGHT_HIP = 'right_hip',
  LEFT_KNEE = 'left_knee',
  RIGHT_KNEE = 'right_knee',
  LEFT_ANKLE = 'left_ankle',
  RIGHT_ANKLE = 'right_ankle',
}

export const moveNetKeyPointsLabels: Record<MoveNetKeypoints, string> = {
  [MoveNetKeypoints.LEFT_KNEE]: 'Left Knee',
  [MoveNetKeypoints.RIGHT_KNEE]: 'Right Knee',
  [MoveNetKeypoints.LEFT_ELBOW]: 'Left Elbow',
  [MoveNetKeypoints.RIGHT_ELBOW]: 'Right Elbow',
  [MoveNetKeypoints.LEFT_EYE]: 'Left Eye',
  [MoveNetKeypoints.RIGHT_EYE]: 'Right Eye',
  [MoveNetKeypoints.LEFT_SHOULDER]: 'Left Shoulder',
  [MoveNetKeypoints.RIGHT_SHOULDER]: 'Right Shoulder',
  [MoveNetKeypoints.LEFT_HIP]: 'Left Hip',
  [MoveNetKeypoints.RIGHT_HIP]: 'Right Hip',
  [MoveNetKeypoints.LEFT_ANKLE]: 'Left Ankle',
  [MoveNetKeypoints.RIGHT_ANKLE]: 'Right Ankle',
  [MoveNetKeypoints.NOSE]: 'Nose',
  [MoveNetKeypoints.LEFT_EAR]: 'Left Ear',
  [MoveNetKeypoints.RIGHT_EAR]: 'Right Ear',
  [MoveNetKeypoints.LEFT_WRIST]: 'Left Wrist',
  [MoveNetKeypoints.RIGHT_WRIST]: 'Right Wrist',
};

export const angleMoveNetKeyPoints: {
  [key: string]: MoveNetKeypoints[];
} = {
  [MoveNetKeypoints.LEFT_KNEE]: [
    MoveNetKeypoints.LEFT_HIP,
    MoveNetKeypoints.LEFT_ANKLE,
    MoveNetKeypoints.LEFT_KNEE,
  ],
  [MoveNetKeypoints.RIGHT_KNEE]: [
    MoveNetKeypoints.RIGHT_HIP,
    MoveNetKeypoints.RIGHT_ANKLE,
    MoveNetKeypoints.RIGHT_KNEE,
  ],
  [MoveNetKeypoints.LEFT_ELBOW]: [
    MoveNetKeypoints.LEFT_WRIST,
    MoveNetKeypoints.LEFT_SHOULDER,
    MoveNetKeypoints.LEFT_ELBOW,
  ],
  [MoveNetKeypoints.RIGHT_ELBOW]: [
    MoveNetKeypoints.RIGHT_WRIST,
    MoveNetKeypoints.RIGHT_SHOULDER,
    MoveNetKeypoints.RIGHT_ELBOW,
  ],
};

export const skeletonKeypoints: MoveNetKeypoints[][] = [
  [
    MoveNetKeypoints.RIGHT_ANKLE,
    MoveNetKeypoints.RIGHT_KNEE,
    MoveNetKeypoints.RIGHT_HIP,
    MoveNetKeypoints.RIGHT_SHOULDER,
    MoveNetKeypoints.RIGHT_ELBOW,
    MoveNetKeypoints.RIGHT_WRIST,
  ],
  [
    MoveNetKeypoints.LEFT_ANKLE,
    MoveNetKeypoints.LEFT_KNEE,
    MoveNetKeypoints.LEFT_HIP,
    MoveNetKeypoints.LEFT_SHOULDER,
    MoveNetKeypoints.LEFT_ELBOW,
    MoveNetKeypoints.LEFT_WRIST,
  ],
  [MoveNetKeypoints.RIGHT_HIP, MoveNetKeypoints.LEFT_HIP],
  [MoveNetKeypoints.RIGHT_SHOULDER, MoveNetKeypoints.LEFT_SHOULDER],
];

export const headKeypoints: string[] = [
  MoveNetKeypoints.NOSE,
  MoveNetKeypoints.LEFT_EYE,
  MoveNetKeypoints.RIGHT_EYE,
  MoveNetKeypoints.LEFT_EAR,
  MoveNetKeypoints.RIGHT_EAR,
];

export enum AuthenticationProviders {
  PASSWORD = 'password',
}

export enum VideoCommentsTabs {
  Text,
  Voice,
  Default,
}

export enum SwitchOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export const EMIT_EVENTS = {
  CHANGE: 'change',
  SELECT: 'select',
  DELETE: 'delete',
  CONFIRM: 'confirm',
  CLOSE: 'close',
};

export const UPPER_BODY = [
  MoveNetKeypoints.LEFT_WRIST,
  MoveNetKeypoints.LEFT_ELBOW,
  MoveNetKeypoints.LEFT_SHOULDER,
  MoveNetKeypoints.RIGHT_SHOULDER,
  MoveNetKeypoints.RIGHT_ELBOW,
  MoveNetKeypoints.RIGHT_WRIST,
];

export const LOWER_BODY = [
  MoveNetKeypoints.LEFT_ANKLE,
  MoveNetKeypoints.LEFT_KNEE,
  MoveNetKeypoints.LEFT_HIP,
  MoveNetKeypoints.RIGHT_HIP,
  MoveNetKeypoints.RIGHT_KNEE,
  MoveNetKeypoints.RIGHT_ANKLE,
];
