<template>
  <div class="flex items-center justify-between">
    <h3 class="an-dark--text text-xl font-medium capitalize leading-8">
      My Videos
    </h3>

    <a
      v-if="videos.length >= PREVIEW_VIDEO_COUNT"
      href="/app/videos/"
      class="flex items-center text-sm font-semibold text-gray-900 transition duration-200 ease-in-out hover:text-gray-600"
    >
      See All
    </a>
  </div>

  <div
    class="mt-6 flex items-center overflow-x-auto border-b border-secondary-light pb-12"
  >
    <div v-if="isVideosLoading" class="flex w-full items-center justify-center">
      <ANSpinner size="w-8 h-8" />
    </div>

    <template v-else-if="videos.length">
      <a
        v-for="video in videos"
        :key="video.id"
        :href="`/app/videos/${video.id}`"
        class="relative mr-2 flex flex-shrink-0 cursor-pointer flex-col overflow-hidden rounded-lg bg-black shadow-md"
      >
        <img
          :id="video.id"
          :src="getPoster(video)"
          :alt="getVideoName(video.blobName)"
          class="h-24 w-24 object-cover"
        />
      </a>
    </template>

    <div v-else class="w-full text-center">
      <EmptyState>
        <template #illustration>
          <BuildingLibraryIcon class="h-8 w-8 text-gray-300" />
        </template>
        <template #description> You haven't uploaded any videos yet </template>
      </EmptyState>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { BuildingLibraryIcon } from '@heroicons/vue/20/solid';
import { ANSpinner } from 'src/components/Uikit/Components';
import { EVENTS } from 'src/components/Video/VideoUpload.vue';
import EmptyState from 'src/components/common/EmptyState.vue';
import { useEmitter } from 'src/core/EventEmitter';
import { IVideo } from 'src/core/Interfaces/IVideo';
import { getPoster, getVideoName } from 'src/core/Utils/VideoUtils';
import {
  PaginatedResponse,
  VideoHttpService,
} from 'src/core/services/Video/VideoHttpService';
import { onMounted, ref } from 'vue';

const emitter = useEmitter();

const PREVIEW_VIDEO_COUNT = 9;

const videoHttpService: VideoHttpService = new VideoHttpService();

const isVideosLoading = ref<boolean>(false);
const videos = ref<IVideo[]>([]);

onMounted(async () => {
  getVideos();
  emitter.on(EVENTS.UPLOAD_FINISHED, () => getVideos());
});

const getVideos = async () => {
  isVideosLoading.value = true;
  const { items } = await videoHttpService.getVideos<PaginatedResponse<IVideo>>(
    0,
    PREVIEW_VIDEO_COUNT,
  );

  videos.value = items;

  isVideosLoading.value = false;
};
</script>
