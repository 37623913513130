import { signInWithCustomToken } from '@firebase/auth';
import '@fontsource/inter';
import '@fontsource/inter/100.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import App from 'src/App.vue';
import { useApplicationInsights } from 'src/core/3rdParty/ApplicationInsights';
import { auth, useAppUser } from 'src/core/3rdParty/Firebase';
import { useSentry } from 'src/core/3rdParty/Sentry';
import {
  APP_ROUTES,
  PUBLIC_ROUTES,
  PUBLIC_ROUTE_NAMES,
  ROUTES_CONFIG,
} from 'src/core/AppRoutes';
import { appUser } from 'src/core/UserStore';
import { store } from 'src/store';
import 'src/styles/main.css';
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { setCurrentScreenName } from './core/services/AnalyticsService';
import {
  COOKIE_AN_WEB_CLIENT,
  COOKIE_CSIT,
  getCookieValueByName,
} from './services/utilities/CookieUtilities';

useApplicationInsights();

store.deviceInfo.isMobileWebClient =
  getCookieValueByName(COOKIE_AN_WEB_CLIENT) === 'mobile';

const router = createRouter({
  history: createWebHistory(),
  routes: ROUTES_CONFIG,
});

router.beforeResolve(async (to) => {
  const { fullPath } = to;

  if (useAppUser()) {
    if (
      [
        PUBLIC_ROUTES[PUBLIC_ROUTE_NAMES.ROOT],
        PUBLIC_ROUTES[PUBLIC_ROUTE_NAMES.LOGIN],
        PUBLIC_ROUTES[PUBLIC_ROUTE_NAMES.REGISTER],
      ].includes(fullPath)
    ) {
      return APP_ROUTES.ROOT;
    }
  } else {
    if (fullPath.startsWith(APP_ROUTES.ROOT)) {
      const customSingInToken = getCookieValueByName(COOKIE_CSIT);

      if (customSingInToken) {
        signInWithCustomToken(auth, customSingInToken)
          .then(() => {
            return to;
          })
          .catch(() => {
            return PUBLIC_ROUTES[PUBLIC_ROUTE_NAMES.LOGIN];
          });
      } else {
        return PUBLIC_ROUTES[PUBLIC_ROUTE_NAMES.LOGIN];
      }
    }
  }
});

router.afterEach(({ name, path }) => {
  setCurrentScreenName(name?.toString() || path);
});

let isAppMounted = false;

const pinia = createPinia();

auth.onAuthStateChanged((user) => {
  appUser.value = user;

  /**
   * It's important that we mount the app after we first check user's auth state from Firebase.
   * This allows to use Vue Router navigation guards to check whether user is authenticated or not.
   * And prevents the app crashing when programmatically navigate after user login and logout
   */
  if (isAppMounted) {
    return;
  }

  const app = createApp(App);
  useSentry(app);
  app.use(router);
  app.use(pinia);
  app.mount('#app');

  isAppMounted = true;

  if (!user) {
    return;
  }

  Sentry.setUser({ id: user.uid });
});
