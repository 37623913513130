<script lang="ts" setup>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
import {
  DEFAULT_PAGE_SIZE,
  PaginationProps,
  emitEvents,
} from './Pagination.types';
import { computed, onMounted, ref } from 'vue';

const emits = defineEmits([emitEvents.change]);

const currentPage = ref(0);

const { initialPage, total, size, showPages } = withDefaults(
  defineProps<PaginationProps>(),
  {
    initialPage: 1,
    total: 0,
    size: DEFAULT_PAGE_SIZE,
    showPages: false,
  },
);
onMounted(() => {
  currentPage.value = initialPage;
});

const pagesCount = computed(() => {
  return Math.ceil(total / size);
});

const onPrevious = () => {
  if (currentPage.value - 1 > 0) {
    currentPage.value--;

    emits(emitEvents.change, currentPage.value);
  }
};

const onNext = () => {
  if (currentPage.value < pagesCount.value) {
    currentPage.value++;

    emits(emitEvents.change, currentPage.value);
  }
};

const onPageChange = (page: number) => {
  currentPage.value = page;
  emits(emitEvents.change, currentPage.value);
};

const currentVideoIndices = computed(() => {
  return [
    (currentPage.value - 1) * DEFAULT_PAGE_SIZE + 1,
    currentPage.value * DEFAULT_PAGE_SIZE > total
      ? total
      : currentPage.value * DEFAULT_PAGE_SIZE,
  ];
});

const pages = computed(() => {
  if (!showPages) {
    return [];
  }

  const firstPageNumber = currentPage.value - 2;

  const startPageIndex = Math.min(
    Math.max(1, firstPageNumber),
    pagesCount.value - 4 > 0 ? pagesCount.value - 4 : 1,
  );

  return Array.from(
    { length: pagesCount.value > 5 ? 5 : pagesCount.value },
    (_, i) => i + startPageIndex,
  );
});
</script>
<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center">
      <button
        type="button"
        title="Previous"
        class="rounded-md border border-stone-500 p-1 hover:border-stone-700 hover:bg-slate-200"
      >
        <ChevronLeftIcon class="h-5 w-5" @click="onPrevious" />
      </button>
      <button
        type="button"
        title="Next"
        class="ml-2 rounded-md border border-stone-500 p-1 hover:border-stone-700 hover:bg-slate-200"
      >
        <ChevronRightIcon class="h-5 w-5" @click="onNext" />
      </button>
      <button
        v-for="page in pages"
        :key="page"
        type="button"
        class="ml-2 flex w-5 min-w-[30px] justify-around rounded-md border border-stone-500 p-1 text-sm hover:border-stone-700 hover:bg-slate-200"
        :class="[page === currentPage && 'bg-slate-300']"
        @click="onPageChange(page)"
      >
        {{ page }}
      </button>
    </div>
    <p class="text-sm tabular-nums text-slate-600">
      {{ currentVideoIndices.join('-') }} of {{ total }} videos
    </p>
  </div>
</template>
