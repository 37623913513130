import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClientAppConfig } from '../ClientAppConfig';

export const useApplicationInsights = () => {
  if (!ClientAppConfig.applicationInsights) {
    return;
  }

  try {
    new ApplicationInsights({
      config: {
        instrumentationKey: ClientAppConfig.applicationInsights,
      },
    }).loadAppInsights();
  } catch {
    return;
  }
};
