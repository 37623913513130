<template>
  <div class="mb-0" :class="{ 'px-3': !fullWidth }">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

interface IProps {
  fullWidth?: boolean;
  bodyClass?: string[];
}

const props = withDefaults(defineProps<IProps>(), {
  fullWidth: false,
  bodyClass: () => [],
});

onMounted(() => {
  if (props.bodyClass?.length) {
    document.documentElement.className = props.bodyClass.join(' ');
  }
});
</script>

<style>
.an-u-min-h-100vh {
  min-height: calc(100vh - env(safe-area-inset-bottom));
}
</style>
