import { BaseBffHttpService } from '../BaseBffHttpService';

type AccountsCreateRequest = {
  email: string;
  password: string;
};

type AccountsCreateResponse = {
  token: string;
};

export class AccountsHttpService extends BaseBffHttpService {
  basePath = 'accounts';
  #path = 'accounts';

  create = async ({
    email,
    password,
  }: AccountsCreateRequest): Promise<AccountsCreateResponse> => {
    const response = await this.post(`${this.#path}`, {
      email,
      password,
    });

    const { token } = await response.json();

    return {
      token,
    };
  };

  deleteAccount = async (): Promise<void> => {
    await this.delete(`${this.#path}`);
  };
}
