<template>
  <div class="text-center">
    <button type="button" style="font-size: 0" @click="authorizeUser">
      <img
        src="/apple_login_btn.svg"
        alt="Sign in with Apple"
        class="h-10 max-w-[200px]"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { OAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { APP_ROUTES } from 'src/core/AppRoutes';
import { useRouter } from 'vue-router';

const router = useRouter();
const authorizeUser = async () => {
  const provider = new OAuthProvider('apple.com');
  const auth = getAuth();

  const signInResult = await signInWithPopup(auth, provider);

  if (!signInResult) {
    return;
  }

  router.push(APP_ROUTES.VIDEOS);
};
</script>
