import {
  useAuthorizationHeader,
  useCommentsUploadEndpoint,
} from 'src/api/api.endpoints';
import { useAppUser } from 'src/core/3rdParty/Firebase';
import { HttpMethods } from '../Constants';
import { HttpClientFetch } from '../HttpClient';

/**
 * @deprecated We should migrate to BFF  API endpoint
 */
// TODO migrate to BFF API endpoint
export const postComment = async (videoId: string, body: FormData) => {
  return await HttpClientFetch(useCommentsUploadEndpoint(videoId), {
    method: HttpMethods.POST,
    headers: {
      ...useAuthorizationHeader((await useAppUser()?.getIdToken()) ?? ''),
    },
    body,
  });
};
