<template>
  <div class="fixed right-2 top-1/2 z-50 -translate-y-1/2 transform">
    <div
      v-if="isColorSwitcherVisible"
      class="absolute right-full top-2 w-48 rounded bg-white p-2"
    >
      <button
        v-for="color in COLORS"
        :key="color"
        type="button"
        class="p-1"
        @click="setStrokeStyle(color)"
      >
        <div
          class="h-9 w-9 rounded-full"
          :style="{ 'background-color': color }"
        />
      </button>
    </div>
    <div
      v-if="isThicknessControlsVisible"
      class="absolute right-full top-2 w-48 max-w-[164px] rounded bg-white p-2"
    >
      <button
        v-for="number in 4"
        :key="number"
        type="button"
        class="flex min-w-full items-center rounded-md p-1 transition-colors duration-200 ease-linear hover:bg-gray-200"
        @click="updateLineThickness(number)"
      >
        <span class="mr-2 text-xxs font-medium text-gray-400">
          {{ number }}
        </span>

        <div
          class="w-full rounded-full bg-black"
          :style="{ height: `${number}px` }"
        />
      </button>
    </div>

    <div class="m-2 flex flex-col rounded-lg bg-white p-2">
      <ANSwitch
        class="mb-2"
        :selected="activeMode"
        :options="Object.values(DrawingMode)"
        :orientation="SwitchOrientation.VERTICAL"
        uppercase
        @change="onDrawingModeChanged"
      >
        <template #default="{ option }">
          {{ DRAWING_MODE_NAME[option as DrawingMode] }}
        </template>
      </ANSwitch>

      <button
        type="button"
        class="mb-2 flex justify-center rounded bg-gray-200 px-2 py-1.5"
        :style="{ color: options.strokeStyle }"
        @click="isColorSwitcherVisible = !isColorSwitcherVisible"
      >
        <EyeDropperIcon class="h-5 w-5" />
      </button>
      <button
        type="button"
        class="mb-2 flex h-8 items-center justify-center rounded bg-gray-200 px-2 py-1.5"
        @click="isThicknessControlsVisible = !isThicknessControlsVisible"
      >
        <div
          class="mx-2 w-full rounded-full bg-black"
          :style="{ height: `${options.thickness}px` }"
        />
      </button>
      <button
        type="button"
        class="rounded bg-gray-200 px-2 py-2 text-xs font-bold uppercase"
        :class="{ 'mb-2': activeMode === DrawingMode.LINE }"
        @click="clearCanvas"
      >
        Clear
      </button>
      <button
        v-if="activeMode === DrawingMode.LINE"
        type="button"
        class="pmb-2 rounded bg-gray-200 py-2 text-xs font-bold uppercase"
        @click="undoDrawing"
      >
        Undo
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { EyeDropperIcon } from '@heroicons/vue/20/solid';
import { COLORS } from 'src/core/Constants';
import { ANSwitch } from 'src/components/Uikit/Components';
import { ref } from 'vue';
import { useEmitter } from 'src/core/EventEmitter';
import { CANVAS_DRAWING_EVENTS } from 'src/core/Events';
import { DrawingMode, DRAWING_MODE_NAME } from 'src/services/DrawingService';
import { SwitchOrientation } from 'src/core/Constants';

const emitter = useEmitter();
const isColorSwitcherVisible = ref(false);
const isThicknessControlsVisible = ref(false);
const activeMode = ref(DrawingMode.LINE);
const options = ref({
  strokeStyle: COLORS.pink,
  thickness: 2,
});

const setStrokeStyle = (color: string) => {
  options.value.strokeStyle = color;
  isColorSwitcherVisible.value = false;

  onOptionsChanged();
};

const updateLineThickness = (thickness: number) => {
  options.value.thickness = thickness;
  isThicknessControlsVisible.value = false;

  onOptionsChanged();
};

const onDrawingModeChanged = (mode: DrawingMode) => {
  activeMode.value = mode;
  emitter.emit(CANVAS_DRAWING_EVENTS.MODE_CHANGE, mode);
  options.value = {
    strokeStyle: COLORS.pink,
    thickness: 2,
  };
};

const clearCanvas = () => {
  emitter.emit(CANVAS_DRAWING_EVENTS[activeMode.value].CLEAR);
};

const undoDrawing = () => {
  emitter.emit(CANVAS_DRAWING_EVENTS.LINE.UNDO);
};

const onOptionsChanged = () => {
  emitter.emit(
    CANVAS_DRAWING_EVENTS[activeMode.value].OPTIONS_CHANGED,
    options.value,
  );
};
</script>
