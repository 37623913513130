<template>
  <div
    v-for="{ videos, name, key } in albums"
    :key="key"
    class="relative flex flex-shrink-0 cursor-pointer flex-col"
    :class="cssClass"
  >
    <div
      class="rounded-lg p-0.5 transition-colors duration-100 ease-linear hover:bg-gray-200"
    >
      <button
        v-if="removable"
        type="button"
        class="absolute right-0 top-0 z-20 flex h-6 w-6 -translate-y-1/3 translate-x-1/3 transform items-center justify-center rounded-full bg-black bg-opacity-80 transition duration-300 ease-in-out hover:bg-opacity-100"
        @click.prevent="deleteAlbum($event, key)"
      >
        <TrashIcon class="h-3.5 w-3.5 text-white" />
      </button>
      <div class="relative z-10" @click="addVideo(key)">
        <div
          v-if="videos.length"
          class="grid w-full gap-0.5 rounded-md transition duration-200 ease-linear"
          :class="[
            videos.length === SINGLE_COUNT ? 'grid-cols-1' : 'grid-cols-2',
          ]"
        >
          <img
            v-for="videoItem in videos"
            :key="videoItem?.id"
            :src="getPoster(videoItem)"
            :alt="getName(videoItem.blobName)"
            class="w-full rounded-md object-cover"
            :class="videos.length === SINGLE_COUNT ? size.single : size.quarter"
          />

          <template
            v-if="
              videos.length > SINGLE_COUNT &&
              videos.length !== MAX_VIDEOS_PER_ALBUM_PREVIEW
            "
          >
            <span
              v-for="index in MAX_VIDEOS_PER_ALBUM_PREVIEW - videos.length"
              :key="index"
              class="flex w-full items-center justify-center rounded-lg bg-gray-300"
              :class="size.quarter"
            >
              <VideoCameraSlashIcon class="h-4 w-4 text-gray-600" />
            </span>
          </template>
        </div>

        <div
          v-else
          class="flex items-center justify-center rounded-lg bg-gray-300"
          :class="[size.single]"
        >
          <VideoCameraSlashIcon class="h-4 w-4 text-gray-600" />
        </div>
      </div>
    </div>
    <p
      class="mt-2 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-black"
    >
      {{ name }}
    </p>
    <p v-if="showCount" class="text-xs text-secondary">
      {{ videos.length }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { IVideoAlbumEntry } from 'src/core/Interfaces/IVideoAlbum';
import { TrashIcon, VideoCameraSlashIcon } from '@heroicons/vue/20/solid';
import { getVideoName, getPoster } from 'src/core/Utils/VideoUtils';
import { computed } from 'vue';
import { EMIT_EVENTS } from 'src/core/Constants';

const props = withDefaults(
  defineProps<{
    albums: IVideoAlbumEntry[];
    showCount?: boolean;
    large?: boolean;
    removable?: boolean;
    cssClass?: string;
  }>(),
  {
    albums: () => [],
    showCount: false,
    large: false,
    removable: false,
    cssClass: '',
  },
);

const emit = defineEmits([EMIT_EVENTS.SELECT, EMIT_EVENTS.DELETE]);
const MAX_VIDEOS_PER_ALBUM_PREVIEW = 4;
const SINGLE_COUNT = 1;

const size = computed(() => {
  if (props.large) {
    return {
      single: 'h-40',
      quarter: 'h-20',
      width: 'w-full',
    };
  }

  return {
    single: 'h-24',
    quarter: 'h-12',
    width: 'w-24',
  };
});

const getName = (blobName: string) => {
  return getVideoName(blobName);
};

const addVideo = (key: string) => {
  emit('select', key);
};

const deleteAlbum = (event: MouseEvent, key: string) => {
  event.preventDefault();
  emit(EMIT_EVENTS.DELETE, key);
};
</script>
