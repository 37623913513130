<script setup lang="ts">
import { moveNetKeyPointsLabels } from 'src/core/Constants';
import { FrameTagDto } from 'src/core/services/FrameTags';
import { useVideoTagsScreenStore } from './videoTagsScreen.store';

const props = defineProps<{ tags: FrameTagDto[] }>();

defineEmits(['selectTag']);

const store = useVideoTagsScreenStore();
</script>
<template>
  <div class="flex max-h-full text-white">
    <div class="w-64">
      <div
        class="mx-2 mb-6 mt-16 flex flex-1 flex-col rounded-md bg-gray-800/80 p-2"
      >
        <div class="flex items-center justify-between text-sm">
          Shows AI Keypoints
          <input
            v-model="store.isAiKeypointsVisible"
            type="checkbox"
            class="toggle toggle-info toggle-sm ml-2"
          />
        </div>
        <div class="mt-2 flex items-center justify-between text-sm text-white">
          Join Body Points
          <input
            v-model="store.isTagJoinsVisible"
            type="checkbox"
            class="toggle toggle-info toggle-sm ml-2"
          />
        </div>

        <div class="pt-4">
          <h3 class="font-bold">Body Points</h3>

          <p v-if="!tags.length" class="py-4 text-center text-xs">
            No tags added. <br />
            Click on the image to add a Tag
          </p>

          <button
            v-for="tag in props.tags"
            :key="tag.id"
            type="button"
            class="block pt-1 text-sm hover:text-yellow-400"
            @click="$emit('selectTag', { e: $event, frameTag: tag })"
          >
            {{
              moveNetKeyPointsLabels[
                tag.name as keyof typeof moveNetKeyPointsLabels
              ]
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
