<template>
  <div class="z-40 flex flex-col md:px-4">
    <router-link
      v-for="{ route, label, icon } in routes"
      :key="route"
      :to="route"
      active-class="nav-active font-medium text-sky-500 md:text-sky-500"
      class="flex items-center rounded-lg p-2 text-base font-light text-gray-900 md:text-white md:hover:bg-gray-700"
    >
      <Component :is="icon" class="mr-2 h-5 w-5" />
      <p>{{ label }}</p>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { APP_ROUTES } from 'src/core/AppRoutes';
import {
  VideoCameraIcon,
  UserGroupIcon,
  HomeIcon,
  Squares2X2Icon,
} from '@heroicons/vue/24/outline';
import { computed } from 'vue';

const routes = computed(() => {
  return [
    {
      route: APP_ROUTES.DASHBOARD,
      icon: HomeIcon,
      label: 'Dashboard',
    },
    {
      route: APP_ROUTES.VIDEOS,
      icon: VideoCameraIcon,
      label: 'Videos',
    },
    {
      route: APP_ROUTES.ALBUMS,
      icon: Squares2X2Icon,
      label: 'Albums',
    },
    {
      route: APP_ROUTES.TEAM,
      icon: UserGroupIcon,
      label: 'Team',
    },
    // {
    //   route: APP_ROUTES.TEAMS,
    //   icon: UserGroupIcon,
    //   label: 'Teams V2',
    // },
  ];
});
</script>

<style scoped>
.nav-active {
  @apply opacity-100;
}
</style>
