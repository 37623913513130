<template>
  <div class="mt-1 flex items-center justify-between pb-10 pt-8">
    <h1 class="an-dark--text text-2xl font-medium capitalize leading-8">
      <slot />
    </h1>

    <slot name="action" />
  </div>
</template>

<script setup lang="ts"></script>
