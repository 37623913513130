<template>
  <ANContentSection title="Profile">
    <div class="mt-6 flex items-center">
      <div
        v-if="isUploadingAvatar"
        class="flex h-16 w-16 items-center justify-center rounded-full border border-gray-200 md:h-24 md:w-24"
      >
        <ANSpinner />
      </div>
      <UserAvatar v-else size="w-16 h-16 md:w-24 md:h-24" />
      <ANButton
        small
        class="ml-4"
        @click="($refs.avatarUpload as HTMLInputElement).click()"
      >
        Upload Photo
      </ANButton>
      <input
        id="avatar-upload"
        ref="avatarUpload"
        type="file"
        accept="image/*"
        class="absolute hidden"
        @change="uploadAvatar"
      />
    </div>

    <ANInput
      v-model="form.displayName"
      class="mt-6"
      label="Display Name"
      placeholder="Enter Your Display Name"
    />

    <ANInput v-model="form.email" class="mt-6" label="Email" disabled>
      <template #labelAfter>
        <span
          class="self-start rounded px-2 py-1 text-xs"
          :class="user?.emailVerified ? 'text-green-600' : 'text-red-600'"
        >
          {{ emailVerificationStatus }}
        </span>
      </template>
    </ANInput>

    <template v-if="!user?.emailVerified">
      <section
        v-if="isVerificationLinkSent"
        class="mt-4 text-xs font-semibold text-gray-900"
      >
        We sent the verification link to your email address. Please check your
        email and follow the verification link.
      </section>
      <section
        v-else
        class="mt-2 flex flex-col items-center justify-between rounded-lg border border-yellow-300 bg-yellow-50 p-2 sm:flex-row"
      >
        <p class="text-xs font-semibold text-gray-900">
          Your email address has not yet been verified. You can verify email by
          pressing the button and we will send you a verification link to your
          email
        </p>
        <ANButton
          small
          class="mt-2 w-full sm:ml-4 sm:mt-0 sm:w-fit-content"
          @click="verifyEmail"
        >
          Verify Email
        </ANButton>
      </section>
    </template>

    <section
      v-if="error"
      class="mt-6 flex items-center rounded-lg border border-red-300 bg-red-50 p-2"
    >
      <ExclamationTriangleIcon class="h-5 w-5 text-red-500" />
      <span class="ml-2 text-xs text-gray-900"> {{ error }}</span>
    </section>

    <ANButton
      class="mt-6"
      full
      :loading="isFormLoading"
      :disabled="!canSubmit"
      @click="save"
    >
      Save Profile Info
    </ANButton>
  </ANContentSection>
</template>

<script lang="ts" setup>
import { sendEmailVerification } from '@firebase/auth';
import { uploadBytes } from '@firebase/storage';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import {
  API,
  useAuthorizationHeader,
  useContentTypeHeader,
  useEndpoint,
} from 'src/api/api.endpoints';
import UserAvatar from 'src/components/common/UserAvatar.vue';
import {
  ANButton,
  ANContentSection,
  ANInput,
  ANSpinner,
} from 'src/components/Uikit/Components';
import { useAppUser } from 'src/core/3rdParty/Firebase';
import { ContentTypeHeaders, HttpMethods } from 'src/core/Constants';
import { HttpClientFetch } from 'src/core/HttpClient';
import { userAvatarRef } from 'src/core/services/storage-service';
import { computed, onMounted, ref } from 'vue';

const user = useAppUser();

const isUploadingAvatar = ref(false);
const isVerificationLinkSent = ref(false);
const error = ref('');
const isFormLoading = ref(false);

const form = ref({
  displayName: '',
  email: '',
});

onMounted(() => {
  resetForm();
});

const canSubmit = computed(() => !!form.value.displayName);

const emailVerificationStatus = computed(() => {
  return user?.emailVerified ? 'Verified' : 'Not verified';
});

const uploadAvatar = async (event: Event) => {
  const { files } = event.target as HTMLInputElement;

  if (!files?.length) {
    return;
  }

  const metadata = {
    contentType: ContentTypeHeaders.JPEG,
  };

  isUploadingAvatar.value = true;
  await uploadBytes(userAvatarRef(user?.uid ?? ''), files[0], metadata)
    .catch(() => null)
    .finally(() => (isUploadingAvatar.value = false));
};

const save = () => {
  if (!canSubmit.value) {
    error.value = 'Please enter a valid display name';

    return;
  }

  isFormLoading.value = true;
  updateUser();
};

const updateUser = async () => {
  const response = await HttpClientFetch(useEndpoint(API.USER), {
    method: HttpMethods.PUT,
    headers: {
      ...useAuthorizationHeader((await user?.getIdToken()) ?? ''),
      ...useContentTypeHeader(ContentTypeHeaders.JSON),
    },
    body: JSON.stringify(form.value),
  });

  if (!response.ok) {
    error.value = 'Something went wrong, please try again!';

    return;
  }
  await user?.reload();

  clearError();
  resetForm();
  isFormLoading.value = false;
};

const clearError = () => (error.value = '');

const resetForm = () => {
  form.value.displayName = user?.displayName ?? '';
  form.value.email = user?.email ?? '';
};

const verifyEmail = async () => {
  if (!user) {
    return;
  }

  await sendEmailVerification(user);
  isVerificationLinkSent.value = true;
};
</script>
