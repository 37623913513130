import { ContentTypeHeaders } from 'src/core/Constants';

export enum API {
  USER = 'user',
  VIDEOS = 'videos',
  MY_ATHLETES = 'team/athletes',
  DRAWINGS = 'drawings',
  INVITE_TOKEN = 'team/invite-token',
  SUBSCRIPTION = 'subscription',
  SHARE_VIDEOS = 'share/video',
}

const { VITE_API_PATH } = import.meta.env;

export const useEndpoint = (endpoint: API) =>
  `${VITE_API_PATH}/api/${endpoint}`;

export const useAuthorizationHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export const useContentTypeHeader = (headerValue: ContentTypeHeaders) => ({
  'Content-Type': `${headerValue}`,
});

/**
 * @deprecated Use BFF API when it will be implemented
 */
export const useCommentsEndpoint = (vid: string) => {
  return `${VITE_API_PATH}/api/${vid}/comment`;
};

export const useCommentsUploadEndpoint = (vid: string) => {
  return `${useCommentsEndpoint(vid)}/media`;
};
