<template>
  <div>
    <InfoField class="mb-10 w-full sm:w-1/2 lg:w-1/3">
      Videos uploaded

      <template #description>
        {{ pagination?.total ?? 0 }}
      </template>
    </InfoField>

    <div v-if="isVideosLoading" class="flex w-full items-center justify-center">
      <ANSpinner size="w-8 h-8" />
    </div>

    <template v-else-if="videos.length">
      <div
        class="grid grid-cols-3 gap-6 xs:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
      >
        <div v-for="video in videos" :key="video.id" class="relative">
          <div
            class="absolute right-0 top-0 z-20 -translate-y-1/2 translate-x-1/2 transform"
          >
            <ContextCard
              position="right"
              margin="-mt-1 ml-4 z-50 py-1"
              @open="isContextMenuOpen = true"
              @close="isContextMenuOpen = false"
            >
              <button
                type="button"
                class="flex h-6 w-6 items-center justify-center rounded-full bg-black bg-opacity-80 transition duration-200 ease-in-out hover:bg-opacity-100"
              >
                <EllipsisHorizontalIcon class="h-5 w-5 text-white" />
              </button>

              <template #content="{ close }">
                <div class="px-2 py-1">
                  <button
                    class="flex w-full items-center rounded-lg p-2 text-sm font-normal leading-6 transition duration-200 hover:bg-secondary-light"
                    @click="onSelectVideo(video, close)"
                  >
                    <FolderPlusIcon class="mr-3 h-4 w-4" />
                    <span>Add to Album</span>
                  </button>

                  <button
                    v-if="removable"
                    class="flex w-full items-center rounded-lg p-2 text-sm font-normal leading-6 transition duration-200 hover:bg-secondary-light"
                    @click.prevent="onRemoveVideo(video.id, close)"
                  >
                    <TrashIcon class="mr-3 h-4 w-4" />
                    <span>Remove Video</span>
                  </button>
                </div>
              </template>
            </ContextCard>
          </div>

          <a
            :href="`/app/videos/${video.id}`"
            class="flex cursor-pointer flex-col"
          >
            <div
              class="relative flex aspect-square flex-grow items-center justify-center overflow-hidden rounded-lg bg-black text-sm text-white shadow-md"
            >
              <img
                :id="video.id"
                :src="getPoster(video)"
                :alt="getVideoName(video.blobName)"
                class="absolute left-0 top-0 z-0 h-full max-h-fit-content w-full object-cover"
              />
            </div>
            <p class="py-1 text-xs text-stone-400">
              {{ getShortDisplayDate(video.createdAt) }}
            </p>
          </a>

          <div v-if="athlete?.firstName" class="mt-3 flex items-center">
            <UserAvatar :user-id="athleteId" size="h-7 w-7" />

            <div class="ml-2">
              <p class="text-xs font-medium capitalize leading-4 text-gray-900">
                {{ `${athlete.firstName} ${athlete?.lastName ?? ''}` }}
              </p>
              <p
                class="mt-1 items-center text-xxs font-medium uppercase leading-4 text-secondary"
              >
                Uploaded {{ getDisplayDateTime(video.createdAt) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        v-if="(pagination?.total ?? 0) > DEFAULT_PAGE_SIZE"
        class="mt-4"
        :initial-page="currentPage"
        :size="DEFAULT_PAGE_SIZE"
        :total="pagination?.total"
        show-pages
        @change="onPageChange"
      />
    </template>
    <EmptyState v-else class="mt-8 h-96">
      <template #illustration>
        <BuildingLibraryIcon class="h-24 w-24 text-gray-300" />
      </template>

      <template #description>
        Unfortunately, there are no videos uploaded
      </template>

      No Videos
    </EmptyState>
  </div>

  <RemoveConfirmationModal
    v-if="removeId"
    @close="removeId = ''"
    @confirm="removeVideo(removeId as string)"
  >
    <p class="mt-4 text-sm text-gray-500">
      Are you sure you want to remove this video? This change is irreversible
    </p>
  </RemoveConfirmationModal>

  <VideoAlbumsDrawer
    v-if="selectedVideo"
    :video="selectedVideo"
    @close="selectedVideo = null"
  />
</template>

<script setup lang="ts">
import { BuildingLibraryIcon } from '@heroicons/vue/20/solid';
import {
  EllipsisHorizontalIcon,
  FolderPlusIcon,
  TrashIcon,
} from '@heroicons/vue/24/outline';
import RemoveConfirmationModal from 'src/components/Video/RemoveConfirmationModal.vue';
import VideoAlbumsDrawer from 'src/components/Video/VideoAlbumsDrawer.vue';
import { EVENTS } from 'src/components/Video/VideoUpload.vue';
import ContextCard from 'src/components/common/ContextCard.vue';
import EmptyState from 'src/components/common/EmptyState.vue';
import InfoField from 'src/components/common/InfoField.vue';
import UserAvatar from 'src/components/common/UserAvatar.vue';
import { useEmitter } from 'src/core/EventEmitter';
import { VIDEO_RECORDER_EVENTS } from 'src/core/Events';
import { IMember } from 'src/core/Interfaces/IAthlete';
import { IVideo } from 'src/core/Interfaces/IVideo';
import { getPoster } from 'src/core/Utils/VideoUtils';
import {
  PaginatedResponse,
  PaginationData,
  VideoHttpService,
} from 'src/core/services/Video/VideoHttpService';
import { onMounted, ref } from 'vue';

import { DEFAULT_PAGE_SIZE } from 'src/components/common/pagination/Pagination.types';
import Pagination from 'src/components/common/pagination/Pagination.vue';
import { useRoute, useRouter } from 'vue-router';
import { ANSpinner } from '../Uikit/Components';
import {
  getDisplayDateTime,
  getShortDisplayDate,
} from 'src/services/utilities/DateUtils';

const videoHttpService: VideoHttpService = new VideoHttpService();
withDefaults(
  defineProps<{ removable: boolean; athleteId?: string; athlete?: IMember }>(),
  {
    removable: false,
    athleteId: '',
    athlete: undefined,
  },
);

const isVideosLoading = ref<boolean>(false);
const videos = ref<IVideo[]>([]);
const pagination = ref<PaginationData>();
const currentPage = ref(0);
const removeId = ref<string>('');
const isContextMenuOpen = ref<boolean>(false);
const selectedVideo = ref<IVideo | null>(null);

const emitter = useEmitter();
const router = useRouter();
const route = useRoute();

onMounted(async () => {
  emitter.on(EVENTS.UPLOAD_FINISHED, () => getVideos());
  emitter.on(VIDEO_RECORDER_EVENTS.SAVE, () => getVideos(1));
  const page: number = Number(route.query.page) || 0;
  onPageChange(page < 1 ? 1 : page);
});

const onPageChange = async (page: number) => {
  currentPage.value = page;
  getVideos((page - 1) * DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE);
  router.push({ query: { page } });
};

const getVideos = async (skip = 0, take = DEFAULT_PAGE_SIZE) => {
  isVideosLoading.value = true;
  const data = await videoHttpService.getVideos<PaginatedResponse<IVideo>>(
    skip,
    take,
  );
  videos.value = data.items;
  pagination.value = data.pagination;

  isVideosLoading.value = false;
};

const getVideoName = (blobName: string) => {
  return blobName.split('/').pop();
};

const removeVideo = async (videoId: string): Promise<void> => {
  const response = await videoHttpService.deleteVideo(videoId);

  if (!response.ok) {
    return;
  }

  getVideos();
};

// eslint-disable-next-line @typescript-eslint/ban-types
const onSelectVideo = (video: IVideo, close: Function) => {
  selectedVideo.value = video;
  close();
};

// eslint-disable-next-line @typescript-eslint/ban-types
const onRemoveVideo = (id: string, close: Function) => {
  removeId.value = id;
  close();
};
</script>
