<template>
  <div
    class="absolute left-0 top-0 h-full w-full"
    :class="{ 'z-10': activeMode === DrawingMode.ANGLE }"
  >
    <div class="relative flex h-full w-full items-center justify-center">
      <canvas
        ref="angleCanvasElement"
        class="canvas absolute"
        :class="{ 'cursor-pointer': isDrawing }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { COLORS } from 'src/core/Constants';
import { useEmitter } from 'src/core/EventEmitter';
import { CANVAS_DRAWING_EVENTS } from 'src/core/Events';
import { DrawingMode } from 'src/services/DrawingService';
import { getElementDimensionInfo } from 'src/services/utilities/HtmlElementUtilities';
import { onMounted, onUnmounted, ref, computed } from 'vue';

const isTouchCapable = 'ontouchstart' in document.documentElement;
const MIN_LINE_LENGTH = 30;
const MAX_POINT_COUNT = 3;

const props = defineProps<{
  videoEl?: HTMLVideoElement;
}>();

type Coordinate = { x: number; y: number };

let ctx: CanvasRenderingContext2D;
let canvasDimensions = { height: 0, width: 0 };
let canvasOffsets = { x: 0, y: 0 };
const coordinates = ref<Coordinate[]>([]);
const lastPointCoordinateOnMove = ref<{ x: number; y: number }>({ x: 0, y: 0 });
const isDrawing = ref(false);

const activeMode = ref(DrawingMode.LINE);
const angleCanvasElement = ref<HTMLCanvasElement>();
const emitter = useEmitter();
const isDrawingControlsVisible = ref(false);
const isThicknessControlsVisible = ref(false);
const lineThickness = ref(2);
const strokeStyle = ref(COLORS.pink);

onMounted(() => {
  setup();
});

onUnmounted(() => {
  unsubscribe();

  const { ANGLE, MODE_CHANGE } = CANVAS_DRAWING_EVENTS;

  [ANGLE.CLEAR, ANGLE.OPTIONS_CHANGED, MODE_CHANGE].forEach((type) =>
    emitter.off(type),
  );
});

const updatedCoordinates = computed(() => {
  return [...coordinates.value, lastPointCoordinateOnMove.value];
});

const setup = () => {
  if (!angleCanvasElement.value) {
    return;
  }

  registerEmitterEvents();
  setCanvasDimensions();

  const canvasContext = angleCanvasElement.value.getContext('2d');

  if (!canvasContext) {
    return;
  }

  ctx = canvasContext;
};

const subscribe = () => {
  if (!angleCanvasElement?.value) {
    return;
  }

  if (isTouchCapable) {
    angleCanvasElement.value.addEventListener('touchstart', touchstart);
    angleCanvasElement.value.addEventListener('touchmove', touchmove);
    angleCanvasElement.value.addEventListener('touchend', touchend);
  } else {
    angleCanvasElement.value.addEventListener('pointerdown', mousedown);
    angleCanvasElement.value.addEventListener('pointermove', mousemove);
    angleCanvasElement.value.addEventListener('pointerup', mouseup);
  }
};

const unsubscribe = () => {
  if (!angleCanvasElement.value) {
    return;
  }

  if (isTouchCapable) {
    angleCanvasElement.value.removeEventListener('touchstart', touchstart);
    angleCanvasElement.value.removeEventListener('touchmove', touchmove);
    angleCanvasElement.value.removeEventListener('touchend', touchend);
  } else {
    angleCanvasElement.value.removeEventListener('pointerdown', mousedown);
    angleCanvasElement.value.removeEventListener('pointermove', mousemove);
    angleCanvasElement.value.removeEventListener('pointerup', mouseup);
  }
};

const registerEmitterEvents = () => {
  const { ANGLE, MODE_CHANGE } = CANVAS_DRAWING_EVENTS;

  emitter.on(ANGLE.CLEAR, clearCanvas);
  emitter.on(ANGLE.OPTIONS_CHANGED, (options) => {
    updateDrawing(options as { strokeStyle: string; thickness: number });
  });
  emitter.on(MODE_CHANGE, (mode: unknown) => {
    activeMode.value = mode as DrawingMode;
    updateListeners(mode as DrawingMode);
  });
};

const updateListeners = (mode: DrawingMode) => {
  mode === DrawingMode.ANGLE ? subscribe() : unsubscribe();
};

const setCanvasDimensions = () => {
  const { height, width, x, y } = getElementDimensionInfo(props.videoEl);

  canvasDimensions = { height, width };
  canvasOffsets = { x, y };

  if (angleCanvasElement.value) {
    angleCanvasElement.value.height = height;
    angleCanvasElement.value.width = width;
  }
};

const touchstart = (e: TouchEvent) => {
  e.preventDefault();

  if (coordinates.value.length === MAX_POINT_COUNT) {
    return;
  }

  displayHaloOnTouch(e);

  if (coordinates.value.length) {
    return;
  }

  drawLineEndpoint(e);
};

const touchmove = (e: TouchEvent) => {
  e.preventDefault();

  if (
    !coordinates.value.length ||
    coordinates.value.length === MAX_POINT_COUNT
  ) {
    return;
  }

  const { pageX = 0, pageY = 0 } = e.touches[0];
  const { x: offsetX, y: offsetY } = getOffsetAdjustedPath({
    x: pageX,
    y: pageY,
  });

  clearDrawing();
  drawAllLines();
  drawPoint({ offsetX, offsetY } as MouseEvent);
  drawCurrentLine({ offsetX, offsetY } as MouseEvent);

  lastPointCoordinateOnMove.value = { x: offsetX, y: offsetY };
  showAngle();
};

const touchend = (e: TouchEvent) => {
  e.preventDefault();

  if (coordinates.value.length === MAX_POINT_COUNT) {
    return;
  }

  clearDrawing();

  if (isLineTooShort(e)) {
    onInvalidLine();

    return;
  }

  drawLineEndpoint(e);
  drawAllLines();

  if (coordinates.value.length === MAX_POINT_COUNT) {
    showAngle();
  }
};

const mousedown = (e: MouseEvent) => {
  if (coordinates.value.length === MAX_POINT_COUNT) {
    return;
  }

  isDrawing.value = true;
  saveCoordinates(e);
};

const mousemove = (e: MouseEvent) => {
  e.preventDefault();

  if (
    !coordinates.value.length ||
    coordinates.value.length === MAX_POINT_COUNT
  ) {
    return;
  }

  const { offsetX: x, offsetY: y } = e;

  clearDrawing();
  drawAllLines();
  drawCurrentLine(e);

  if (updatedCoordinates.value.length < MAX_POINT_COUNT) {
    return;
  }

  lastPointCoordinateOnMove.value = { x, y };
  showAngle();
};

const mouseup = (e: MouseEvent) => {
  e.preventDefault();

  if (coordinates.value.length === MAX_POINT_COUNT) {
    isDrawing.value = false;
  }

  if (coordinates.value.length > MAX_POINT_COUNT) {
    return;
  }

  drawAllLines();
};

const drawLineEndpoint = (e: TouchEvent) => {
  const { pageX = 0, pageY = 0 } = e as unknown as MouseEvent;
  const { x: offsetX, y: offsetY } = getOffsetAdjustedPath({
    x: pageX,
    y: pageY,
  });

  saveCoordinates({ offsetX, offsetY } as MouseEvent);
  drawPoint({ offsetX, offsetY } as MouseEvent);
};

const drawCurrentLine = (e: MouseEvent) => {
  ctx.beginPath();
  ctx.strokeStyle = strokeStyle.value;
  ctx.lineWidth = lineThickness.value;

  const { x, y } = coordinates.value[coordinates.value.length - 1];
  ctx.moveTo(x, y);
  ctx.lineTo(e.offsetX, e.offsetY);
  ctx.stroke();
};

const onInvalidLine = () => {
  if (coordinates.value.length === 1) {
    clearCanvas();
  }

  drawAllLines();
};

const isLineTooShort = (e: TouchEvent) => {
  const { pageX = 0, pageY = 0 } = e as unknown as MouseEvent;
  const { x: offsetX, y: offsetY } = getOffsetAdjustedPath({
    x: pageX,
    y: pageY,
  });
  const { x, y } = coordinates.value[coordinates.value.length - 1];
  const difference = Math.hypot(offsetX - x, offsetY - y);

  return difference < MIN_LINE_LENGTH;
};

const displayHaloOnTouch = (e: TouchEvent) => {
  const { pageX = 0, pageY = 0 } = e.touches[0];
  const { x: offsetX, y: offsetY } = getOffsetAdjustedPath({
    x: pageX,
    y: pageY,
  });
  const radius = MIN_LINE_LENGTH;

  drawPoint({ offsetX, offsetY } as MouseEvent);

  ctx.beginPath();
  ctx.moveTo(offsetX + radius, offsetY);
  ctx.arc(offsetX, offsetY, radius, 0, 2 * Math.PI);
  ctx.closePath();
  ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
  ctx.lineWidth = lineThickness.value;
  ctx.fill();
};

const showAngle = () => {
  const coordinatesWithEndpoint =
    coordinates.value.length === MAX_POINT_COUNT
      ? coordinates.value
      : updatedCoordinates.value;

  if (coordinatesWithEndpoint.length !== MAX_POINT_COUNT) {
    return;
  }

  const angle = calculateAngle(coordinatesWithEndpoint);

  if (angle) {
    const { a1, a2, valueX, valueY } = angle;
    drawAngle(a1, a2, valueX, valueY);
  }
};

const saveCoordinates = ({ offsetX: x, offsetY: y }: MouseEvent) => {
  coordinates.value = [...coordinates.value, { x, y }];
};

const drawPoint = ({ offsetX: x, offsetY: y }: MouseEvent) => {
  ctx.beginPath();
  ctx.fillStyle = strokeStyle.value;
  ctx.arc(x, y, 4, 0, 4 * Math.PI);
  ctx.fill();
};

const drawAllLines = () => {
  coordinates.value.forEach(({ x, y }, index) => {
    drawPoint({ offsetX: x, offsetY: y } as MouseEvent);
    ctx.beginPath();
    ctx.strokeStyle = strokeStyle.value;
    ctx.lineWidth = lineThickness.value;
    ctx.moveTo(x, y);

    const nextPosition = coordinates.value[index + 1];

    if (!nextPosition) {
      return;
    }

    ctx.lineTo(nextPosition.x, nextPosition.y);
    ctx.stroke();
  });
};

const calculateAngle = (
  coordinates: Coordinate[],
):
  | {
      a1: number;
      a2: number;
      valueX: number;
      valueY: number;
    }
  | undefined => {
  const [top, middle, bottom] = coordinates;

  if (!top || !bottom || !middle) {
    return undefined;
  }

  const a1 = Math.atan2(top.y - middle.y, top.x - middle.x);
  const a2 = Math.atan2(bottom.y - middle.y, bottom.x - middle.x);

  return { a1, a2, valueX: middle.x, valueY: middle.y };
};

const drawAngle = (angle1: number, angle2: number, x: number, y: number) => {
  if (!ctx) {
    return;
  }

  const calculatedAngle =
    parseInt(String(((angle1 - angle2) * 180) / Math.PI + 360)) % 360;

  const { angleOne, angleTwo } =
    calculatedAngle > 180
      ? { angleOne: angle2, angleTwo: angle1 }
      : { angleOne: angle1, angleTwo: angle2 };

  ctx.moveTo(x, y);
  ctx.arc(x, y, 48, angleOne, angleTwo, true);
  ctx.closePath();
  ctx.lineWidth = lineThickness.value;
  ctx.stroke();

  ctx.fillStyle = strokeStyle.value;
  ctx.font = '20px system-ui';

  ctx.fillText(`${normalizeAngleTo180(calculatedAngle)}°`, x - 15, y - 25);
};

const normalizeAngleTo180 = (calculatedAngle: number) => {
  return calculatedAngle > 180 ? 360 - calculatedAngle : calculatedAngle;
};

const clearCanvas = () => {
  coordinates.value = [];
  lastPointCoordinateOnMove.value = { x: 0, y: 0 };
  clearDrawing();
};

const clearDrawing = () => {
  ctx.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);
};

const setStrokeStyle = (color: string) => {
  strokeStyle.value = color;
  isDrawingControlsVisible.value = false;

  drawAllLines();
  showAngle();
};

const updateLineThickness = (thickness: number) => {
  lineThickness.value = thickness;
  isThicknessControlsVisible.value = false;

  clearDrawing();
  drawAllLines();
  showAngle();
};

const getOffsetAdjustedPath = (path: { x: number; y: number }) => {
  const x = path.x - canvasOffsets.x;
  const y = path.y - canvasOffsets.y;

  return { x, y };
};

const updateDrawing = ({
  strokeStyle,
  thickness,
}: {
  strokeStyle: string;
  thickness: number;
}) => {
  setStrokeStyle(strokeStyle);
  updateLineThickness(thickness);
};
</script>

<style scoped>
.canvas {
  touch-action: none;
}
</style>
