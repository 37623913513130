import { StorageReference } from 'firebase/storage';

export class UserAvatarFile {
  constructor(
    private readonly storageFileRef: StorageReference,
    private readonly user: { displayName?: string; email?: string } = {
      displayName: '',
      email: '',
    },
  ) {}

  get publicUrl() {
    const { bucket, fullPath } = this.storageFileRef ?? {};

    return `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodeURIComponent(
      fullPath,
    )}?alt=media`;
  }

  get firstLetter() {
    const { displayName, email } = this.user;
    const letter = (displayName || email)?.charAt(0) ?? '';

    return letter.toUpperCase();
  }
}
