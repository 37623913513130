import { environmentVariables } from 'src/core/config/AppConfig';

export class AppFeatureFlags {
  static parse(featureFlag: string) {
    try {
      return JSON.parse(featureFlag);
    } catch {
      return false;
    }
  }

  static get isSubscriptionEnabled() {
    return !!AppFeatureFlags.parse(
      environmentVariables.FEATURE_SUBSCRIPTIONS_ENABLED,
    );
  }
}
