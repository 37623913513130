import { BaseBffHttpService } from '../BaseBffHttpService';

type CreateTeamRequest = {
  email: string;
  teamId: string;
};

export class TeamInvitesHttpService extends BaseBffHttpService {
  basePath = 'team-invites';

  create(body: CreateTeamRequest) {
    return this.post<CreateTeamRequest>(this.basePath, body);
  }

  listMy(teamId: string) {
    return this.get([this.basePath, teamId].join(this.pathSeparator));
  }
}
