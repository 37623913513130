<template>
  <div class="an-body public h-full">
    <section class="an-gradient-bg w-full">
      <header
        class="mx-auto flex max-w-screen-xl items-center justify-between px-4 py-4 md:py-7"
      >
        <div class="flex items-center text-sm font-semibold text-white">
          <img
            class="mr-20 h-8"
            src="/athleticnext_light.svg"
            alt="AthleticNext dark logo on light background"
          />
        </div>
        <div class="flex items-center text-sm font-semibold text-white">
          <a
            href="#for-coaches"
            class="mr-8 hidden transition duration-300 ease-in-out hover:text-orange-400 md:mr-12 md:block"
          >
            Coaches
          </a>
          <a
            href="#for-athletes"
            class="mr-8 hidden transition duration-300 ease-in-out hover:text-orange-400 md:mr-12 md:block"
          >
            Athletes
          </a>
          <RouterLink
            to="/login"
            class="mr-8 flex items-center transition duration-300 ease-in-out hover:text-orange-400 hover:opacity-90 md:mr-12"
          >
            <UserIcon class="mr-1.5 h-5 w-5" />
            Sign In
          </RouterLink>
          <RouterLink
            to="/register"
            class="hidden rounded-md bg-white px-5 py-2.5 font-medium capitalize text-black transition duration-300 hover:bg-white/95 md:inline"
          >
            Get started for free
          </RouterLink>
        </div>
      </header>

      <div
        class="radial-animation absolute right-0 top-[40%] hidden h-3/5 w-1/2 -translate-y-1/2 transform opacity-70 md:block"
      />

      <div class="mx-auto max-w-screen-xl px-4 pt-8 md:pt-12">
        <div class="flex flex-col items-center justify-between md:flex-row">
          <div class="order-2 mt-12 md:order-1 md:mr-14 md:mt-0 md:w-6/12">
            <h1
              class="an-gradient-text text-center text-4xl font-bold md:text-left md:text-7xl lg:text-8xl"
            >
              Athlete starts with a motion
            </h1>

            <p
              class="my-4 text-center text-base font-bold leading-6 text-white md:my-12 md:text-left"
            >
              With technology in our hands, we can help athletes improve their
              technique and mechanics at all times.
            </p>
          </div>
          <div
            class="z-20 order-1 flex w-full items-center justify-end self-baseline md:order-2 md:w-7/12"
          >
            <img
              src="/athleticnext-app-preview.png"
              alt="Video analysis of track athlete"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div
        class="mx-auto flex max-w-screen-xl flex-col items-center px-4 md:flex-row"
      >
        <router-link
          to="/register"
          class="flex h-12 w-56 items-center justify-center rounded-md bg-white font-medium capitalize text-black transition duration-300 hover:bg-white/95"
        >
          Get started on web
        </router-link>
        <span class="p-3 font-medium text-white">or</span>

        <div>
          <a
            href="https://apps.apple.com/us/app/athleticnext/id1607750949?itsct=apps_box_badge&amp;itscg=30200"
            target="_blank"
          >
            <img
              class="h-12"
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?releaseDate=1652140800&h=499ac473b2de28861eea83f1890eb203"
              alt="Download on the App Store"
            />
          </a>
        </div>
      </div>
    </section>

    <div class="bg-black px-4 py-20 md:py-32">
      <div class="mx-auto max-w-screen-xl">
        <p
          class="relative text-center text-xl font-bold text-white opacity-95 md:px-14 md:text-2xl"
        >
          "We train smarter these days and not harder. Patience is the best way
          to success. Self belief and confidence can get you closer to your
          dreams."
        </p>
        <p
          class="mt-3 w-full text-right text-base font-medium text-white opacity-95 md:mr-14 md:w-auto"
        >
          Jaysuma Saidy Ndure - Olympic Athlete
        </p>
      </div>
    </div>

    <div
      v-if="statistics.users || statistics.videos"
      class="justify-center bg-black px-8 pb-20 text-white sm:flex"
    >
      <div
        v-if="statistics.users"
        class="w-full rounded-lg border border-white px-4 py-2 sm:mr-4 sm:w-[256px]"
      >
        <p class="text-xs font-medium uppercase">Trusted by</p>
        <p class="an-gradient-text py-1 text-3xl font-bold">
          {{ statistics.users }}+
        </p>
        <p class="text-xs font-medium uppercase">athletes and coaches</p>
      </div>
      <div
        v-if="statistics.videos"
        class="mt-4 w-full rounded-lg border border-white px-4 py-2 sm:ml-4 sm:mt-0 sm:w-[256px]"
      >
        <p class="text-xs font-medium uppercase">&nbsp;</p>
        <p class="an-gradient-text py-1 text-3xl font-bold">
          {{ statistics.videos }}+
        </p>
        <p class="text-xs font-medium uppercase">videos analyzed</p>
      </div>
    </div>

    <div class="relative bg-black pb-20 md:pb-40">
      <p
        id="for-coaches"
        class="mx-auto mb-6 w-fit-content text-3xl font-bold capitalize tracking-wide text-white md:pt-14 md:text-5xl"
      >
        Coaches
      </p>

      <div
        class="max-w-screen-md px-4 pb-12 text-center font-normal md:mx-auto"
      >
        <span class="text-gray-300">
          Don’t miss any detail:
          <span class="an-gradient-text">AthleticNext</span> is all about making
          coach-athlete work even more productive. Coaches can analyse their
          team member videos, see the performance up-close, comment, make voice
          recordings, let their team know their insights on the performance. All
          of the stats will be collected in one place so you can go back, look
          back and improve.
        </span>
      </div>

      <Carousel class="mx-auto" :tabs="coachFeatures" />
    </div>

    <div
      class="bg-black pb-20 text-justify text-white transition-all duration-75 ease-in md:pb-40"
    >
      <div class="max-w-screen-lg px-4 md:mx-auto">
        <p
          id="for-athletes"
          class="mx-auto mb-6 w-fit-content text-3xl font-bold capitalize tracking-wide text-white md:pt-14 md:text-5xl"
        >
          Athletes
        </p>

        <div class="mx-auto max-w-screen-md pb-12 text-center font-normal">
          <span class="text-gray-300">
            Work, upload videos, share, wait for feedback, improve, repeat.
            <span class="an-gradient-text"> AthleticNext </span>
            lets athletes share their performance with their coaches by
            uploading videos, get insights, analyse, collect all of their work
            in one place and get better and better.
          </span>
        </div>
      </div>

      <Carousel class="mx-auto" :tabs="athleteFeatures" css-class="w-[75vw]" />
    </div>

    <div class="bg-black px-4 pb-20 md:pb-40">
      <div class="mx-auto flex max-w-screen-xl flex-col items-center">
        <h2
          class="w-full text-center text-3xl font-semibold capitalize text-white md:text-5xl"
        >
          Start free and upgrade your subscription as you grow
        </h2>

        <section
          class="mt-10 grid w-full gap-12"
          :class="`grid-cols-${subscriptions.length}`"
        >
          <div
            v-for="{
              name,
              color,
              price,
              description,
              benefits,
            } in subscriptions"
            :key="name"
            class="relative col-span-3 h-[420px] rounded-xl px-8 py-4 transition duration-300 ease-in md:col-span-1"
            :class="[color, `col-span-${subscriptions.length}`]"
          >
            <h6 class="text-md font-semibold">{{ name }}</h6>
            <p class="mt-4 text-3xl font-semibold">{{ price }}</p>
            <p class="mb-12 mt-8 min-h-[4rem] text-sm">{{ description }}</p>
            <div
              v-for="(benefit, index) in benefits"
              :key="`${benefit}-${index}`"
              class="flex items-center font-semibold"
              :class="{ 'mt-2': index > 0 }"
            >
              <ArrowRightIcon class="mr-2 h-6 w-6" />
              {{ benefit }}
            </div>
          </div>
        </section>
      </div>
    </div>

    <section class="bg-black">
      <div
        class="mx-auto flex max-w-screen-xl flex-col items-center px-4 pb-20"
      >
        <h3
          class="mx-auto mb-6 w-fit-content text-center text-3xl font-bold capitalize tracking-wide text-white md:pt-14 md:text-5xl"
        >
          How can we help you succeed?
        </h3>
        <p class="text-center font-normal text-white">
          <span class="an-gradient-text"> AthleticNext </span> transforms the
          sport by digitalising it. Athletes will be able to improve their
          performance by seeing real-time feedback from their coaches: all of
          that is possible with the latest Web and AI technologies. The tools we
          provide will take the collaboration between athletes and coaches to
          the next level.
          <span class="an-gradient-text">
            #athleticnext #nextgenerationathletics
          </span>
        </p>
        <router-link
          to="/register"
          class="mt-4 flex h-12 min-w-min items-center justify-center rounded-md bg-white px-5 font-medium capitalize text-black transition duration-300 hover:bg-white/95 md:mt-12"
        >
          Create an account - it's free!
        </router-link>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script setup lang="ts">
import Carousel from 'src/components/common/Carousel.vue';
import Footer from 'src/components/common/Footer.vue';
import { ArrowRightIcon, UserIcon } from '@heroicons/vue/20/solid';
import { computed, onMounted, ref } from 'vue';
import { PublicRepository } from 'src/core/repositories/PublicRepository';

const publicRepository = new PublicRepository();

const athleteFeatures = [
  {
    title: 'Share',
    text: 'Let your coach see your performance up-close: upload and share your videos.',
    image: '/athlete_14.jpg',
  },
  {
    title: 'Analyse',
    text: 'Athlete-coach teamwork has never been easier: work together, analyse your performance and see the room for improvement.',
    image: '/athlete_12.jpg',
  },
  {
    title: 'Collect history',
    text: 'All of your hard work in one place. Videos, comments, analysis collected on the app.',
    image: '/athlete_13.jpg',
  },
  {
    title: 'Follow progress',
    text: 'See the improvements and continue. AthleticNext lets you follow your journey. ',
    image: '/athlete_15.jpg',
  },
];

const coachFeatures = [
  {
    title: 'Assemble a team',
    text: 'Make your own team of athletes by adding participants on the app.',
    image: '/athlete_7.jpg',
  },
  {
    title: 'Comment on videos',
    text: 'Let your team know your thoughts of their performance by commenting on their videos.',
    image: '/athlete_8.jpg',
  },
  {
    title: 'Record voice comments',
    text: 'Get even more specific - voice recording option lets you get into details about your team’s performance.',
    image: '/athlete_10.jpg',
  },
  {
    title: 'Share analysis',
    text: 'All stats in one place. Analyse. Share expertise. Improve. Raise the limits.',
    image: '/athlete_9.jpg',
  },
  {
    title: 'Save sketches',
    text: 'Get the best technology can offer. Draw right on the video or use Artificial Intelligence.',
    image: '/athlete_16.jpg',
  },
];

const subscriptions = computed(() => {
  return [
    {
      name: 'Basic',
      color: 'bg-gray-300 hover:bg-white',
      price: 'Free',
      description:
        'Get to know the platform. This option allows you to invite and add up to 3 athletes to your team. Store and share up to 1GB of videos.',
      benefits: ['Invite up to 3 athletes', 'Store up to 1GB of videos'],
    },
    {
      name: 'Professional',
      color: 'bg-orange-300 hover:bg-orange-400',
      price: 'On Request',
      description:
        'You enjoy the platform and have a bigger team? Unlimited amount of athletes in your team, unlimited amount of videos you can upload, share and analyse to improve your work even more.',

      benefits: [
        'Unlimited teams, athletes and storage',
        'Advanced analysis tools',
      ],
    },
  ];
});

const statistics = ref({ users: 0, videos: 0 });

onMounted(async () => {
  const data = await publicRepository.GetStatistics();

  statistics.value = data.statistics;
});
</script>

<style scoped>
.radial-animation {
  animation-delay: 1.75s;
  animation: animate 15s ease-in-out infinite;
  background: radial-gradient(50% 50% at 50% 50%, #222222, transparent 100%);
}

@keyframes animate {
  0% {
    right: 0%;
  }

  25% {
    top: 40%;
  }

  50% {
    right: 5%;
  }

  75% {
    top: 50%;
  }

  100% {
    right: 0%;
  }
}
</style>
