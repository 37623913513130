<template>
  <div
    class="absolute left-1/2 top-3 z-10 -translate-x-1/2 transform rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-0.5 tabular-nums"
  >
    <div class="rounded-md bg-white">
      <div
        class="gap grid grid-cols-2 border-b border-b-slate-300 px-2 py-1 text-xxs text-slate-900"
      >
        <div>Start Time: {{ clock?.startTime?.toFixed(2) }}</div>
        <div>Current Time: {{ currentTime?.toFixed(2) }}</div>
      </div>
      <p class="px-2 py-1 text-center text-lg font-semibold">
        {{
          difference > 0 ? `+${difference.toFixed(2)}` : difference.toFixed(2)
        }}s
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { Clock } from 'src/core/providers/types';

type Props = {
  clock: Clock;
  currentTime: number;
};

const props = withDefaults(defineProps<Props>(), {});

const difference = computed(() => {
  const { clock, currentTime } = props;

  return currentTime - (clock.startTime ?? 0);
});
</script>
