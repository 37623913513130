import { BaseBffHttpService } from '../BaseBffHttpService';
import { TeamDto } from './Teams.types';

type CreateTeamRequest = {
  name: string;
  description: string | undefined;
};

type GetTeamsResponse = Record<'items', TeamDto[]>;

export class TeamsHttpService extends BaseBffHttpService {
  basePath = 'teams';

  async getTeams(): Promise<GetTeamsResponse> {
    const response = await this.get(this.basePath);

    return response.json();
  }

  create(body: CreateTeamRequest) {
    return this.post<CreateTeamRequest>(this.basePath, body);
  }

  deleteTeam(id: string) {
    return this.delete(`${this.basePath}/${id}`);
  }
}
