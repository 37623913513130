<template>
  <button
    type="button"
    class="flex items-center justify-center rounded-lg border py-2.5 text-center text-sm font-medium shadow-sm transition-all disabled:cursor-not-allowed"
    :class="[
      { 'w-full': full },
      dark ? COLORS.dark : COLORS[color],
      small ? 'h-8 min-w-fit px-3' : 'h-12 min-w-[8rem] px-5',
    ]"
    :disabled="disabled"
  >
    <template v-if="loading">
      <ANSpinner :light="dark" :class="{ 'mr-3': $slots.loading }" />
      <slot name="loading" />
    </template>
    <slot v-else />
  </button>
</template>

<script lang="ts">
const COLORS_ENUM = {
  dark: 'dark',
  light: 'light',
  danger: 'danger',
};

const COLORS: Record<string, string> = {
  [COLORS_ENUM.dark]:
    'border-gray-700 bg-gray-700 text-white hover:border-gray-900 hover:bg-gray-900 focus:ring-gray-200 disabled:border-gray-300 disabled:bg-gray-300',
  [COLORS_ENUM.light]:
    'border-gray-300 bg-white text-gray-700 hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-500',
  [COLORS_ENUM.danger]:
    'border-red-500 bg-red-500 text-white hover:border-red-600 hover:bg-red-600 focus:ring-red-200 disabled:border-red-300 disabled:bg-red-300',
};
</script>

<script lang="ts" setup>
import { ANSpinner } from 'src/components/Uikit/Components';

interface IProps {
  small?: boolean;
  full?: boolean;
  dark?: boolean;
  loading?: boolean;
  disabled?: boolean;
  color?: string;
}

withDefaults(defineProps<IProps>(), {
  small: false,
  full: false,
  dark: false,
  loading: false,
  disabled: false,
  color: COLORS_ENUM.light,
});
</script>
