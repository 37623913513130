import { BaseBffHttpService } from '../BaseBffHttpService';
import { CreateFrameTagRequest, FrameTagResponse } from './FrameTags.types';

export class FrameTagsHttpService extends BaseBffHttpService {
  protected basePath = 'frame-tags';

  async create(
    videoId: string,
    body: CreateFrameTagRequest,
  ): Promise<FrameTagResponse> {
    const response = await this.post<CreateFrameTagRequest>(
      `${this.basePath}/${videoId}`,
      body,
    );

    return await response.json();
  }

  async load(videoId: string): Promise<FrameTagResponse> {
    const response = await this.get(`${this.basePath}/${videoId}`, {});

    return await response.json();
  }
}
