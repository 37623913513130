<template>
  <div class="flex flex-col items-center justify-center">
    <slot name="illustration" />
    <h2 class="mt-2 text-2xl font-semibold text-gray-900"><slot /></h2>
    <p class="mt-1 text-sm text-secondary">
      <slot name="description" />
    </p>
  </div>
</template>

<script setup lang="ts"></script>
