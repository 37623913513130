<script setup lang="ts">
import {
  TrashIcon,
  XMarkIcon,
  ArrowDownTrayIcon,
} from '@heroicons/vue/20/solid';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TagIcon,
} from '@heroicons/vue/24/outline';
import { MoveNetKeypoints, moveNetKeyPointsLabels } from 'src/core/Constants';
import { IVideo } from 'src/core/Interfaces/IVideo';
import { getFrameKey, getFrameUrl } from 'src/core/Utils/VideoFrameUtils';
import { FrameTagDto, FrameTagResponseDto } from 'src/core/services/FrameTags';
import { computed, onMounted, ref } from 'vue';
import TagsList from './TagsList.vue';
import TagsSvgOverlay from './TagsSvgOverlay.vue';
import { useHttpApi } from './useHttpApi';
import { useMouseMoveListener } from './useMouseMoveListener';
import { mapToFrameTagDto } from './videoTags.utils';
import {
  Keypoint,
  Keypoints,
  VideoKeypointsHttpService,
} from 'src/core/services/Video/VideoKeypointsHttpService';
import { useSvgToPngDownload } from './useSvgToPngDownload';

const videoKeypointsHttpService = new VideoKeypointsHttpService();

enum FramePointType {
  Point = 'point',
}

const { saveTags, getTags } = useHttpApi();
const { offsetX, offsetY, parentElement } = useMouseMoveListener();
const { download, drawCanvas } = useSvgToPngDownload();

const { video } = defineProps<{ video: IVideo }>();

const imageElementRef = ref();
const tagsSvgOverlayRef = ref();
const isFramesLoaded = ref(false);
const loadedPreviewFrames = ref<number[]>([]);
const frameTags = ref<Map<number, Map<string, FrameTagDto>>>(new Map());
const frameSizeRatio = ref({ x: 1, y: 1 });
const frameSize = ref({ clientWidth: 1, clientHeight: 1 });
const selectedFrame = ref(1);
const currentFramePoints = ref<FrameTagDto[]>();
const framesWithPoints = ref<Set<number>>(new Set());
const pointTooltipCoordinates = ref<FrameTagDto | undefined>(undefined);
const keypoints = ref<Partial<Keypoints>>({});

onMounted(async () => {
  keypoints.value = await videoKeypointsHttpService.getKeypoints(video);

  const { items } = await getTags(video.id);

  items.forEach((tag: FrameTagResponseDto) => {
    const frameTag = mapToFrameTagDto(tag);

    framesWithPoints.value.add(frameTag.frameNumber);

    if (!frameTags.value.has(frameTag.frameNumber)) {
      frameTags.value.set(
        frameTag.frameNumber,
        new Map([[frameTag.id, frameTag]]),
      );
    } else {
      frameTags.value.get(frameTag.frameNumber)?.set(frameTag.id, frameTag);
    }
  });
});

const frameTagsBySelectedFrame = computed<FrameTagDto[]>(() => {
  if (!selectedFrame.value) {
    return [];
  }

  return Array.from(frameTags.value.get(selectedFrame.value)?.values() ?? []);
});

const frameTagsBySelectedFrameAsKeypoints = computed<Keypoint[]>(() => {
  return frameTagsBySelectedFrame.value.map((tag) => ({
    x: tag.offsetX,
    y: tag.offsetY,
    score: 1,
    name: tag.name as keyof typeof moveNetKeyPointsLabels,
  }));
});

const frameIndexes = computed(() => {
  const step = Math.ceil(video.framesCount / video.duration / 5);

  const sequence = [];
  let current = 1;

  while (current <= video.framesCount - 1) {
    sequence.push(current);
    current += step;
  }

  return sequence;
});

const selectedFrameKeypoints = computed(() => {
  return keypoints.value?.frames?.[getFrameKey(selectedFrame.value)];
});

const downloadFileName = computed(() => {
  if (!selectedFrame.value || !frameIndexes.value) {
    return;
  }

  const frameNumber = frameIndexes.value.indexOf(selectedFrame.value) + 1;

  return `frame-${frameNumber}-${selectedFrame.value}-${video.id.slice(0, 8)}`;
});

const selectFrameHandler = (index: number) => {
  if (currentFramePoints.value?.length) {
    saveTags(
      video.id,
      currentFramePoints.value.map((tag) => ({
        name: tag.name,
        offsetX: Math.floor(tag.offsetX),
        offsetY: Math.floor(tag.offsetY),
        frameNumber: tag.frameNumber,
        frameWidth: tag.frameWidth,
        frameHeight: tag.frameHeight,
        type: tag.type,
      })),
    );
  }
  selectedFrame.value = index;

  const frame = frameTags.value.get(selectedFrame.value);

  currentFramePoints.value = frame ? [...frame.values()] : [];
  pointTooltipCoordinates.value = undefined;
};

const addPointHandler = () => {
  if (pointTooltipCoordinates.value) {
    pointTooltipCoordinates.value = undefined;

    return;
  }

  const x = offsetX.value * frameSizeRatio.value.x;
  const y = offsetY.value * frameSizeRatio.value.y;

  const newTag: FrameTagDto = {
    id: Date.now().toString(),
    name: MoveNetKeypoints.LEFT_ANKLE,
    offsetX: x,
    offsetY: y,
    frameNumber: selectedFrame.value,
    frameWidth: video.width,
    frameHeight: video.height,
    type: FramePointType.Point,
  };

  currentFramePoints.value = [newTag];
  pointTooltipCoordinates.value = currentFramePoints.value[0];

  if (!frameTags.value.has(selectedFrame.value)) {
    frameTags.value.set(selectedFrame.value, new Map());
  }

  const frame = frameTags.value.get(selectedFrame.value);
  frame?.set(newTag.id, newTag);
  currentFramePoints.value = [...(frame?.values() ?? []), newTag];

  pointTooltipCoordinates.value = undefined;
  framesWithPoints.value.add(selectedFrame.value);
};

const selectTagHandler = (e: MouseEvent, frameTag: FrameTagDto) => {
  e.preventDefault();
  pointTooltipCoordinates.value = frameTag;
};

const deleteFrameTag = () => {
  if (!pointTooltipCoordinates.value) {
    return;
  }

  const frame = frameTags.value.get(selectedFrame.value);

  frame?.delete(pointTooltipCoordinates.value?.id);
  pointTooltipCoordinates.value = undefined;
  currentFramePoints.value = [...(frame?.values() ?? [])];
};

const keyPoints = Object.values(MoveNetKeypoints).sort();

const updateFrameSizeRatio = () => {
  if (!imageElementRef.value) {
    return;
  }

  frameSizeRatio.value = {
    x: video.width / imageElementRef.value.clientWidth,
    y: video.height / imageElementRef.value.clientHeight,
  };

  frameSize.value = {
    clientWidth: imageElementRef.value.clientWidth,
    clientHeight: imageElementRef.value.clientHeight,
  };
};
</script>

<template>
  <div class="flex h-full">
    <div
      class="mx-2 mb-6 mt-16 flex w-64 shrink-0 flex-col rounded-md bg-gray-800/80 p-2"
    >
      <div class="overflow-hidden">
        <div
          class="grid max-h-full shrink-0 grid-cols-2 gap-3 overflow-y-scroll"
        >
          <div
            v-for="(frameIndex, index) in frameIndexes"
            :key="frameIndex"
            class="relative border-2"
            :class="[
              frameIndex === selectedFrame
                ? 'border-white'
                : framesWithPoints.has(frameIndex)
                  ? 'border-green-400/80'
                  : 'border-transparent',
            ]"
          >
            <img
              :src="getFrameUrl(frameIndex, video.blobDirectory)"
              class="cursor-pointer transition-opacity hover:opacity-100"
              :class="[
                frameIndex === selectedFrame ? 'opacity-100' : 'opacity-80',
              ]"
              @click="() => selectFrameHandler(frameIndex)"
              @load="
                () => {
                  loadedPreviewFrames.push(frameIndex);
                }
              "
            />
            <template v-if="loadedPreviewFrames.includes(frameIndex)">
              <span
                class="absolute top-0 bg-yellow-600/80 p-1 text-sm tabular-nums text-white"
                >{{ index + 1 }}</span
              >
              <TagIcon
                v-if="framesWithPoints.has(frameIndex)"
                class="absolute right-1 top-1 h-5 w-5 fill-green-400/80 text-green-800/80"
              />
            </template>
          </div>
        </div>
      </div>
      <div class="mt-4 flex justify-center">
        <button
          type="button"
          class="btn btn-outline btn-primary btn-sm mr-2 pl-2"
          @click="
            () => {
              selectFrameHandler(
                frameIndexes[
                  Math.max(0, frameIndexes.indexOf(selectedFrame) - 1)
                ],
              );
            }
          "
        >
          <ChevronLeftIcon class="h-4 w-4" />
          Prev
        </button>
        <button
          type="button"
          class="btn btn-outline btn-primary btn-sm ml-2 pr-2"
          @click="
            () => {
              selectFrameHandler(
                frameIndexes[
                  Math.min(
                    frameIndexes.length - 1,
                    frameIndexes.indexOf(selectedFrame) + 1,
                  )
                ],
              );
            }
          "
        >
          Next
          <ChevronRightIcon class="h-4 w-4" />
        </button>
      </div>
    </div>

    <div class="mr-2 flex grow items-center justify-around">
      <div ref="parentElement" class="relative">
        <div
          v-if="pointTooltipCoordinates"
          class="indicator absolute z-[999999] w-96 -translate-x-1/2 -translate-y-full transform"
          :style="{
            left: `${pointTooltipCoordinates.offsetX / frameSizeRatio.x}px`,
            top: `${pointTooltipCoordinates.offsetY / frameSizeRatio.y - 16}px`,
          }"
          @click="(e) => e.preventDefault()"
        >
          <div class="indicator-item">
            <button
              class="btn btn-circle btn-xs"
              type="button"
              @click="pointTooltipCoordinates = undefined"
            >
              <XMarkIcon />
            </button>
          </div>
          <div
            class="card card-compact w-full rounded-md bg-base-100 shadow-xl"
          >
            <div class="card-body !p-2.5">
              <label class="form-control w-full">
                <div class="label">
                  <span class="label-text">Body Point</span>
                </div>
                <div class="flex items-center">
                  <select
                    v-model="pointTooltipCoordinates.name"
                    class="select select-bordered flex-1"
                  >
                    <option disabled selected>Pick one</option>
                    <option
                      v-for="keyPoint in keyPoints"
                      :key="keyPoint"
                      :value="keyPoint"
                    >
                      {{ moveNetKeyPointsLabels[keyPoint] }}
                    </option>
                  </select>
                  <button
                    class="btn btn-square ml-4"
                    type="button"
                    @click="deleteFrameTag"
                  >
                    <TrashIcon class="h-6 w-6" />
                  </button>
                </div>
              </label>
            </div>
          </div>
        </div>

        <div
          v-show="!!isFramesLoaded"
          class="glass absolute right-3 top-3 z-10 h-fit-content w-fit-content rounded-lg p-2"
        >
          <button
            class="btn btn-primary btn-xs outline-none"
            onclick="download_image_modal.showModal()"
            type="button"
            @click="
              () =>
                drawCanvas({
                  svgElementId: 'svg-element',
                  backgroundImageSource: getFrameUrl(
                    selectedFrame,
                    video.blobDirectory,
                  ),
                  width: video.width,
                  height: video.height,
                  frameWidth: frameSize.clientWidth,
                  frameHeight: frameSize.clientHeight,
                  keypoints: frameTagsBySelectedFrameAsKeypoints,
                  aiKeypoints: selectedFrameKeypoints?.pose.keypoints,
                })
            "
          >
            Download
            <ArrowDownTrayIcon class="h-4 w-4" />
          </button>
        </div>
        <dialog id="download_image_modal" class="modal">
          <div class="modal-box grid w-11/12 max-w-5xl grid-rows-1 p-4">
            <div class="overflow-scroll rounded-xl">
              <canvas
                id="download-image-canvas"
                class="w-full"
                :width="video.width"
                :height="video.height"
              ></canvas>
            </div>
            <div v-if="downloadFileName" class="modal-action mt-4">
              <button
                type="button"
                class="btn btn-sm font-bold outline-none"
                onclick="download_image_modal.close()"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm font-bold outline-none"
                onclick="download_image_modal.close()"
                @click="download(downloadFileName)"
              >
                Download Image
              </button>
            </div>
          </div>
        </dialog>

        <div ref="tagsSvgOverlayRef">
          <TagsSvgOverlay
            id="svg-element"
            :tags="frameTagsBySelectedFrame"
            :active-tag-id="pointTooltipCoordinates?.id"
            :frame-size-ratio="frameSizeRatio"
            :frame-size="frameSize"
            :keypoints-frame="selectedFrameKeypoints"
            @select-tag="({ e, frameTag }) => selectTagHandler(e, frameTag)"
            @add-tag="addPointHandler"
          />
        </div>

        <img
          ref="imageElementRef"
          :src="getFrameUrl(selectedFrame, video.blobDirectory)"
          class="max-h-screen"
          @load="
            () => {
              updateFrameSizeRatio();
              isFramesLoaded = true;
            }
          "
        />
      </div>
    </div>

    <TagsList
      :tags="frameTagsBySelectedFrame"
      @select-tag="({ e, frameTag }) => selectTagHandler(e, frameTag)"
    />
  </div>
</template>
