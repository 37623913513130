import { ClientAppConfig } from 'src/core/ClientAppConfig';
import { MoveNetKeypoints } from 'src/core/Constants';
import { IVideo } from 'src/core/Interfaces/IVideo';

export interface Keypoints {
  frames: { [key: string]: Frame };
}

export interface Frame {
  pose: Pose;
}

export interface Pose {
  keypoints: Keypoint[];
  score: number;
}

export interface Keypoint {
  y: number;
  x: number;
  score: number;
  name: MoveNetKeypoints;
}

export class VideoKeypointsHttpService {
  async getKeypoints({ blobDirectory }: IVideo): Promise<Keypoints> {
    const url = new URL(
      [ClientAppConfig.mediaCdnUrl, blobDirectory, 'keypoints.json'].join('/'),
    ).href;

    const response = await fetch(url);

    return response.json();
  }
}
