<template>
  <FullscreenLayout class="h-full min-h-screen bg-slate-100">
    <PageHeader>
      Settings
      <template #action>
        <ANButton dark @click="router.push(APP_ROUTES.DASHBOARD)">
          <div class="flex items-center justify-center">
            <ArrowLeftIcon
              class="mr-2 h-5 w-5 text-white transition duration-300 ease-in-out"
            />
            <p>Back</p>
          </div>
        </ANButton>
      </template>
    </PageHeader>
    <ProfileInfo class="bg-white" />
    <SubscriptionOverview class="mt-6 bg-white" />
    <AccountManagement class="mt-6 bg-white" />
  </FullscreenLayout>
</template>

<script setup lang="ts">
import SubscriptionOverview from 'src/components/Account/SubscriptionOverview.vue';
import PageHeader from 'src/components/common/PageHeader.vue';
import FullscreenLayout from 'src/components/layout/FullscreenLayout.vue';
import { useRouter } from 'vue-router';
import { APP_ROUTES } from 'src/core/AppRoutes';
import { ArrowLeftIcon } from '@heroicons/vue/24/outline';
import { ANButton } from 'src/components/Uikit/Components';
import ProfileInfo from 'src/components/Account/settings/ProfileInfo.vue';
import AccountManagement from 'src/components/Account/AccountManagement.vue';

const router = useRouter();
</script>
