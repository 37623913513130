<template>
  <AppLayout
    :full-width="true"
    :body-class="['overflow-hidden', 'an-u-min-h-100vh']"
  >
    <VideoPlayerStateProvider>
      <div
        v-if="video"
        class="relative bg-black"
        style="height: 100vh; width: 100vw"
      >
        <VideoDetailsHeader
          v-if="!store.deviceInfo.isMobileWebClient"
          :video="video"
        />
        <template v-if="(video.userId || video.userUid) && video.blobName">
          <VideoPlayerSideBySide
            v-if="videoDetailsStore.mode === VideoDetailsMode.SideBySidePlayer"
            class="video-player-wrapper"
            :class="{ 'drawer-visible': isDrawerVisible }"
            :video="video"
          />

          <VideoTagsScreen
            v-else-if="videoDetailsStore.mode === VideoDetailsMode.Tags"
            :video="video"
          />

          <VideoPlayer
            v-else
            :video="video"
            class="video-player-wrapper"
            :class="{ 'drawer-visible': isDrawerVisible }"
          />
        </template>

        <div
          v-if="isFloatingMenuVisible"
          class="fixed left-1/2 opacity-100 transition-[bottom]"
          :class="{
            'floating-with-drawer': isDrawerVisible,
            'bottom-24': isImageSliderVisible,
            'bottom-28': isImageSliderVisible && isSideBySideVideoPlayerVisible,
            'bottom-4': !isImageSliderVisible,
          }"
        >
          <div
            v-if="isActionWrapperVisible"
            class="absolute bottom-full mb-2 -translate-x-1/2 transform"
          >
            <VideoSettings
              v-if="isVideoSettingsVisible"
              :playback-rate="playbackRate"
              @change="setPlaybackRate"
            />
            <VideoTools v-if="isToolsActionMenuVisible" />
          </div>
          <FloatingMenu>
            <button
              type="button"
              class="flex cursor-pointer flex-col items-center px-2 hover:text-stone-200"
              :class="[
                videoDetailsStore.mode === VideoDetailsMode.VideoPlayer &&
                  'text-white',
              ]"
              @click="toggleAction(VideoDetailsMode.VideoPlayer)"
            >
              <FilmIcon class="h-5 w-5" />
              <span class="text-xxs">Player</span>
            </button>
            <template v-if="video.hasKeypoints">
              <button
                type="button"
                class="flex cursor-pointer flex-col items-center px-2 hover:text-stone-200"
                :class="[
                  videoDetailsStore.mode === VideoDetailsMode.AiFrames &&
                    'text-white',
                ]"
                @click="toggleAction(VideoDetailsMode.AiFrames)"
              >
                <CubeTransparentIcon class="h-5 w-5" />
                <span class="text-xxs">AI Frames</span>
              </button>
              <button
                type="button"
                class="flex cursor-pointer flex-col items-center px-2 hover:text-stone-200"
                :class="[
                  videoDetailsStore.mode === VideoDetailsMode.Tags &&
                    'text-white',
                ]"
                @click="toggleAction(VideoDetailsMode.Tags)"
              >
                <TagIcon class="h-5 w-5" />
                <span class="text-xxs">Tags</span>
              </button>
            </template>
            <button
              type="button"
              class="flex cursor-pointer flex-col items-center px-2 transition-colors hover:text-stone-200"
              :class="[
                videoDetailsStore.mode === VideoDetailsMode.SideBySidePlayer &&
                  'text-white',
              ]"
              @click="toggleAction(VideoDetailsMode.SideBySidePlayer)"
            >
              <FilmIcon class="h-5 w-5 rotate-90 transform" />
              <span class="text-xxs">Compare</span>
            </button>

            <div class="flex h-8 w-6 justify-around">
              <div
                class="h-full w-0.5 border-r border-stone-200/10 bg-stone-400/20"
              ></div>
            </div>

            <button
              v-if="!isSideBySideVideoPlayerVisible"
              type="button"
              class="flex cursor-pointer flex-col items-center px-2 hover:text-stone-200"
              @click="toggleVideoCanvas()"
            >
              <PencilIcon class="h-5 w-5" />
              <span class="text-xxs">Draw</span>
            </button>
            <button
              v-if="!isSideBySideVideoPlayerVisible"
              class="flex cursor-pointer flex-col items-center px-2 hover:text-stone-200"
              type="button"
              @click="setIsToolsActionMenuVisible"
            >
              <ClockIcon class="h-5 w-5" />
              <span class="text-xxs"> Timer </span>
            </button>
            <button
              type="button"
              class="flex cursor-pointer flex-col items-center px-2 hover:text-stone-200"
              @click="toggleDrawer()"
            >
              <PencilSquareIcon class="h-5 w-5" />
              <span class="text-xxs">Notes</span>
            </button>
            <button
              v-if="!isSideBySideVideoPlayerVisible"
              type="button"
              class="flex cursor-pointer flex-col items-center px-2 hover:text-stone-200"
              @click="toggleVideoSettings()"
            >
              <ForwardIcon class="h-5 w-5" />
              <span class="text-xxs">Speed</span>
            </button>
            <button
              class="flex cursor-pointer flex-col items-center px-2 hover:text-stone-200"
              type="button"
              @click="toggleImageSlider"
            >
              <ArrowsUpDownIcon class="h-5 w-5" />
              <span class="text-xxs">
                {{ isImageSliderVisible ? 'Hide' : 'Show' }}
              </span>
            </button>
          </FloatingMenu>
        </div>

        <Drawer v-if="isDrawerVisible && video.id">
          <template #header>
            <h5 class="text-sm font-semibold">Comments</h5>

            <span
              class="close-modal-icon transform cursor-pointer"
              @click="toggleDrawer()"
            >
              <ChevronDownIcon class="h-6 w-6" />
            </span>
          </template>

          <template #content>
            <Comments :video-id="video.id" />
          </template>
        </Drawer>
      </div>
    </VideoPlayerStateProvider>
  </AppLayout>
</template>

<script setup lang="ts">
import {
  ArrowsUpDownIcon,
  ChevronDownIcon,
  // FilmIcon,
} from '@heroicons/vue/20/solid';
import {
  ClockIcon,
  CubeTransparentIcon,
  FilmIcon,
  ForwardIcon,
  PencilIcon,
  PencilSquareIcon,
  TagIcon,
} from '@heroicons/vue/24/outline';
import Comments from 'src/components/Video/Comments.vue';
import VideoDetailsHeader from 'src/components/Video/VideoDetailsHeader.vue';
import VideoPlayer from 'src/components/Video/VideoPlayer.vue';
import VideoPlayerSideBySide from 'src/components/Video/VideoPlayerSideBySide.vue';
import VideoSettings from 'src/components/Video/VideoSettings.vue';
import VideoTagsScreen from 'src/components/Video/VideoTags/VideoTagsScreen.vue';
import Drawer from 'src/components/common/Drawer.vue';
import FloatingMenu from 'src/components/common/FloatingMenu.vue';
import AppLayout from 'src/components/layout/AppLayout.vue';
import { APP_ROUTE_NAMES } from 'src/core/AppRoutes';
import { useEmitter } from 'src/core/EventEmitter';
import {
  CONTEXT_CARD_EVENTS,
  VIDEO_EVENTS,
  VIDEO_PLAYER_EVENTS,
  VOICE_VISUALIZATION_EVENT,
} from 'src/core/Events';
import { IVideo } from 'src/core/Interfaces/IVideo';
import VideoPlayerStateProvider from 'src/core/providers/VideoPlayerStateProvider.vue';
import { VideoHttpService } from 'src/core/services/Video/VideoHttpService';
import VideoTools from 'src/pages/app/VideoDetails/VideoTools.vue';
import { useBodyClassToggler } from 'src/services/utilities/HtmlElementUtilities';
import { store } from 'src/store';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useVideoDetailsStore } from './VideoDetails.store';
import { VideoDetailsMode } from './VideoDetails.types';

const videoHttpService = new VideoHttpService();
const bodyClassToggler = useBodyClassToggler('an-sidebar-open');
const videoDetailsStore = useVideoDetailsStore();

const route = useRoute();
const emitter = useEmitter();
const video = ref<IVideo>({} as IVideo);
const videoFetchInProgress = ref(false);
const isFloatingMenuVisible = ref(true);
const isDrawerVisible = ref(false);
const playbackRate = ref(1);
const isImageSliderVisible = ref(true);
const isSideBySideVideoPlayerVisible = ref(false);
const hasLoadedWaveform = ref(false);
const isVideoSettingsVisible = ref(false);
const isToolsActionMenuVisible = ref(false);

emitter.on(
  VIDEO_EVENTS.VIDEO_SEEK_START,
  () => (isFloatingMenuVisible.value = false),
);

emitter.on(
  VIDEO_EVENTS.VIDEO_SEEK_END,
  () => (isFloatingMenuVisible.value = true),
);

onMounted(async () => {
  await fetchVideo();

  if (route.name === APP_ROUTE_NAMES.VIDEO_TAGS) {
    videoDetailsStore.mode = VideoDetailsMode.Tags;
  }
});

const isActionWrapperVisible = computed(() => {
  return [isVideoSettingsVisible, isToolsActionMenuVisible].some(Boolean);
});

const fetchVideo = async () => {
  videoFetchInProgress.value = true;

  if (typeof route.params.videoId === 'string') {
    video.value = await videoHttpService.getVideo(route.params.videoId);
  }

  videoFetchInProgress.value = false;
};

const toggleDrawer = () => {
  isDrawerVisible.value = !isDrawerVisible.value;
  if (isDrawerVisible.value) {
    bodyClassToggler.add();
  } else {
    bodyClassToggler.remove();
  }

  if (hasLoadedWaveform.value) {
    return;
  }

  emitter.emit(VOICE_VISUALIZATION_EVENT.DRAW);
  hasLoadedWaveform.value = true;
};

const toggleAction = (mode: VideoDetailsMode) => {
  videoDetailsStore.mode = mode;
  closeVideoSettings();
  setIsToolsActionMenuVisible(false);
};

const toggleVideoCanvas = () => {
  emitter.emit(VIDEO_PLAYER_EVENTS.TOGGLE_VIDEO_CANVAS);
  closeVideoSettings();
  setIsToolsActionMenuVisible(false);
};

const toggleVideoSettings = () => {
  isVideoSettingsVisible.value = !isVideoSettingsVisible.value;
  setIsToolsActionMenuVisible(false);
};

const closeVideoSettings = () => {
  isVideoSettingsVisible.value = false;
};

const toggleImageSlider = () => {
  isImageSliderVisible.value = !isImageSliderVisible.value;
  emitter.emit(VIDEO_PLAYER_EVENTS.TOGGLE_IMAGE_SLIDER);
  closeVideoSettings();
  setIsToolsActionMenuVisible(false);
};

const setPlaybackRate = (value: number) => {
  playbackRate.value = value;
  emitter.emit(VIDEO_EVENTS.CHANGE_PLAYBACKRATE, playbackRate.value);
  emitter.emit(CONTEXT_CARD_EVENTS.CLOSE);
  closeVideoSettings();
};

const setIsToolsActionMenuVisible = (
  value: boolean | MouseEvent | TouchEvent,
) => {
  if (typeof value === 'boolean') {
    isToolsActionMenuVisible.value = value;

    return;
  }

  isToolsActionMenuVisible.value = !isToolsActionMenuVisible.value;
  closeVideoSettings();
};
</script>

<style scoped>
.floating-menu {
  bottom: 168px !important;
}

@media (orientation: landscape) {
  .floating-menu {
    bottom: calc(88px + (4.1 * env(safe-area-inset-bottom))) !important;
  }
}

.video-player-wrapper {
  height: 100vh;
  width: 100vw;
}

.drawer-visible {
  height: 50%;
}

.floating-with-drawer {
  transform: translateX(calc(-1 * var(--an-drawer-width)));
}

@screen sm {
  .drawer-visible {
    height: 100vh !important;
    width: 57vw !important;
  }

  .close-modal-icon {
    transform: rotate(-90deg);
  }

  .floating-with-drawer {
    left: 33.3%;
  }
}

@screen lg {
  .drawer-visible {
    height: 100% !important;
    width: 67vw !important;
  }
}
</style>
