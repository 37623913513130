<template>
  <ANDrawer closable @close="emit('close')">
    <template #title> Add Video To Album </template>

    <ANSpinner v-if="isLoading" class="mt-5" />

    <ResponsiveGrid v-else :min-column-width="6" :gap="2" class="-mt-2.5">
      <div>
        <div class="grid grid-cols-1 gap-0.5 rounded-md p-0.5">
          <button
            class="flex h-24 w-full cursor-pointer items-center justify-center rounded-lg bg-gray-100 text-gray-900 transition duration-200 ease-linear hover:bg-gray-200"
            @click="isCreateAlbumModalVisible = true"
          >
            <PlusIcon class="h-6 w-6" />
          </button>
        </div>

        <p class="mt-2 text-xs text-black">New Album</p>
      </div>

      <VideoAlbums v-if="albums.length" :albums="albums" @select="addToAlbum" />
    </ResponsiveGrid>

    <CreateVideoAlbumModal
      v-if="isCreateAlbumModalVisible"
      @success="onAlbumCreated"
      @close="isCreateAlbumModalVisible = false"
    >
      To create new album, please enter the album name. Your selected video will
      be automatically added to the album.
    </CreateVideoAlbumModal>
  </ANDrawer>
</template>

<script lang="ts" setup>
import { DataSnapshot } from '@firebase/database';
import { PlusIcon } from '@heroicons/vue/20/solid';
import ResponsiveGrid from 'src/components/common/ResponsiveGrid.vue';
import { ANDrawer, ANSpinner } from 'src/components/Uikit/Components';
import CreateVideoAlbumModal from 'src/components/Video/CreateVideoAlbumModal.vue';
import VideoAlbums from 'src/components/Video/VideoAlbums.vue';
import { IVideo } from 'src/core/Interfaces/IVideo';
import { IVideoAlbumEntry } from 'src/core/Interfaces/IVideoAlbum';
import AlbumsRepository from 'src/core/repositories/Albums/AlbumsRepository';
import { AlbumsService } from 'src/core/services/Albums/AlbumsService';
import { onMounted, ref } from 'vue';

const albumsRepository = new AlbumsRepository();
const albumsService = new AlbumsService();

const emit = defineEmits(['close']);
const props = withDefaults(defineProps<{ video: IVideo }>(), {
  video: () => ({}) as IVideo,
});

const albums = ref<IVideoAlbumEntry[]>([]);
const isCreateAlbumModalVisible = ref<boolean>(false);
const isLoading = ref<boolean>(false);

onMounted(async () => {
  await getAlbums();
});

const getAlbums = async () => {
  isLoading.value = true;

  albums.value = await albumsService.getAlbums(4);

  isLoading.value = false;
};

const onAlbumCreated = async () => {
  return albumsRepository.onChildAdded(
    albumsRepository.albumPath,
    (data: DataSnapshot) => {
      if (data.key) {
        addToAlbum(data.key);
      }
    },
    true,
  );
};

const isVideoAlreadyInAlbum = (albumKey: string) => {
  const { videos } = albums.value.find(({ key }) => albumKey === key) ?? {};

  return videos?.some(({ id }) => id === props.video?.id);
};

const addToAlbum = async (albumKey: string) => {
  if (isVideoAlreadyInAlbum(albumKey)) {
    return;
  }

  await albumsRepository.addVideoToAlbum(albumKey, props.video);
  await getAlbums();

  isCreateAlbumModalVisible.value = false;
  emit('close');
};
</script>

<style scoped>
.album-modal {
  @apply max-h-screen md:max-h-[50vh];
}
</style>
