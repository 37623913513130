import { reactive } from 'vue';

export enum STORE_KEYS {
  CURRENT_TIME_VIDEO_A = 'CURRENT_TIME_VIDEO_A',
  CURRENT_TIME_VIDEO_B = 'CURRENT_TIME_VIDEO_B',
}

export const store = reactive({
  isSideBySideVideoSeekingLocked: false,
  [STORE_KEYS.CURRENT_TIME_VIDEO_A]: 0,
  [STORE_KEYS.CURRENT_TIME_VIDEO_B]: 0,
  deviceInfo: {
    isMobileWebClient: false,
  },
});
