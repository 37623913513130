import { ClientAppConfig } from 'src/core/ClientAppConfig';
import { IVideo } from '../Interfaces/IVideo';

export const getVideoName = (blobName: string) => {
  const fileName = blobName.split('/').pop();

  return fileName;
};

export const getPoster = (video: IVideo) => {
  // Old format from API (dotnet)
  if (video.blobUrl && !video.hasKeypoints) {
    return `${[
      ClientAppConfig.mediaCdnUrl,
      (video.userUid ?? video.userId ?? '').toLowerCase(),
      video.blobName,
    ].join('/')}.jpeg`;
  }

  // New format from BFF API (node)
  return [
    ClientAppConfig.mediaCdnUrl,
    getDirectory(video.blobName).toLowerCase(),
    'poster.jpg',
  ].join('/');
};

export const getDirectory = (fileName: string) => {
  const fileParts = fileName.split('/');
  fileParts.pop();

  return fileParts.join('/');
};

export const getFramesDirectory = (fileName: string) => {
  return [ClientAppConfig.mediaCdnUrl, getDirectory(fileName), 'frames'].join(
    '/',
  );
};
