import {
  FrameTagRequestDto,
  FrameTagResponse,
  FrameTagsHttpService,
} from 'src/core/services/FrameTags';
import { ref } from 'vue';

export function useHttpApi() {
  const data = ref<FrameTagResponse | null>(null);
  const error = ref<unknown | null>(null);
  const isLoading = ref(false);

  const http = new FrameTagsHttpService();

  async function saveTags(videoId: string, frameTags: FrameTagRequestDto[]) {
    isLoading.value = true;
    try {
      const response = await http.create(videoId, {
        frameTags,
      });

      data.value = response;
    } catch (err: unknown) {
      error.value = err;
    } finally {
      isLoading.value = false;
    }
  }

  async function getTags(videoId: string) {
    isLoading.value = true;

    return await http.load(videoId);
  }

  return { data, error, isLoading, saveTags, getTags };
}
