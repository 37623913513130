import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { ClientAppConfig } from '../ClientAppConfig';

export const useSentry = (app: App<Element>) => {
  if (!ClientAppConfig.sentryDsn) {
    return;
  }

  Sentry.init({
    app,
    dsn: ClientAppConfig.sentryDsn,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};
