<template>
  <div
    class="overflow-hidden rounded-lg border border-gray-100 bg-gray-200 p-1"
  >
    <div
      class="flex items-center gap-1 text-xs font-medium text-gray-400"
      :class="
        orientation === SwitchOrientation.HORIZONTAL ? 'flex-row' : 'flex-col'
      "
    >
      <button
        v-for="option in options"
        :key="option"
        :class="[
          uppercase ? 'font-bold uppercase' : 'capitalize',
          activeTab === option ? 'bg-white shadow' : 'hover:bg-sky-100',
        ]"
        class="relative inline-flex w-full items-center justify-center gap-1 rounded p-1 text-black transition duration-100 ease-linear md:p-2"
        @click="onChange(option)"
      >
        <slot v-bind="{ option }">{{ option }}</slot>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SwitchOrientation, EMIT_EVENTS } from 'src/core/Constants';
import { ref, onMounted } from 'vue';

interface IProps {
  selected?: string;
  options?: string[];
  orientation?: SwitchOrientation;
  uppercase?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  selected: '',
  options: () => [],
  orientation: SwitchOrientation.HORIZONTAL,
  uppercase: false,
});

const emit = defineEmits([EMIT_EVENTS.CHANGE]);
const activeTab = ref('');

onMounted(() => {
  if (!props.selected) {
    return;
  }

  activeTab.value = props.selected;
});

const onChange = (option: string) => {
  if (option === activeTab.value) {
    return;
  }

  activeTab.value = option;
  emit(EMIT_EVENTS.CHANGE, option);
};
</script>
