import { ref, set } from 'firebase/database';
import { useAppUser } from 'src/core/3rdParty/Firebase';
import { IVideo } from 'src/core/Interfaces/IVideo';
import { IVideoAlbum, IVideoAlbumEntry } from 'src/core/Interfaces/IVideoAlbum';
import BaseRealtimeDBRepository from 'src/core/repositories/Albums/BaseRealtimeDBRepository';

export default class AlbumsRepository extends BaseRealtimeDBRepository {
  #USERS_BASE_URL = 'users/';

  getAlbums = () => {
    return this.getData(this.albumPath, {} as IVideoAlbum);
  };

  getVideosInAlbum = (key: string) => {
    return this.getData(`${this.albumPath}/${key}`, {} as IVideoAlbumEntry);
  };

  setAlbum = (name: string) => {
    const entry = this.getEntryRef(this.albumPath);

    set(entry, { name });
  };

  addVideoToAlbum = async (key: string, video: IVideo) => {
    set(ref(this.db, `${this.albumPath}/${key}/videos/${video.id}`), video);
  };

  removeVideoFromAlbum = async (key: string, videoId: string) => {
    this.delete(`${this.albumPath}/${key}/videos/${videoId}`);
  };

  deleteAlbum = async (key: string) => {
    this.delete(`${this.albumPath}/${key}`);
  };

  get albumPath() {
    return `${this.#USERS_BASE_URL}${useAppUser()?.uid}/videoAlbums`;
  }
}
