import { LOWER_BODY, UPPER_BODY } from 'src/core/Constants';
import { Keypoint } from 'src/core/services/Video/VideoKeypointsHttpService';
import Colors from 'tailwindcss/colors';
import { onMounted } from 'vue';

type PointCoordinates = {
  x: number;
  y: number;
};

const drawKeypoints = (
  ctx: CanvasRenderingContext2D,
  keypoints: Keypoint[] = [],
  sizeRatio: number = 1,
  fillStyle: string = Colors.yellow[400],
) => {
  const lines: PointCoordinates[][] = [
    Array.from(new Array(UPPER_BODY.length)).map(
      () => ({}),
    ) as PointCoordinates[],
    Array.from(new Array(LOWER_BODY.length)).map(
      () => ({}),
    ) as PointCoordinates[],
  ];

  const drawPoint = (x: number, y: number) => {
    ctx.fillStyle = Colors.black;
    ctx.beginPath();
    ctx.arc(x, y, 10 * sizeRatio, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = fillStyle;
    ctx.beginPath();
    ctx.arc(x, y, 6 * sizeRatio, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();
  };

  keypoints.forEach(({ x, y, name }: Keypoint) => {
    let index = UPPER_BODY.indexOf(name);

    if (index !== -1) {
      lines[0][index] = { x, y };
      drawPoint(x, y);

      return;
    }

    index = LOWER_BODY.indexOf(name);

    if (index !== -1) {
      lines[1][index] = { x, y };
      drawPoint(x, y);
    }
  });

  lines.forEach((line) => {
    ctx.beginPath();
    ctx.strokeStyle = fillStyle;
    ctx.lineWidth = 4 * sizeRatio;
    line.forEach(({ x, y }, index) => {
      if (!index) {
        ctx.moveTo(x, y);
      }
      ctx.lineTo(x, y);
    });
    ctx.stroke();
  });
};

export function useSvgToPngDownload() {
  let canvas: HTMLCanvasElement | null = null;

  function drawCanvas({
    svgElementId,
    backgroundImageSource,
    height,
    width,
    keypoints,
    aiKeypoints,
  }: {
    svgElementId: string;
    backgroundImageSource: string;
    height: number;
    width: number;
    frameHeight: number;
    frameWidth: number;
    keypoints: Keypoint[] | undefined;
    aiKeypoints: Keypoint[] | undefined;
  }) {
    if (!canvas) {
      return;
    }

    const svgElement = document.querySelector<SVGElement>(`#${svgElementId}`);

    if (!svgElement) {
      return;
    }

    const ctx = canvas!.getContext('2d');
    if (!ctx) {
      return;
    }

    const backgroundImage = new Image();
    backgroundImage.crossOrigin = 'anonymous';
    backgroundImage.onload = () => {
      ctx.drawImage(backgroundImage, 0, 0, width, height);
      drawKeypoints(ctx, keypoints, width / 1680, Colors.red['400']);
      drawKeypoints(ctx, aiKeypoints, width / 1680);
    };
    backgroundImage.src = backgroundImageSource;
  }

  const download = (filename: string) => {
    if (!canvas) {
      return;
    }

    const link = document.createElement('a');
    link.download = `${filename}.jpeg`;
    link.href = canvas.toDataURL('image/jpeg', 1.0);
    link.click();
  };

  onMounted(() => {
    canvas = document.getElementById(
      'download-image-canvas',
    ) as HTMLCanvasElement;
  });

  return { download, drawCanvas };
}
