<template>
  <SidebarLayout>
    <GridContainer>
      <div class="col-span-12 mb-20">
        <PageHeader>
          My Team

          <template #action>
            <div class="flex items-center">
              <ANButton
                dark
                :disabled="!inviteToken.length"
                @click="inviteModalVisible = true"
              >
                Invite Athlete
              </ANButton>
            </div>
          </template>
        </PageHeader>

        <div
          v-if="isFetchInProgress"
          class="flex h-120 items-center justify-center"
        >
          <ANSpinner size="w-10 h-10" />
        </div>

        <div v-else>
          <ANModal
            v-if="inviteModalVisible"
            closable
            size="Medium"
            @close="inviteModalVisible = false"
          >
            <template #title> Invite a friend </template>

            <InviteAFriend
              v-if="inviteToken.length"
              :token="inviteToken"
              :invites-count="invitesCount"
              :athletes-count="athletes.length"
            />
          </ANModal>

          <div
            class="mb-10 grid w-full grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-3"
          >
            <InfoField>
              Athletes in team

              <template #description>
                {{ athletes.length }}
              </template>
            </InfoField>
            <InfoField>
              Invites available

              <template #description>
                {{ invitesCount }}
              </template>
            </InfoField>
          </div>

          <div
            v-if="athletes.length"
            class="grid grid-cols-1 gap-6 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
          >
            <div v-for="athlete in athletes" :key="athlete.id">
              <AthleteCard
                :athlete="athlete"
                :with-info="true"
                @delete="deleteAthlete(athlete.id)"
              />
            </div>
          </div>

          <EmptyState v-else class="mt-8 h-96">
            <template #illustration>
              <UserGroupIcon class="h-24 w-24 text-gray-300" />
            </template>

            <template #description>
              You haven't invited any athletes to join your team yet
            </template>
          </EmptyState>
        </div>
      </div>
    </GridContainer>
  </SidebarLayout>
</template>

<script setup lang="ts">
import { UserGroupIcon } from '@heroicons/vue/20/solid';
import { API, useEndpoint } from 'src/api/api.endpoints';
import EmptyState from 'src/components/common/EmptyState.vue';
import GridContainer from 'src/components/common/GridContainer.vue';
import InfoField from 'src/components/common/InfoField.vue';
import PageHeader from 'src/components/common/PageHeader.vue';
import SidebarLayout from 'src/components/layout/SidebarLayout.vue';
import AthleteCard from 'src/components/Team/AthleteCard.vue';
import InviteAFriend from 'src/components/Team/InviteAFriend.vue';
import { ANButton, ANModal, ANSpinner } from 'src/components/Uikit/Components';
import { INVITES_PER_USER_LIMIT } from 'src/core/ClientAppConfig';
import { HttpMethods } from 'src/core/Constants';
import { HttpClientFetch } from 'src/core/HttpClient';
import { IMember } from 'src/core/Interfaces/IAthlete';
import { computed, onMounted, ref } from 'vue';

const athletes = ref<IMember[]>([]);
const user = ref<IMember>({} as IMember);
const isFetchInProgress = ref(false);
const inviteToken = ref('');
const inviteModalVisible = ref(false);

onMounted(async () => {
  isFetchInProgress.value = true;

  await getToken();
  await getAthletes();
  await getUser();

  isFetchInProgress.value = false;
});

const invitesCount = computed(() => {
  if (athletes.value.length > INVITES_PER_USER_LIMIT) {
    return 0;
  }

  return INVITES_PER_USER_LIMIT - athletes.value.length;
});

const getUser = async () => {
  const response = await HttpClientFetch(useEndpoint(API.USER));

  if (!response.ok) {
    return;
  }

  user.value = await response.json();
};

const getAthletes = async () => {
  const response = await HttpClientFetch(useEndpoint(API.MY_ATHLETES));

  if (!response.ok) {
    return;
  }

  const { items } = await response.json();

  athletes.value = items;
};

const deleteAthlete = async (athleteId: string) => {
  const response = await HttpClientFetch(
    `${useEndpoint(API.MY_ATHLETES)}/${athleteId}`,
    {
      method: HttpMethods.DELETE,
    },
  );

  if (!response.ok) {
    return;
  }

  const { items } = await response.json();

  athletes.value = items;
};

const getToken = async () => {
  const response = await HttpClientFetch(useEndpoint(API.INVITE_TOKEN));

  if (!response.ok) {
    return;
  }

  const { token } = await response.json();

  inviteToken.value = token;
};
</script>
