<template>
  <div ref="contextCardDropdown" class="context-card z-50">
    <div class="context-card__wrapper">
      <div class="context-card__binding" @click="toggleDropdown">
        <slot />
      </div>
      <div
        v-if="isDropdownOpen"
        class="context-card__dropdown-wrapper absolute z-30 block overflow-hidden rounded-md border-t-4 border-t-sky-500 bg-white"
        :class="[margin, cssClass]"
      >
        <slot name="content" v-bind="{ close: closeDropdown }" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEmitter } from 'src/core/EventEmitter';
import { CONTEXT_CARD_EVENTS } from 'src/core/Events';
import { computed, onMounted, onUnmounted, ref } from 'vue';

const emitter = useEmitter();

interface Props {
  position?: string;
  margin?: string;
}

const props = withDefaults(defineProps<Props>(), {
  position: 'left',
  margin: 'my-2',
});

const emit = defineEmits(['open', 'close']);

const isDropdownOpen = ref(false);
const contextCardDropdown = ref<HTMLDivElement>();

const cssClass = computed(
  () => `context-card__dropdown-wrapper--${props.position}`,
);

onMounted(() => {
  document.body.addEventListener('click', onClickOutside);
  emitter.on(CONTEXT_CARD_EVENTS.CLOSE, () => {
    closeDropdown();
  });
});

onUnmounted(() => {
  document.body.removeEventListener('click', onClickOutside);
});

const closeDropdown = () => {
  if (!isDropdownOpen.value) {
    return;
  }

  isDropdownOpen.value = false;
  emit('close');
};

const openDropdown = () => {
  if (isDropdownOpen.value) {
    return;
  }

  emit('open');
  isDropdownOpen.value = true;
};

const toggleDropdown = () => {
  return isDropdownOpen.value ? closeDropdown() : openDropdown();
};

const onClickOutside = (event: PointerEvent | MouseEvent) => {
  if (!contextCardDropdown?.value?.contains(event.target as Node)) {
    closeDropdown();
  }
};
</script>

<style scoped>
.context-card__dropdown-wrapper {
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
</style>
