import { defineStore } from 'pinia';
import { ref } from 'vue';

export type VideoTagsScreenStore = {
  isTagJoinsVisible: boolean;
  isAiKeypointsVisible: boolean;
};

export const useVideoTagsScreenStore = defineStore(
  'VideoTagsScreenStore',
  () => {
    const isTagJoinsVisible = ref(false);
    const isAiKeypointsVisible = ref(false);

    return {
      isTagJoinsVisible: isTagJoinsVisible.value,
      isAiKeypointsVisible: isAiKeypointsVisible.value,
    };
  },
);
