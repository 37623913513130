<template>
  <div class="w-full">
    <section v-if="!isPlayback" class="flex w-full items-center">
      <div class="mr-4 flex w-full items-center justify-center">
        <VoiceVisualization v-if="isRecording" class="mx-2 w-full md:mx-4" />
      </div>

      <button @click="stopRecording">
        <StopIcon
          class="h-8 w-8 cursor-pointer text-primary hover:text-primary-dark"
        />
      </button>
    </section>

    <section v-show="isPlayback" class="flex w-full items-center">
      <button
        type="button"
        class="mr-4 fill-primary transition duration-300 hover:fill-primary-dark"
        @click="discard"
      >
        <TrashIcon class="h-7 w-7 text-primary" />
      </button>

      <div class="mr-4 mt-[2px] flex w-full items-center justify-center">
        <AudioPlayer
          v-if="blob"
          dark-controls
          is-blob
          :blob="blob"
          player-key="voice-player"
        />
      </div>

      <button type="button" class="h-8 w-8 rotate-90" @click="save">
        <PaperAirplaneIcon
          class="text-primary transition duration-300 hover:text-primary-dark"
        />
      </button>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ContentTypeHeaders } from 'src/core/Constants';
import { getSimpleId } from 'src/services/utilities/HtmlElementUtilities';
import { onMounted, ref } from 'vue';
import {
  PaperAirplaneIcon,
  StopIcon,
  TrashIcon,
} from '@heroicons/vue/20/solid';
import { useEmitter } from 'src/core/EventEmitter';
import {
  VOICE_COMMENT_ACTION,
  VOICE_VISUALIZATION_EVENT,
} from 'src/core/Events';
import { postComment } from 'src/core/services/CommentsHttpService';
import AudioPlayer from 'src/components/Audio/AudioPlayer.vue';
import VoiceVisualization from 'src/components/Audio/VoiceVisualization.vue';

const props = defineProps<{
  videoId: string;
}>();

const emitter = useEmitter();

let mediaRecorder: MediaRecorder;
let stream: MediaStream;

let chunks: BlobPart[] = [];
const blob = ref<Blob>();

const isRecording = ref(false);
const isPlayback = ref(false);

onMounted(async () => {
  emitter.on(VOICE_COMMENT_ACTION.START_RECORDING, record);
});

const startStream = () => {
  return navigator.mediaDevices.getUserMedia({
    audio: true,
  });
};

const record = async () => {
  stream = await startStream();
  toggleRecordState();
  mediaRecorder = new MediaRecorder(stream);

  mediaRecorder.ondataavailable = ({ data }: BlobEvent) => chunks.push(data);
  mediaRecorder.onstop = () => {
    onStopHandler();
  };

  mediaRecorder.start();
};

const onStopHandler = () => {
  const audioBlob = new Blob(chunks, { type: ContentTypeHeaders.MP3 });
  blob.value = audioBlob;

  emitter.emit(VOICE_VISUALIZATION_EVENT.DRAW_BLOB, audioBlob);

  if (!blob.value) {
    return;
  }

  stopStream();
};

const stopStream = () => {
  if (!stream) {
    return;
  }

  stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
};

const stopRecording = () => {
  toggleRecordState();
  togglePlayback();
  mediaRecorder.stop();
};

const toggleRecordState = () => {
  isRecording.value = !isRecording.value;
};

const togglePlayback = () => {
  isPlayback.value = !isPlayback.value;
};

const save = async () => {
  if (!blob.value) {
    return;
  }

  const formData = new FormData();

  formData.append('MediaFile', blob.value, `${getSimpleId()}.mp3`);
  formData.append('Time', '0.00');

  emitter.emit(VOICE_COMMENT_ACTION.UPLOAD);

  const response = await postComment(props.videoId, formData);

  if (!response.ok) {
    return;
  }

  discard();
  emitter.emit(VOICE_COMMENT_ACTION.GET_COMMENTS);
};

const discard = () => {
  if (!blob.value) {
    return;
  }

  blob.value = undefined;
  chunks = [];

  emitter.emit(VOICE_COMMENT_ACTION.STOP_RECORDING);
  isPlayback.value = false;
  isRecording.value = false;
};
</script>

<style scoped>
.sticky {
  top: calc(100% - 4rem);
}

.playback-btn {
  min-width: 1.5rem;
  min-height: 1.5rem;
}
</style>
