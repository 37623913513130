export const DEFAULT_PAGE_SIZE = 30;

export const emitEvents = {
  change: 'change',
};

export type PaginationProps = {
  initialPage: number;
  total: number;
  size: number;
  showPages: boolean;
};
