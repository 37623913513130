export enum VideoRecorderStatus {
  STOPPED = 'stopped',
  RECORDING = 'recording',
  PLAYABLE = 'playable',
}

export enum PlaybackStatus {
  PLAYABLE = 'playable',
  PAUSED = 'paused',
}
