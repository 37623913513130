<template>
  <div
    class="rounded-md border border-t-4 border-gray-300 border-t-sky-500 bg-gray-100 p-4"
  >
    <h4 class="text-xs font-medium capitalize leading-4 text-gray-900">
      <slot />
    </h4>

    <p v-if="$slots.description" class="mt-1 text-sm font-bold text-secondary">
      <slot name="description" />
    </p>
  </div>
</template>

<script setup lang="ts"></script>
