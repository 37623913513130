<template>
  <ANModal size="Small" closable @close="emit('close')">
    <template #title> Create Video Album </template>

    <p class="mt-4 text-sm text-gray-500"><slot></slot></p>

    <ANInput
      v-model="albumName"
      label="Album Name"
      placeholder="Please Enter"
      class="mt-4"
    />

    <template #action>
      <ANButton class="mr-2 w-full" @click="emit('close')"> Cancel </ANButton>
      <ANButton
        dark
        :disabled="!albumName"
        class="ml-2 w-full"
        @click="createNewAlbum"
      >
        <p>Confirm</p>
      </ANButton>
    </template>
  </ANModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { ANModal, ANInput, ANButton } from 'src/components/Uikit/Components';
import AlbumsRepository from 'src/core/repositories/Albums/AlbumsRepository';

const albumsRepository = new AlbumsRepository();
const emit = defineEmits(['close', 'success']);

const albumName = ref<string>('');

const createNewAlbum = () => {
  if (!albumName?.value) {
    return;
  }

  const trimmedName = albumName?.value.trim();

  if (!trimmedName.length) {
    return;
  }

  albumsRepository.setAlbum(albumName.value);
  emit('success');
  emit('close');
};
</script>
