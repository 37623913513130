<template>
  <div
    class="fixed bottom-0 left-0 right-0 top-0 z-50 min-h-screen w-full bg-black"
  >
    <video id="videoElement" class="fixed h-full w-full" playsinline></video>

    <div class="absolute left-1/2 top-1/2 -translate-y-1/2 transform">
      <ANSpinner v-if="isLoading" light size="w-8 h-8" />
    </div>

    <div class="absolute left-0 right-0 top-0 z-10 flex px-3 py-2">
      <ANButton class="order-2 mt-4" small @click="close">
        <ArrowLeftIcon
          class="mr-2 h-5 w-5 text-gray-900 transition duration-300 ease-in-out"
        />
        <p>Back</p>
      </ANButton>
    </div>

    <div class="controls-wrapper fixed bottom-0 left-0 w-full">
      <div class="flex w-full justify-center text-white">
        <section v-if="isPlayable" class="flex justify-center">
          <span class="flex-col text-center">
            <button
              type="button"
              class="br-rounded flex h-12 w-12 items-center justify-center bg-red-500"
              @click="discard"
            >
              <XMarkIcon class="h-6 w-6" />
            </button>
            <span class="text-xs font-semibold text-red-500">Discard</span>
          </span>

          <div class="mx-16 -translate-y-2 transform">
            <button
              v-if="isPaused"
              class="mb-1"
              type="button"
              @click="pausePlayback"
            >
              <PauseIcon class="h-16 w-16" />
            </button>
            <button v-else class="mb-2" type="button" @click="play">
              <PlayIcon class="h-16 w-16" />
            </button>
          </div>

          <span class="flex-col text-center">
            <button
              type="button"
              class="br-rounded flex h-12 w-12 items-center justify-center bg-green-500"
              @click="save"
            >
              <CheckIcon class="h-6 w-6" />
            </button>
            <span class="text-xs font-semibold text-green-500">Save</span></span
          >
        </section>

        <section v-else>
          <button v-if="isRecording" type="button" @click="stopRecording">
            <div
              class="flex h-12 w-12 items-center justify-center rounded-full border border-red-500"
            >
              <div class="h-6 w-6 bg-red-500" />
            </div>
          </button>
          <button v-else type="button" @click="record">
            <div
              class="flex h-12 w-12 items-center justify-center rounded-full border border-red-500"
            >
              <div class="h-8 w-8 rounded-full bg-red-500" />
            </div>
          </button>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useEmitter } from 'src/core/EventEmitter';
import { ANSpinner, ANButton } from 'src/components/Uikit/Components';
import {
  PlaybackStatus,
  VideoRecorderStatus,
} from 'src/core/Interfaces/IVideoRecorder';
import {
  CheckIcon,
  PlayIcon,
  PauseIcon,
  XMarkIcon,
  ArrowLeftIcon,
} from '@heroicons/vue/20/solid';
import { VIDEO_RECORDER_EVENTS } from 'src/core/Events';
import {
  toggleClassOnBody,
  getSimpleId,
} from 'src/services/utilities/HtmlElementUtilities';
import {
  API,
  useAuthorizationHeader,
  useEndpoint,
} from 'src/api/api.endpoints';
import { useAppUser } from 'src/core/3rdParty/Firebase';
import { ContentTypeHeaders, HttpMethods } from 'src/core/Constants';

const emitter = useEmitter();
const appUser = useAppUser();

let videoElement: HTMLVideoElement | null;

let recorder: MediaRecorder;
let stream: MediaStream;

let chunks: BlobPart[] = [];
const isLoading = ref(false);
const recorderStatus = ref(VideoRecorderStatus.STOPPED);
const playbackStatus = ref(PlaybackStatus.PLAYABLE);

onMounted(() => {
  toggleOverflow();

  videoElement = document.getElementById('videoElement') as HTMLVideoElement;

  startStream();
});

const isRecording = computed(() => {
  return recorderStatus.value === VideoRecorderStatus.RECORDING;
});

const isPlayable = computed(() => {
  return recorderStatus.value === VideoRecorderStatus.PLAYABLE;
});

const isPaused = computed(() => {
  return playbackStatus.value === PlaybackStatus.PAUSED;
});

const startStream = async () => {
  stream = await navigator.mediaDevices.getUserMedia({
    video: {
      facingMode: 'environment',
    },
  });

  if (!videoElement) {
    return;
  }

  videoElement.srcObject = stream;
};

const record = () => {
  if (!stream || !videoElement) {
    return;
  }

  videoElement.play();

  recorder = new MediaRecorder(stream);

  recorder.ondataavailable = (e: BlobEvent) => chunks.push(e.data);
  recorder.onstop = () => {
    onStopHandler();
  };

  recorder.start();
  recorderStatus.value = VideoRecorderStatus.RECORDING;
};

const onStopHandler = () => {
  const blob = new Blob(chunks, { type: ContentTypeHeaders.MP4 });
  const url = URL.createObjectURL(blob);

  stopStream();

  if (!videoElement) {
    return;
  }

  videoElement.srcObject = null;
  videoElement.src = url;
};

const stopRecording = () => {
  recorder.stop();
  recorderStatus.value = VideoRecorderStatus.PLAYABLE;
};

const play = () => {
  if (!videoElement) {
    return;
  }

  videoElement.play();
  playbackStatus.value = PlaybackStatus.PAUSED;

  videoElement.onended = () => {
    playbackStatus.value = PlaybackStatus.PLAYABLE;
  };
};

const pausePlayback = () => {
  if (!videoElement) {
    return;
  }

  videoElement.pause();
  playbackStatus.value = PlaybackStatus.PLAYABLE;
};

const toggleOverflow = () => {
  return toggleClassOnBody('an-toggle-body-overflow');
};

const close = () => {
  if (recorder) {
    videoElement = null;

    if (recorder.state !== 'inactive') {
      recorder.stop();
    }
  }

  stopStream();
  toggleOverflow();

  emitter.emit(VIDEO_RECORDER_EVENTS.CLOSE);
};

const discard = () => {
  if (!videoElement) {
    return;
  }

  videoElement.src = '';
  chunks = [];
  recorderStatus.value = VideoRecorderStatus.STOPPED;
  playbackStatus.value = PlaybackStatus.PLAYABLE;

  startStream();
};

const save = async () => {
  const blob = new Blob(chunks, { type: ContentTypeHeaders.MP4 });
  const formData = new FormData();

  formData.append('file', blob, `${getSimpleId()}.mp4`);

  isLoading.value = true;

  const response = await fetch(`${useEndpoint(API.VIDEOS)}/${appUser?.uid}`, {
    method: HttpMethods.POST,
    headers: {
      ...useAuthorizationHeader((await appUser?.getIdToken()) || ''),
    },
    body: formData,
  });

  await response.json();
  isLoading.value = false;

  close();
  emitter.emit(VIDEO_RECORDER_EVENTS.SAVE);
};

const stopStream = () => {
  if (!stream) {
    return;
  }

  stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
};
</script>

<style scoped>
.controls-wrapper {
  bottom: calc(1.5rem + env(safe-area-inset-bottom));
}
</style>
