import { DrawingType } from 'src/core/Interfaces/IDrawing';

export const createNewDrawing = () => {
  return {
    type: DrawingType.LINE,
    version: 1,
    time: Date.now(),
    path: [],
  };
};

export const useClearPath = () => ({ x1: 0, y1: 0, x2: 0, y2: 0 });

export type IDrawingsCoordinates = [number, number];
export interface IDrawingsCanvasDimensions {
  height: number;
  width: number;
}
export interface IDrawingsPath {
  points: IDrawingsCoordinates[];
  strokeStyle: string;
  canvasDimensions: IDrawingsCanvasDimensions;
}
interface IDrawingsContainerContext {
  context: CanvasRenderingContext2D | null;
  paths: IDrawingsPath[];
}

export interface IDrawingsContainer {
  addPath(
    points: IDrawingsCoordinates[],
    strokeStyle: string,
    canvasDimensions: IDrawingsCanvasDimensions,
  ): IDrawingsPath[];
  getPaths(): IDrawingsPath[];
  clearPaths(): never[];
  clearLastPath(): void;
}

export enum DrawingMode {
  LINE = 'LINE',
  ANGLE = 'ANGLE',
}

export const DRAWING_MODE_NAME = {
  [DrawingMode.LINE]: 'Line',
  [DrawingMode.ANGLE]: 'Angle',
};

export const useDrawingsContainer = (
  context: CanvasRenderingContext2D,
): IDrawingsContainer => {
  const _container: IDrawingsContainerContext = {
    context: null,
    paths: [],
  };

  if (!_container.context) {
    _container.context = context;
  }

  const addPath = (
    points: IDrawingsCoordinates[],
    strokeStyle: string,
    canvasDimensions: IDrawingsCanvasDimensions,
  ) => {
    _container.paths.push({ points, strokeStyle, canvasDimensions });

    return _container.paths;
  };

  const getPaths = () => _container.paths;

  const clearPaths = (): never[] => (_container.paths = []);

  const clearLastPath = (): void => {
    _container.paths.pop();
  };

  return { addPath, getPaths, clearPaths, clearLastPath };
};
