<template>
  <div
    class="sidebar-header"
    :class="{ 'mx-10 border-b border-secondary pb-8': !small }"
  >
    <div class="flex" :class="{ 'mt-2': !small }">
      <ContextCard
        class="w-full"
        :position="small ? 'right' : 'left-top'"
        margin="-mt-1 ml-4 z-50"
        @open="isMenuOpen = true"
        @close="isMenuOpen = false"
      >
        <div
          class="user-wrapper flex w-full cursor-pointer items-center justify-center"
          :class="{ open: isMenuOpen }"
        >
          <div class="flex w-full items-center overflow-hidden">
            <UserAvatar />
            <p
              class="ml-4 hidden w-full shrink overflow-hidden text-ellipsis whitespace-nowrap text-sm font-normal capitalize leading-6 tracking-wider text-white md:block"
            >
              {{ user?.displayName?.trim() || user?.email }}
            </p>
            <ChevronDownIcon
              class="icon hidden h-5 w-5 shrink-0 text-white transition duration-300 ease-in-out md:block"
            />
          </div>
        </div>
        <template #content>
          <AccountMenu class="w-64" />
        </template>
      </ContextCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppUser } from 'src/core/3rdParty/Firebase';
import { ref } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import AccountMenu from 'src/components/Account/AccountMenu.vue';
import ContextCard from 'src/components/common/ContextCard.vue';
import UserAvatar from 'src/components/common/UserAvatar.vue';

interface IProps {
  small?: boolean;
}

withDefaults(defineProps<IProps>(), {
  small: false,
});

const user = useAppUser();

const isMenuOpen = ref(false);
</script>

<style scoped>
.user-wrapper:hover .icon,
.open .icon {
  @apply -rotate-90 transform text-sky-500;
}
</style>
