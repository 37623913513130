<template>
  <div class="relative">
    <button
      type="button"
      class="absolute right-0 top-0 z-10 -translate-y-1/2 translate-x-1/2 rounded-full border border-gray-400 bg-slate-100 p-2 hover:border-red-400 hover:bg-red-100"
      @click="$emit('delete')"
    >
      <TrashIcon class="h-4 w-4" />
    </button>
    <a
      :href="`/app/Team/${props.athlete.id}`"
      class="relative block rounded-lg border-b border-gray-200 bg-black px-2 py-6 transition duration-300 hover:opacity-90 sm:rounded-lg sm:border-b-0 sm:px-4 sm:shadow-sm lg:px-6"
    >
      <div
        v-if="props.athlete"
        class="flex min-h-40 flex-col items-end justify-between"
      >
        <div class="z-20 flex items-center text-sm text-white">
          <VideoCameraIcon class="mr-2 h-5 w-5 text-white" />
          {{ props.athlete.videoCount || 0 }}
        </div>
        <span
          class="flex h-full w-full items-center justify-center bg-black p-2"
        >
          <UserAvatar :user-avatar-file="userAvatarFile" size="w-24 h-24" />
        </span>

        <div class="z-20 flex w-full items-center justify-between">
          <div class="w-full">
            <div class="text-center text-sm font-semibold text-white">
              <span v-if="props.athlete.firstName && props.athlete.lastName">
                {{ `${props.athlete.firstName} ${props.athlete.lastName}` }}
              </span>
              <span v-else>
                {{ props.athlete?.username || props.athlete.id }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
import { TrashIcon, VideoCameraIcon } from '@heroicons/vue/20/solid';
import { IMember } from 'src/core/Interfaces/IAthlete';
import UserAvatar from 'src/components/common/UserAvatar.vue';
import { computed } from 'vue';
import { UserAvatarFile } from 'src/core/models/UserAvatarFile';
import { userAvatarRef } from 'src/core/services/storage-service';

defineEmits(['delete']);
const props = defineProps<{
  athlete: IMember;
}>();

const userAvatarFile = computed(() => {
  return new UserAvatarFile(userAvatarRef(props.athlete.id), {
    displayName: props.athlete.username,
  });
});
</script>
