import ResetPassword from 'src/pages/account/ResetPassword.vue';
import Settings from 'src/pages/account/Settings.vue';
import Albums from 'src/pages/app/Albums.vue';
import AthleteDetails from 'src/pages/app/AthleteDetails.vue';
import Dashboard from 'src/pages/app/Dashboard/Dashboard.vue';
import Team from 'src/pages/app/Team.vue';
import TeamDetails from 'src/pages/app/Teams/TeamDetails.vue';
import Teams from 'src/pages/app/Teams/Teams.vue';
import VideoAlbum from 'src/pages/app/VideoAlbum.vue';
import VideoDetails from 'src/pages/app/VideoDetails/VideoDetails.vue';
import Videos from 'src/pages/app/videos.vue';
import Home from 'src/pages/home/home.vue';
import Privacy from 'src/pages/legal/Privacy.vue';
import Terms from 'src/pages/legal/Terms.vue';
import Login from 'src/pages/login/login.vue';
import Register from 'src/pages/register/register.vue';
import ShareVideos from 'src/pages/share/Videos.vue';

export const PUBLIC_ROUTE_NAMES = {
  ROOT: 'root',
  LOGIN: 'login',
  REGISTER: 'register',
  RESET_PASSWORD: 'reset-password',
};

export const PUBLIC_ROUTES = {
  [PUBLIC_ROUTE_NAMES.ROOT]: '/',
  [PUBLIC_ROUTE_NAMES.LOGIN]: '/login',
  [PUBLIC_ROUTE_NAMES.REGISTER]: '/register',
  [PUBLIC_ROUTE_NAMES.RESET_PASSWORD]: '/reset-password',
  TERMS_AND_CONDITIONS: '/legal/terms',
  PRIVACY_POLICY: '/legal/privacy',
  SHARE_VIDEO: '/share/videos/:videoId',
};

export enum APP_ROUTES {
  ROOT = '/app',
  DASHBOARD = '/app/dashboard',
  TEAM = '/app/team',
  TEAMS = '/app/teams',
  TEAM_DETAILS = '/app/teams/:teamId',
  ATHLETE_DETAILS = '/app/team/:athleteId',
  VIDEOS = '/app/videos',
  VIDEO_DETAILS = '/app/videos/:videoId',
  VIDEO_TAGS = '/app/videos/:videoId/tags',
  SETTINGS = '/app/account/settings',
  ALBUMS = '/app/videos/albums',
  VIDEO_ALBUM = '/app/videos/albums/:key',
}

export const APP_ROUTE_NAMES = {
  VIDEO_ALBUM: 'VideoAlbum',
  VIDEO_LIST: 'Videos',
  VIDEO_DETAILS: 'VideoDetails',
  VIDEO_TAGS: 'VideoTags',
};

export const ROUTES_CONFIG = [
  {
    path: PUBLIC_ROUTES[PUBLIC_ROUTE_NAMES.ROOT],
    name: PUBLIC_ROUTE_NAMES.ROOT,
    component: Home,
  },
  {
    path: PUBLIC_ROUTES[PUBLIC_ROUTE_NAMES.LOGIN],
    name: PUBLIC_ROUTE_NAMES.LOGIN,
    component: Login,
  },
  {
    path: PUBLIC_ROUTES[PUBLIC_ROUTE_NAMES.REGISTER],
    name: PUBLIC_ROUTE_NAMES.REGISTER,
    component: Register,
  },
  {
    path: PUBLIC_ROUTES[PUBLIC_ROUTE_NAMES.RESET_PASSWORD],
    name: PUBLIC_ROUTE_NAMES.RESET_PASSWORD,
    component: ResetPassword,
  },
  { path: PUBLIC_ROUTES.TERMS_AND_CONDITIONS, component: Terms },
  { path: PUBLIC_ROUTES.PRIVACY_POLICY, component: Privacy },
  { path: PUBLIC_ROUTES.SHARE_VIDEO, component: ShareVideos },
  { path: APP_ROUTES.ROOT, redirect: APP_ROUTES.DASHBOARD },
  { path: APP_ROUTES.TEAM, component: Team },
  { path: APP_ROUTES.TEAMS, component: Teams },
  { path: APP_ROUTES.TEAM_DETAILS, component: TeamDetails },
  { path: APP_ROUTES.ATHLETE_DETAILS, component: AthleteDetails },
  {
    path: APP_ROUTES.VIDEOS,
    name: APP_ROUTE_NAMES.VIDEO_LIST,
    component: Videos,
  },
  {
    path: APP_ROUTES.VIDEO_DETAILS,
    name: APP_ROUTE_NAMES.VIDEO_DETAILS,
    component: VideoDetails,
  },
  {
    path: APP_ROUTES.VIDEO_TAGS,
    name: APP_ROUTE_NAMES.VIDEO_TAGS,
    component: VideoDetails,
  },
  { path: APP_ROUTES.SETTINGS, component: Settings },
  { path: APP_ROUTES.DASHBOARD, component: Dashboard },
  { path: APP_ROUTES.ALBUMS, component: Albums },
  {
    path: APP_ROUTES.VIDEO_ALBUM,
    name: APP_ROUTE_NAMES.VIDEO_ALBUM,
    component: VideoAlbum,
  },
];
