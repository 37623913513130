<template>
  <ANContentSection title="Danger Zone">
    <div class="mt-6 border-t">
      <div
        class="mt-2 flex flex-col justify-between md:flex-row md:items-center"
      >
        <div class="md:flex">
          <div class="whitespace-nowrap font-medium md:w-32 md:shrink-0">
            Delete Account
          </div>
          <div class="md:mt-0 md:px-4">
            <p class="text-xs text-gray-500">
              If you delete your account, you will lose access to AthleticNext
              apps. We will delete your data from the cloud storage within 30
              days. For more information please see our Privacy Policy
            </p>
          </div>
        </div>
        <div class="md:w-40 md:shrink-0">
          <ANButton
            class="mt-4 w-full whitespace-nowrap md:mt-0"
            :color="'danger'"
            @click="isDeleteAccountModalOpen = true"
          >
            Delete
          </ANButton>
        </div>
      </div>
    </div>
    <ANModal
      v-if="isDeleteAccountModalOpen"
      closable
      size="Small"
      @close="closeDeleteAccountModal"
    >
      <template #title> Delete Account </template>
      <div class="mt-4">
        Are you sure you want to delete your account associated with the email
        address <span class="font-bold">{{ user?.email }}</span> from
        AthleticNext.com?
      </div>
      <div class="mt-2">
        This action cannot be undone and you will lose access to AthleticNext
        apps. Please note that it may take up to 30 days to delete all of your
        data from our cloud storage. If you are sure you want to proceed with
        deleting your account, please click 'Delete' below. Deleting your
        account means you will lose all of your saved data. For more
        information, please see our
        <RouterLink
          :to="PUBLIC_ROUTES.PRIVACY_POLICY"
          class="font-bold transition duration-300 ease-in-out hover:text-orange-400"
        >
          Privacy Policy </RouterLink
        >.
      </div>

      <div>
        <ANInput
          v-model="password"
          type="password"
          label="To delete the account enter your password"
          placeholder="Enter your password"
          class="mt-4"
          @focus="deleteAccountErrorMessage = ''"
        />
      </div>

      <ErrorNotification
        v-if="deleteAccountErrorMessage"
        class="block rounded-lg text-red-500"
      >
        <p class="text-red-600">{{ deleteAccountErrorMessage }}</p>
      </ErrorNotification>

      <template #action>
        <ANButton class="mr-2 w-full" @click="isDeleteAccountModalOpen = false">
          Cancel
        </ANButton>
        <ANButton
          color="danger"
          class="ml-2 w-full"
          :loading="isDeleteAccountInProgress"
          :disabled="
            !password ||
            isDeleteAccountInProgress ||
            !!deleteAccountErrorMessage
          "
          @click="deleteAccount(password)"
        >
          <p>Delete</p>
        </ANButton>
      </template>
    </ANModal>
  </ANContentSection>
</template>

<script setup lang="ts">
import { getAuth, signInWithEmailAndPassword } from '@firebase/auth';
import {
  ANButton,
  ANContentSection,
  ANInput,
  ANModal,
} from 'src/components/Uikit/Components';
import ErrorNotification from 'src/components/common/ErrorNotification.vue';
import { PUBLIC_ROUTES, PUBLIC_ROUTE_NAMES } from 'src/core/AppRoutes';
import { ERROR_MESSAGE } from 'src/core/Errors';
import { useAppUser } from 'src/core/3rdParty/Firebase';
import { AccountsHttpService } from 'src/core/services/accounts/AccountsHttpService';
import { ref } from 'vue';
import { RouterLink, useRouter } from 'vue-router';

const accountsHttpService: AccountsHttpService = new AccountsHttpService();

const user = useAppUser();
const auth = getAuth();
const router = useRouter();

const isDeleteAccountModalOpen = ref(false);
const password = ref('');
const deleteAccountErrorMessage = ref('');
const isDeleteAccountInProgress = ref(false);

const deleteAccount = async (password: string) => {
  if (!user || !password.length) {
    return;
  }

  isDeleteAccountInProgress.value = true;

  signInWithEmailAndPassword(auth, user.email ?? '', password)
    .then(async () => {
      await accountsHttpService.deleteAccount();
      await auth.signOut();
      router.push({ name: PUBLIC_ROUTE_NAMES.ROOT });
    })
    .catch((error) => {
      deleteAccountErrorMessage.value = ERROR_MESSAGE[error.code];
    })
    .finally(() => {
      isDeleteAccountInProgress.value = false;
    });
};

const closeDeleteAccountModal = () => {
  isDeleteAccountModalOpen.value = false;
  password.value = '';
  deleteAccountErrorMessage.value = '';
};
</script>
