<script setup lang="ts">
import { COLORS, ColorName } from 'src/core/Constants';
import { ref } from 'vue';

withDefaults(defineProps<{ modelValue: string }>(), {
  modelValue: COLORS[ColorName.WHITE],
});
defineEmits(['update:modelValue']);

const color = ref(COLORS[ColorName.WHITE]);
const isColorSwitcherVisible = ref(false);
</script>

<template>
  <button
    type="button"
    class="flex justify-center rounded border px-2 py-1.5"
    :style="{
      color,
      'border-color': 'white',
    }"
    @click="isColorSwitcherVisible = !isColorSwitcherVisible"
  >
    <div class="h-6 w-6 rounded-full" :style="{ 'background-color': color }" />
  </button>
  <div
    v-if="isColorSwitcherVisible"
    class="absolute right-full top-0 mr-1 w-48 rounded bg-white/40 p-2"
  >
    <button
      v-for="colorOption in COLORS"
      :key="colorOption"
      type="button"
      class="p-1"
      @click="
        () => {
          color = colorOption;
          $emit('update:modelValue', colorOption);
        }
      "
    >
      <div
        class="h-9 w-9 rounded-full border-2 border-white"
        :style="{ 'background-color': colorOption }"
      />
    </button>
  </div>
</template>
