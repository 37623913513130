import { useAuthorizationHeader } from 'src/api/api.endpoints';
import { useAppUser } from './3rdParty/Firebase';

export const HttpClientFetch = async (
  url: string,
  config: { [key: string]: unknown } = {},
) => {
  return fetch(url, {
    headers: useAuthorizationHeader((await useAppUser()?.getIdToken()) || ''),
    ...config,
  });
};
