<template>
  <TransitionRoot as="template" show>
    <Dialog as="div" class="relative z-[101]" @close="emit('close')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-[200] overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative z-[200] flex h-full w-full transform flex-col overflow-hidden rounded-lg bg-white px-4 pb-6 text-left transition-all sm:my-8 sm:w-full sm:max-w-sm sm:px-6"
              :class="[
                sizeCss,
                closable ? 'pt-12' : 'pt-5',
                $slots.action ? 'pb-3' : 'pb-6',
              ]"
            >
              <div
                v-if="closable"
                class="absolute right-0 top-0 bg-white pr-4 pt-4"
              >
                <button
                  type="button"
                  class="rounded-md bg-transparent text-gray-400 hover:text-gray-900 focus:outline-none"
                  @click="$emit('close')"
                >
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div class="flex h-full flex-grow flex-col overflow-y-auto">
                <slot name="icon" />

                <div :class="{ 'mt-3 sm:mt-5': !closable }">
                  <DialogTitle
                    as="h3"
                    class="text-center text-lg font-medium leading-6 text-gray-900"
                  >
                    <slot name="title" />
                  </DialogTitle>

                  <slot></slot>
                </div>
              </div>

              <div v-if="$slots.action" class="flex w-full bg-white py-3">
                <div class="flex w-full justify-center">
                  <slot name="action" />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { ModalSize, ModalOptions } from 'src/core/ModalConstants';
import { computed } from 'vue';

const emit = defineEmits(['close', 'action']);

interface IProps {
  closable?: boolean;
  size?: ModalOptions;
}

const props = withDefaults(defineProps<IProps>(), {
  closable: false,
  size: 'Small',
});

const sizeCss = computed(() => {
  return ModalSize[props.size];
});
</script>
