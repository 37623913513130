<template>
  <SidebarLayout>
    <VideoRecorder v-if="recordVideoScreenVisible" />

    <GridContainer>
      <div class="col-span-12 mb-20 flex flex-col">
        <PageHeader>
          My Videos

          <template #action>
            <div class="flex items-center">
              <VideoUpload />
            </div>
          </template>
        </PageHeader>

        <ErrorNotification v-if="uploadFailed">
          Couldn't upload the video. Please try again or get in touch with us
          via
          <a
            class="inline-flex whitespace-nowrap text-blue-600 hover:underline"
            href="mailto:support@athleticnext.com"
          >
            support@athleticnext.com </a
          >.
        </ErrorNotification>

        <VideoGrid removable />
      </div>
    </GridContainer>

    <div
      class="fixed bottom-0 left-0 z-20 flex h-12 w-full items-center justify-center border-t border-secondary-light bg-white md:hidden"
    >
      <div
        class="br-rounded cursor-pointer border border-red-500 p-1"
        @click="recordVideoScreenVisible = true"
      >
        <div
          class="br-rounded flex h-6 w-6 border border-red-500 bg-red-500 p-2"
        >
          &nbsp;
        </div>
      </div>
    </div>
  </SidebarLayout>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import VideoUpload, { EVENTS } from 'src/components/Video/VideoUpload.vue';
import { useEmitter } from 'src/core/EventEmitter';
import SidebarLayout from 'src/components/layout/SidebarLayout.vue';
import ErrorNotification from 'src/components/common/ErrorNotification.vue';
import VideoGrid from 'src/components/Video/VideoGrid.vue';
import VideoRecorder from 'src/components/Video/VideoRecorder.vue';
import { VIDEO_RECORDER_EVENTS } from 'src/core/Events';
import GridContainer from 'src/components/common/GridContainer.vue';
import PageHeader from 'src/components/common/PageHeader.vue';

const uploadFailed = ref(false);
const emitter = useEmitter();

const recordVideoScreenVisible = ref(false);

onMounted(() => {
  emitter.on(EVENTS.UPLOAD_STARTED, () => {
    uploadFailed.value = false;
  });

  emitter.on(EVENTS.UPLOAD_FINISHED, () => {
    uploadFailed.value = false;
  });

  emitter.on(EVENTS.UPLOAD_FAILED, () => {
    uploadFailed.value = true;
  });

  emitter.on(VIDEO_RECORDER_EVENTS.CLOSE, () => {
    recordVideoScreenVisible.value = false;
  });
});
</script>
