<template>
  <ANButton :small="small" :dark="!light" @click="goBack">
    <div class="flex items-center justify-center">
      <ChevronLeftIcon
        class="mr-0.5 h-5 w-5 transition duration-300 ease-in-out"
        :class="light ? 'text-gray-900' : 'text-white'"
      />
      <p>Back</p>
    </div>
  </ANButton>
</template>

<script lang="ts" setup>
import { ChevronLeftIcon } from '@heroicons/vue/20/solid';
import { ANButton } from 'src/components/Uikit/Components';
import { useRouter } from 'vue-router';

type BackButtonProps = {
  small?: boolean;
  light?: boolean;
  routeName?: string;
};

const router = useRouter();

const { routeName } = withDefaults(defineProps<BackButtonProps>(), {
  small: false,
  light: false,
  routeName: undefined,
});

const goBack = () => {
  if (routeName) {
    router.push({ name: routeName });
  } else {
    history.back();
  }
};
</script>
